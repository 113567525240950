import * as React from "react";
import { useHistory } from "react-router-dom";

import { useCreationState } from "./creationFlowHooks";
import { FaCamera } from "react-icons/fa";
import LoadingSpinner from "./shared/LoadingSpinner";
import { toast } from "react-toastify";
import { Poster_Description } from "./shared/Constants";

const Loading: React.FC = () => {
  const { processedImage, error } = useCreationState();
  const history = useHistory();

  React.useEffect(() => {
    if (processedImage) {
      setTimeout(() => {
        history.replace("/preview");
      }, 2000);
    } else if (error) {
      history.replace("/preview");
      toast.error("Something went wrong, couldn't proccess your image");
    }
  }, [processedImage, error, history]);

  return (
    <>
      <div className="bg-bgBody flex flex-col flex-1 items-center justify-center">
        <div className="flex items-center justify-center flex-1">
          <div className="flex items-center justify-center relative">
            <LoadingSpinner color="white" />
            <div className="absolute">
              <FaCamera color="white" size={28} />
            </div>
          </div>
        </div>
        <div style={{ flex: 0.4 }}>
          <h1 className="text-2xl font-bold text-white text-center">
            Generating your {Poster_Description},
            <br /> hold tight
          </h1>
        </div>
      </div>
    </>
  );
};

export default Loading;
