import React from "react";
import logger from "use-reducer-logger";

import { CreationStore, CreationState, CreationActions } from "./types";
import { Types } from "./creationFlowActions";
import { isDev } from "./enviromentHelper";

const initialState = {
  processedImage: null,
  customerDetails: null,
  uploadedPosterUrl: null,
  fullResFanUrl: null,
  uploadedFanUrl: null,
  fanParams: null,
  exportLayers: null,
  finalPoster: localStorage.getItem("finalPoster") || null,
  previewPoster: null,
  loading: false,
  error: null,
  cart: null,
  purchaseDetails: null,
};

export const CreationContext = React.createContext<CreationStore>({
  state: initialState,
  dispatch: () => null,
});

const parseError = (errorCode: number): string => {
  switch (errorCode) {
    case 400:
      return "We had trouble processing your selfie";
    default:
      return "Whoops! Something went wrong here.  To fix it - please refresh your browser and then try again";
  }
};

const reducer = (
  state: CreationState,
  action: CreationActions
): CreationState => {
  switch (action.type) {
    case Types.ProccessRequest:
      return {
        ...state,
        ...action.payload,
        loading: true,
        error: null,
      };
    case Types.ProccessSucess:
      return {
        ...state,
        loading: false,
        ...action.payload,
      };
    case Types.ProccessFailure:
      return {
        ...state,
        loading: false,
        error: parseError(action.payload.errorCode),
      };
    case Types.SetCustomerDetails:
      return {
        ...state,
        ...action.payload,
      };
    case Types.SetPreviewPoster:
      return {
        ...state,
        ...action.payload,
      };
    case Types.ResetProcessedImage:
      return {
        ...state,
        processedImage: null,
      };
    case Types.SetUploadedFanUrl:
      return {
        ...state,
        ...action.payload,
      };
    case Types.SetExportLayers:
    case Types.SetFanParams:
    case Types.SetFullResFanUrl:
      return {
        ...state,
        ...action.payload,
      };

    default:
      throw new Error();
  }
};

interface Props {
  children: JSX.Element;
}

export const CreationContextProvider: React.SFC<Props> = (props: Props) => {
  const reducerToUse = isDev ? logger(reducer) : reducer;
  const [state, dispatch] = React.useReducer(reducerToUse, initialState);

  return (
    <CreationContext.Provider value={{ state, dispatch }}>
      {props.children}
    </CreationContext.Provider>
  );
};
