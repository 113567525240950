import React from "react";
import * as Sentry from "@sentry/react";

import loadImage from "blueimp-load-image";
import { CreationContext } from "./CreationContext";
import { CreationState, CreationActions } from "./types";
import {
  processImageSuccess,
  processImageFailure,
  resetProcessedImage,
  setUploadedFanUrl,
  setFullResFanUrl,
} from "./creationFlowActions";
import { useCamera } from "./useCamera";
import Api from "./api";
import {
  basepath,
  useUploadImage,
  useBackgroundRemoval,
} from "./poster/posterHooks";
import { convertCanvasToBlob, trimImage } from "./utils";

export const useCreationState = (): CreationState => {
  const { state } = React.useContext(CreationContext);
  return state;
};

export const useCreationDispatch = (): React.Dispatch<CreationActions> => {
  const { dispatch } = React.useContext(CreationContext);
  return dispatch;
};

export const useCaptureFan = (onSuccess = (): void => {}) => {
  const dispatch = useCreationDispatch();
  const uploadImage = useUploadImage();
  const backgroundRemovalType = useBackgroundRemoval();
  const onCaptureImage = async (image: File): Promise<void> => {
    setIsRunning(false);
    setIsLoading(true);
    dispatch(resetProcessedImage());

    Api.uploadImage(image).then((fullResImageUrl) => {
      if (fullResImageUrl) {
        dispatch(setFullResFanUrl(fullResImageUrl));
      }
    });

    const { image: loadedImageCanvas } = await loadImage(image, {
      orientation: true,
      canvas: true,
      maxWidth: 1200,
    });

    const imageFile = await convertCanvasToBlob(
      loadedImageCanvas as HTMLCanvasElement
    );

    setIsProcessed(true);
    onSuccess();
    setIsLoading(false);

    try {
      const image =
        basepath === "roaming" ||
        basepath === "roaminggreen" ||
        basepath === "oppo"
          ? ((await trimImage(URL.createObjectURL(imageFile))) as string)
          : await Api.removeImageBackground(imageFile, backgroundRemovalType);

      if (basepath !== "oppo") {
        dispatch(processImageSuccess(image));
      }

      const uploadedFanUrl = await uploadImage(image);
      if (uploadedFanUrl) {
        //This is required in order for the end poster to be generated so wait for the fan url
        //Oppo images are raw and can take a while.
        if (basepath === "oppo") {
          dispatch(processImageSuccess(image));
        }
        dispatch(setUploadedFanUrl(uploadedFanUrl));
        console.log(uploadedFanUrl);
      } else {
        Sentry.captureException(Error("Couldn't upload fan image"));
      }
    } catch (error) {
      console.log(error);
      Sentry.captureException(error);
      dispatch(processImageFailure(error?.response?.status || 500));
    }
  };

  const [, setIsRunning] = React.useState(false);
  const [loading, setIsLoading] = React.useState(false);

  const [processed, setIsProcessed] = React.useState(false);

  const setupCamera = useCamera(onCaptureImage);

  const beginCapture = (): void => setIsRunning(true);

  return {
    captureImage: beginCapture,
    processed,
    loading,
    setupCamera,
    onCaptureImage,
  };
};
