import { gql } from "apollo-boost";

export const CREATE_USER = gql`
  mutation CreateUserMutation($input: CreateUserInput!) {
    createUser(input: $input) {
      success
      userToken {
        token
        expiresIn
      }
    }
  }
`;

export const GET_POSTERS = gql`
  query getPosters {
    posters {
      nodes {
        id
        path
        backgroundRemoval
        template {
          facebookShareTitle
          facebookShareDescription
          splashPageText
          splashPageEnabled
          pageTitle
          gaId
        }
        backdrops {
          nodes {
            id
            image {
              url
              versions {
                large {
                  url
                }
              }
            }
          }
        }
        stylesheet {
          bodyBgColor
          bodyColor
          cardBgColor
          cardColor
          dangerColor

          headerImages {
            url
            role
          }
          infoColor
          lightColor
          primaryColor
          secondaryColor
          splashPageImage {
            versions {
              large {
                url
              }
            }
          }
          successColor
        }
      }
    }
  }
`;

export const UPDATE_USER = gql`
  mutation UpdateUserMutation($input: UpdateUserInput!) {
    updateUser(input: $input) {
      success
      errors {
        attribute
        messages
      }
      user {
        id
      }
    }
  }
`;
