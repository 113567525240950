import * as React from "react";
import { AiOutlineNumber } from "react-icons/ai";
interface Props {
  label: string;
  name: string;
  options: string[];
  register: any;
  icon?: JSX.Element;
  selectPlaceholder: string;
  error?: any;
}

const Select: React.SFC<Props> = ({
  label,
  selectPlaceholder,
  name,
  icon = <AiOutlineNumber />,
  register,
  options,
  error,
}: Props) => (
  <div
    tabIndex={0}
    className="relative z-50 focus-within:border-scienceBlue focus-within:bg-opacity-50 focus-within:text-opacity-50"
  >
    <select
      name={name}
      defaultValue=""
      ref={register({ required: true })}
      className="select"
    >
      <option disabled value="">
        {selectPlaceholder}
      </option>
      {options
        .sort((a, b) =>
          a.localeCompare(b, undefined, {
            numeric: true,
            sensitivity: "base",
          })
        )
        .map((value) => (
          <option value={value} key={value}>
            {value}
          </option>
        ))}
    </select>
    <div
      className="absolute pointer-events-none"
      style={{
        top: "16px",
        left: "20px",
      }}
    >
      {icon}
    </div>
    <label
      htmlFor={name}
      className="absolute block text-label font-bold pointer-events-none"
      style={{
        top: "16px",
        left: "48px",
      }}
    >
      {label}
    </label>
    {error && (
      <p
        className="absolute text-danger text-label pointer-events-none"
        style={{
          top: "16px",
          right: "12px",
        }}
      >
        Required
      </p>
    )}
  </div>
);

export default Select;
