import React from "react";
import "react-input-range/lib/css/index.css";
import InputRange from "react-input-range";
import { AiOutlineClose } from "react-icons/ai";

export type AdjustmentMode = "Adjust";

interface Props {
  onClose: () => void;
  onChangeBrightness: (value: number) => void;
  onChangeContrast: (value: number) => void;

  brightnessRange: { min: number; max: number; step: number };
  contrastRange: { min: number; max: number; step: number };
  getBrightnessValue: () => number;
  getContrastValue: () => number;
}

const AdjustmentPanel: React.FC<Props> = ({
  onClose,
  onChangeBrightness,
  onChangeContrast,
  getBrightnessValue,
  brightnessRange,
  getContrastValue,
  contrastRange,
}) => {
  const [_, forceUpdate] = React.useState({});
  return (
    <div className="absolute flex flex-col justify-between px-4 h-34 bg-white mt-2 border w-full bottom-0 left-0 z-50 rounded-t-xl shadow-lg">
      <div className="flex mt-3">
        <div className="flex items-center justify-center font-medium text-sm flex-grow">
          <h1 className="ml-9">Adjust</h1>
        </div>
        <div
          onClick={onClose}
          className="flex items-center justify-center absolute right-3 top-3 -m-3 p-3"
        >
          <div className="bg-gray-200 rounded-full p-[4px]" onClick={onClose}>
            <AiOutlineClose size={12} />
          </div>
        </div>
      </div>

      <div className="flex flex-grow flex-col items-center pr-5">
        <div className="flex self-stretch mt-4">
          <div className="flex flex-[0.15] justify-end items-center">
            <p className="text-xs font-medium">Brightness</p>
          </div>
          <div className="flex flex-[0.85] ml-2 items-center">
            <InputRange
              step={brightnessRange.step}
              maxValue={brightnessRange.max}
              minValue={brightnessRange.min}
              value={getBrightnessValue()}
              onChange={(value) => {
                onChangeBrightness(value as number);
                forceUpdate({});
              }}
              formatLabel={() => ""}
            />
          </div>
        </div>
        <div className="flex self-stretch mt-4">
          <div className="flex flex-[0.15] justify-end items-center">
            <p className="text-xs font-medium">Contrast</p>
          </div>
          <div className="flex flex-[0.85] ml-2 items-center">
            <InputRange
              step={contrastRange.step}
              maxValue={contrastRange.max}
              minValue={contrastRange.min}
              value={getContrastValue()}
              onChange={(value) => {
                onChangeContrast(value as number);
                forceUpdate({});
              }}
              formatLabel={() => ""}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default AdjustmentPanel;
