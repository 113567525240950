import * as React from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { useCreationDispatch, useCreationState } from "../creationFlowHooks";
import { setCustomerDetails } from "../creationFlowActions";
import Layout from "../shared/Layout";
import Header from "../shared/Header";
import InfoHeader from "../shared/InfoHeader";
import {
  useGetCurrentPosterAdditionalFields,
  useGetCurrentPoster,
} from "../poster/posterHooks";
import DetailsForm from "./DetailsForm";

import Hero from "../shared/Hero";

const Details: React.FC = () => {
  const detailsFields = useGetCurrentPosterAdditionalFields();
  const { poster } = useGetCurrentPoster();
  const showNames =
    poster?.path !== "roaming" && poster?.path !== "roaminggreen";

  const { error } = useCreationState();

  const dispatch = useCreationDispatch();
  const history = useHistory();
  const hasExtra = poster?.path.endsWith("SuperFan");
  const onSubmit = (data): void => {
    if (!showNames) {
      if (data.firstName === undefined) {
        data.firstName = "AIMS";
      }
      if (data.lastName === undefined) {
        data.lastName = "Games";
      }
    }
    dispatch(setCustomerDetails(data));
    if (hasExtra) {
      history.push("/details-extra");
    } else {
      history.replace("/loading");
    }
  };

  React.useEffect(() => {
    if (error) {
      history.replace("/");
      toast.error(error);
    }
  }, [error, history]);

  return (
    <Layout>
      <>
        <Hero
          role="PERSONALISE_PAGE"
          defaultHeader={<Header text="Personalise Your Poster" />}
        />
        <div className="flex flex-col flex-1 mx-5 overflow-hidden">
          <div className="mb-3 mx-4">
            <InfoHeader
              header={
                poster?.template.personaliseText ||
                "Customise image with player name, stats and profile below."
              }
            />
          </div>

          <div className="flex flex-grow overflow-y-scroll overscroll-x-none rounded-lg">
            {detailsFields && (
              <DetailsForm
                defaultValues={{}}
                detailFields={detailsFields}
                showNames={showNames}
                onSubmit={onSubmit}
                submitText={hasExtra ? "Next" : undefined}
              />
            )}
          </div>
        </div>
      </>
    </Layout>
  );
};

export default Details;
