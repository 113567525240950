import * as React from "react";
import { useForm } from "react-hook-form";
import camelCase from "lodash/camelCase";

import Input from "../shared/Input";
import Select from "../shared/Select";
import Button from "../shared/Button";
import { BsFillPersonLinesFill } from "react-icons/bs";
import { IoIosArrowRoundForward } from "react-icons/io";
import { Poster_Description } from "../shared/Constants";

const fieldPlaceholder = {
  "How many Weet-Bix™ can you eat?": "Weet-Bix™ eaten in one go",
};
interface Props {
  defaultValues: { [key: string]: { name: string; options: string[] } };
  detailFields: any;
  showNames: boolean;
  onSubmit: (data: any) => void;
  submitText?: string;
}

const DetailsForm: React.ForwardRefRenderFunction<HTMLFormElement, Props> = ({
  defaultValues,
  detailFields,
  showNames,
  onSubmit,
  submitText,
}: Props) => {
  const { register, handleSubmit, errors } = useForm({
    defaultValues,
  });
  submitText = submitText || `Preview ${Poster_Description}`;
  return (
    <form onSubmit={handleSubmit(onSubmit)} className="flex flex-col flex-grow">
      <div className="px-5 pt-6 pb-3 bg-bgCard text-card">
        {showNames && (
          <>
            <div className="mb-4">
              <Input
                error={errors.firstName}
                icon={<BsFillPersonLinesFill />}
                name="firstName"
                label="First Name"
                register={register({ required: true })}
              />
            </div>
            <div className="mb-4">
              <Input
                error={errors.lastName}
                icon={<BsFillPersonLinesFill />}
                name="lastName"
                label="Last Name"
                register={register({ required: true })}
              />
            </div>
          </>
        )}

        {detailFields.map((field) => (
          <div className="mb-4" key={field.name}>
            <Select
              error={errors[camelCase(field.name)]}
              register={register}
              selectPlaceholder={
                fieldPlaceholder[field.name] || `Select ${field.name}`
              }
              label={field.name}
              name={camelCase(field.name)}
              options={field.options}
            />
          </div>
        ))}
      </div>
      <div className="flex flex-grow items-end bg-whisper px-6 py-4 mt-3">
        <Button
          theme="secondary"
          type="submit"
          icon={<IoIosArrowRoundForward size={30} />}
        >
          {submitText}
        </Button>
      </div>
    </form>
  );
};
export default DetailsForm;
