import React from "react";
import { ApolloProvider } from "@apollo/react-hooks";

import "./styles/index.css";

import { CreationContextProvider } from "./CreationContext";
import CreationFlow from "./CreationFlow";
import { useBootstrap } from "./auth/authHooks";
import { getClient } from "./ApolloClient";
import AppTheme from "./AppTheme";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import LoadingScreen from "./shared/LoadingScreen";
import ErrorScreen from "./shared/ErrorScreen";
import { basepath } from "./poster/posterHooks";
import { preloadFont } from "./poster/posterMaker";
import { postersFonts } from "./poster/posterHelpers";

const calculateVH = (): void => {
  const vh = window.innerHeight * 0.01;
  document.documentElement.style.setProperty("--vh", `${vh}px`);
};

// Disable annoying system zoom on iOS
document.addEventListener("gesturestart", function (e) {
  e.preventDefault();
});

window.addEventListener("resize", () => {
  calculateVH();
});

calculateVH();

const App: React.FC = () => {
  const { loading, error } = useBootstrap();

  React.useEffect(() => {
    const fontsToLoad: string[] = postersFonts[basepath] || [];
    fontsToLoad.forEach((font) => {
      preloadFont(font);
    });
  }, []);

  if (loading) {
    return <LoadingScreen />;
  }

  if (error) {
    return <ErrorScreen error={error} />;
  }

  return (
    <ApolloProvider client={getClient()}>
      <AppTheme>
        <CreationContextProvider>
          <CreationFlow />
        </CreationContextProvider>
      </AppTheme>
    </ApolloProvider>
  );
};

export default App;
