import React from "react";
import cn from "classnames";

interface Props {
  icon: JSX.Element;
  text: string;
  onClick?: () => void;
  className?: string;
  buttonClassName?: string;
}

const ToolbarAction: React.FC<Props> = ({
  icon,
  text,
  onClick,
  className,
  buttonClassName,
}) => {
  return (
    <div
      className={cn(
        "flex flex-col items-center justify-center w-14",
        className
      )}
      onClick={onClick}
    >
      <button
        className={cn("p-2 border border-black rounded-full", buttonClassName)}
      >
        {icon}
      </button>
      <h2 className="text-xs mt-[2px]">{text}</h2>
    </div>
  );
};

export default ToolbarAction;
