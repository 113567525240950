import * as React from "react";
import { createGlobalStyle } from "styled-components";

import { useGetAppStyle } from "./poster/posterHooks";
import { generateStylesheet } from "./theme";

interface Props {
  children: JSX.Element;
}

const GlobalStyle = createGlobalStyle`
${(props): string => props.theme}
`;

const AppTheme: React.FC<Props> = ({ children }: Props) => {
  const stylesheet = useGetAppStyle();

  if (!stylesheet) return null;

  return (
    <>
      <GlobalStyle theme={generateStylesheet(stylesheet)} />
      {children}
    </>
  );
};
export default AppTheme;
