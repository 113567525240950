import React, { FC } from "react";
import { useHistory } from "react-router-dom";
import { motion } from "framer-motion";
import * as Sentry from "@sentry/react";

import Button from "../shared/Button";
import { useCreationState, useCaptureFan } from "../creationFlowHooks";
import { FiRotateCcw } from "react-icons/fi";
import Layout from "../shared/Layout";
import Header from "../shared/Header";
import Hero from "../shared/Hero";
import { captureImage } from "../useCamera";
import { IoIosArrowRoundForward } from "react-icons/io";
import { useLocalDownloadEnabled } from "../poster/posterHooks";

const Share: FC = () => {
  const { previewPoster, customerDetails } = useCreationState();
  const history = useHistory();

  const [isLoaded, setIsLoaded] = React.useState(false);

  const onRetakeSuccess = React.useCallback(() => {
    history.replace("/loading");
  }, [history]);

  const { setupCamera, loading } = useCaptureFan(onRetakeSuccess);

  const variants = {
    loaded: { opacity: 1, scale: 1 },
  };

  const localDownloadEnabled = useLocalDownloadEnabled();
  const download = (finalPosterSrc: string) => {
    try {
      const now = new Date();
      const timeStamp = `${now.getFullYear()}${
        now.getMonth() + 1
      }${now.getDate()}-${now.getHours()}${now.getMinutes()}`;
      const link = document.createElement("a");
      link.href = finalPosterSrc;
      link.download = `${timeStamp}_${customerDetails?.firstName}_${customerDetails?.lastName}.jpg`;
      link.click();
    } catch (exception) {
      Sentry.captureMessage("Issue downloading locally.");
      Sentry.captureException(exception);
    }
  };

  return (
    <Layout>
      <>
        <Hero
          role="SHARE_PAGE"
          defaultHeader={<Header text="Share Your Poster" />}
        />
        <div className="flex flex-grow justify-center flex-col items-center my-4 md:my-8 mx-5 overflow-hidden">
          {previewPoster && (
            <motion.img
              animate={isLoaded ? "loaded" : "closed"}
              initial={{
                opacity: 0,
                scale: 0.75,
              }}
              variants={variants}
              alt="Final Generated Poster"
              className="rounded-lg"
              src={previewPoster.src}
              onLoad={() => {
                setTimeout(() => {
                  setIsLoaded(true);
                }, 500);
              }}
            />
          )}
        </div>
        <div className="flex items-end mx-5 pb-5">
          <Button
            isLoading={loading}
            onClick={captureImage}
            disabled={loading}
            theme="secondary"
            icon={
              <div className="ml-2">
                <FiRotateCcw />
              </div>
            }
            className="mr-8"
          >
            Retake
          </Button>
          {setupCamera}
          <Button
            theme="secondary"
            className="!px-2"
            icon={<IoIosArrowRoundForward size={30} />}
            onClick={() => {
              if (localDownloadEnabled && previewPoster) {
                download(previewPoster.src);
              }
              history.replace("/customer-details");
            }}
          >
            Next
          </Button>
        </div>
      </>
    </Layout>
  );
};

export default Share;
