import { CreationActions, CustomerDetails, FanParams } from "./types";

export enum Types {
  ProccessRequest = "PROCESS_IMAGE_REQUEST",
  ProccessSucess = "PROCESS_IMAGE_SUCCESS",
  ProccessFailure = "PROCESS_IMAGE_FAILURE",
  SetOriginal = "SET_ORIGINAL_IMAGE",
  SetTweaked = "SET_TWEAKED_IMAGE",
  SetCustomerDetails = "SET_CUSTOMER_DETAILS",
  ResetProcessedImage = "RESET_PROCESSED_IMAGE",
  SetUploadedFanUrl = "SET_UPLOADED_FAN_URL",
  SetPreviewPoster = "SET_PREVIEW_POSTER",
  SetFanParams = "SET_FAN_PARAMS",
  SetExportLayers = "SET_EXPORT_LAYERS",
  InitCart = "INIT_CART",
  SetCartIteQuantity = "SET_CART_ITEM_QUANTITY",
  SetPurchaseDetails = "SET_PURCHASE_DETAILS",
  SetFullResFanUrl = "SET_FULL_RES_FAN_URL",
}

export const processImageSuccess = (imageUrl: string): CreationActions => ({
  type: Types.ProccessSucess,
  payload: {
    processedImage: imageUrl,
  },
});

export const processImageFailure = (errorCode): CreationActions => ({
  type: Types.ProccessFailure,
  payload: {
    errorCode,
  },
});

export const setPreviewPoster = (image: HTMLImageElement): CreationActions => ({
  type: Types.SetPreviewPoster,
  payload: {
    previewPoster: image,
  },
});

export const setCustomerDetails = (
  customerDetails: CustomerDetails
): CreationActions => ({
  type: Types.SetCustomerDetails,
  payload: {
    customerDetails,
  },
});

export const resetProcessedImage = (): CreationActions => ({
  type: Types.ResetProcessedImage,
  payload: {},
});

export const setUploadedFanUrl = (uploadedFanUrl: string): CreationActions => ({
  type: Types.SetUploadedFanUrl,
  payload: {
    uploadedFanUrl,
  },
});

export const setFanParams = (fanParams: FanParams): CreationActions => ({
  type: Types.SetFanParams,
  payload: {
    fanParams,
  },
});

export const SetExportLayers = (exportLayers: number[]): CreationActions => ({
  type: Types.SetExportLayers,
  payload: {
    exportLayers,
  },
});

export const setFullResFanUrl = (fullResFanUrl: string): CreationActions => ({
  type: Types.SetFullResFanUrl,
  payload: {
    fullResFanUrl,
  },
});
