import * as React from "react";

import Card from "./Card";
import { Nullable } from "../types";
import { Maybe } from "graphql/jsutils/Maybe";
import manOutlineUrl from "../assets/images/man-outline.svg";
import infoIconUrl from "../assets/images/info-icon.svg";

interface Props {
  title: string;
  body: Nullable<string> | Maybe<string>;
  icon: Maybe<{ url: string }>;
}

const InstructionCard: React.FC<Props> = ({ title, body, icon }: Props) => {
  const imageUrl = icon ? icon.url : manOutlineUrl;
  return (
    <div className="h-full mx-6">
      <Card>
        <div className="flex flex-1 flex-col items-center mt-9 px-6">
          <h1 className="text-h3 sm:text-h1 text-center text-primary font-bold">
            {title}
          </h1>
          <img alt="Man Outline" src={imageUrl} className="my-4 h-60" />
          {body &&
            body.split("<br/>").map((info) => (
              <div
                key={info}
                className="flex items-center self-start mb-4 text-sm sm:text-base"
              >
                <img
                  alt="Info Icon"
                  src={infoIconUrl}
                  className="h-4 w-4 mr-4"
                />
                <p>{info}</p>
              </div>
            ))}
        </div>
      </Card>
    </div>
  );
};
export default InstructionCard;
