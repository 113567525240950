import * as React from "react";

import cn from "classnames";

interface Props {
  color?: string;
  height?: string;
  width?: string;
  strokeWidth?: number;
}

const LoadingSpinner: React.FC<Props> = ({
  color = "white",
  height = "200px",
  width = "200px",
  strokeWidth = 4,
}: Props) => {
  return (
    <svg
      width={width}
      height={height}
      viewBox="0 0 100 100"
      preserveAspectRatio="xMidYMid"
      className="block animate-spin -m-1"
    >
      <circle
        cx="50"
        cy="50"
        fill="none"
        className={cn("stroke-current", `text-${color}`)}
        strokeWidth={strokeWidth}
        r="35"
        strokeDasharray="164.93361431346415 56.97787143782138"
        transform="matrix(1,0,0,1,0,0)"
      ></circle>
    </svg>
  );
};
export default React.memo(LoadingSpinner);
