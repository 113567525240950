import * as React from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import "react-responsive-modal/styles.css";
import { Modal } from "react-responsive-modal";
import ReactMarkdown from "react-markdown";

import { useCreationState } from "../creationFlowHooks";

import Layout from "../shared/Layout";
import Header from "../shared/Header";
import InfoHeader from "../shared/InfoHeader";
import Hero from "../shared/Hero";
import CustomerDetailsForm from "./CustomerDetailsForm";
import {
  useCreatePosterEntry,
  useGetCurrentPoster,
  useSubmitPoster,
} from "../poster/posterHooks";
import { removeAdditionalPrefix } from "../details/AdditionalData";

const CustomerDetails: React.FC = () => {
  const { error, previewPoster, customerDetails } = useCreationState();
  const { poster } = useGetCurrentPoster();
  const history = useHistory();
  const { loading, submitPoster } = useSubmitPoster();
  const [termsModalOpen, setTermsModalOpen] = React.useState(false);
  const finalizePoster = useCreatePosterEntry();
  const onOpenTermsModal = () => setTermsModalOpen(true);
  const onCloseTermsModal = () => setTermsModalOpen(false);

  React.useEffect(() => {
    if (error) {
      history.replace("/");
      toast.error(error);
    }
  }, [error, history]);

  React.useEffect(() => {
    if (previewPoster && customerDetails) {
      finalizePoster();
    }
    // eslint-disable-next-line
  }, []);

  return (
    <Layout>
      <Hero
        role="FORM_PAGE"
        defaultHeader={<Header text="Enter Your Details" />}
      />
      <div className="flex flex-col flex-1 items-center mx-5 overflow-hidden h-full">
        <Modal
          open={termsModalOpen}
          onClose={onCloseTermsModal}
          center
          styles={{
            modal: {
              height: "unset",
              minHeight: "20rem",
              width: "80%",
            },
          }}
        >
          <div
            className={`p-6 antialiased ${
              poster?.path === "oppo" ? "font-body-oppo" : "font-body"
            }`}
          >
            <ReactMarkdown>
              {poster?.template.termsText as string}
            </ReactMarkdown>
          </div>
        </Modal>
        <InfoHeader
          header={
            poster?.template.formText ||
            "Enter your info below to recieve your photo and be in to win."
          }
        />

        <div className="flex flex-grow overflow-y-scroll mt-4 w-full rounded-lg">
          <CustomerDetailsForm
            isLoading={loading}
            onSubmit={(e) => {
              let extraData: any | undefined =
                poster?.path.toLocaleLowerCase() === "maccas360challenge"
                  ? { image_opt_in: e.imageOptIn }
                  : undefined;
              const additionalData = removeAdditionalPrefix(customerDetails);
              if (extraData && additionalData) {
                extraData = { ...extraData, ...additionalData };
              } else if (extraData === undefined && additionalData) {
                extraData = additionalData;
              }
              let optIn = false;
              if (e.emailMe !== undefined) {
                optIn = e.emailMe;
              }
              submitPoster(
                e.email,
                {
                  firstName: e.firstName,
                  lastName: e.lastName,
                },
                optIn,
                extraData
              );
            }}
            openTermsModal={onOpenTermsModal}
          />
        </div>
      </div>
    </Layout>
  );
};

export default CustomerDetails;
