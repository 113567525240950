import React from "react";
import classNames from "classnames";

interface Props {
  header: string;
  className?: string;
}

const InfoHeader: React.FC<Props> = ({ header, className = "mt-7" }: Props) => {
  console.log(className);
  return (
    <div className={classNames(className)}>
      <h1 className="text-base font-bold text-center">{header}</h1>
    </div>
  );
};
export default InfoHeader;
