import * as React from "react";
import { FaCamera } from "react-icons/fa";

import Card from "./Card";
import { useHowToPlayEnabled } from "../poster/posterHooks";

const DefaultInstructionCard: React.FC = () => {
  const howToPlayEnabled = useHowToPlayEnabled();

  const primaryColor = getComputedStyle(
    document.documentElement
  ).getPropertyValue("--color-primary");
  return (
    <div className="h-full mx-6">
      <Card>
        <div className="flex flex-1 flex-col items-center justify-center">
          <FaCamera color={primaryColor} size={50} className="mb-20 h-10" />
          <h1 className="text-2xl sm:text-4xl font-bold text-center">
            {howToPlayEnabled ? (
              <>
                How to take <br /> the perfect
                <br /> photo.
              </>
            ) : (
              <> </>
            )}
          </h1>
        </div>
      </Card>
    </div>
  );
};
export default DefaultInstructionCard;
