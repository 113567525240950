import * as React from "react";
import { useForm } from "react-hook-form";
import { IoIosArrowRoundForward } from "react-icons/io";
import { HiOutlineMail } from "react-icons/hi";
import { BsFillPersonLinesFill } from "react-icons/bs";

import Input from "../shared/Input";
import * as icons from "../assets/images/icons";
import { useDefaultOptin, useGetCurrentPoster } from "../poster/posterHooks";
import Button from "../shared/Button";

import { isDev } from "../enviromentHelper";

interface Props {
  onSubmit: (data: any) => void;
  isLoading: boolean;
  openTermsModal: () => void;
}
const CustomerDetailsForm: React.ForwardRefRenderFunction<
  HTMLFormElement,
  Props
> = ({ onSubmit, isLoading, openTermsModal }) => {
  const { poster } = useGetCurrentPoster();
  const defaultOptin = useDefaultOptin(poster);

  const defaultValues = isDev
    ? {
        firstName: "Malcolm",
        lastName: "Durling",
        email: "malcolm@durling.co.nz",
        phone: "02150332",
        termsAccepted: false,
        emailMe: true,
        imageOptIn: true,
      }
    : {};

  defaultValues.emailMe = defaultOptin;
  //Temp code for maccas360challenge. Longer term this would come through
  //from the poster like optInEnabled
  const showImageOptIn = poster?.path.toLowerCase() === "maccas360challenge";
  if (showImageOptIn) {
    defaultValues.imageOptIn = true;
  }
  if (poster?.path.endsWith("SuperFan")) {
    defaultValues.termsAccepted = true;
    defaultValues.emailMe = true;
  }
  const { register, handleSubmit, errors } = useForm({
    defaultValues: defaultValues,
  });

  return (
    <form
      className="flex flex-1 flex-col rounded-lg px-5 pt-7 pb-7 mb-2 bg-white overflow-y-auto"
      onSubmit={handleSubmit(onSubmit)}
    >
      {poster?.form.firstNameEnabled && (
        <div className="mb-4">
          <Input
            icon={<BsFillPersonLinesFill />}
            error={errors.firstName}
            name="firstName"
            label={poster?.form.firstNameLabel || "First Name"}
            {...(poster?.form.firstNamePlaceholder && {
              placeholder: poster?.form.firstNamePlaceholder,
            })}
            register={register({ required: true })}
          />
        </div>
      )}
      {poster?.form.lastNameEnabled && (
        <div className="mb-4">
          <Input
            icon={<BsFillPersonLinesFill />}
            error={errors.lastName}
            name="lastName"
            label={poster?.form.lastNameLabel || "Last Name"}
            register={register({ required: true })}
            {...(poster?.form.lastNamePlaceholder && {
              placeholder: poster?.form.lastNamePlaceholder,
            })}
          />
        </div>
      )}
      {poster?.form.emailEnabled && (
        <div className="mb-4">
          <Input
            icon={<HiOutlineMail />}
            name="email"
            type="email"
            error={errors.email}
            label={poster?.form.emailLabel || "Email"}
            register={register({
              required: true,
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: "invalid email address",
              },
            })}
            {...(poster?.form.emailPlaceholder && {
              placeholder: poster?.form.emailPlaceholder,
            })}
          />
        </div>
      )}
      {poster?.form.mobileNumberEnabled && (
        <div className="mb-4">
          <Input
            icon={
              <img
                alt="Ball Icon"
                src={icons.ballIconUrl}
                className="flex-shrink-0 h-3"
              />
            }
            name="phone"
            label={poster?.form.mobileNumberLabel || "Mobile"}
            {...(poster?.form.mobileNumberPlaceholder && {
              placeholder: poster?.form.mobileNumberPlaceholder,
            })}
            register={register({ required: true })}
          />
        </div>
      )}
      <div className="mx-3 mt-3 mb-5">
        <div className="mb-2 text-label text-danger">
          {errors.termsAccepted && "Please agree to the terms and conditions"}
        </div>
        {poster?.form.termsAndConditionsEnabled && (
          <div className="flex items-center">
            <input
              type="checkbox"
              name="termsAccepted"
              className="flex-grow-0 mr-3"
              ref={register({ required: true })}
            />
            <label
              htmlFor="termsAccepted"
              className="block text-xs iphonePlus:text-sm md:text-base"
            >
              {poster?.form.termsAndConditionsLabel}
            </label>
          </div>
        )}
        {poster?.form.optInEnabled && (
          <div className="flex items-center mt-2">
            <input
              type="checkbox"
              name="emailMe"
              id="emailMe"
              className="self-start mt-1 mr-3"
              ref={register}
            />
            <label
              htmlFor="emailMe"
              className="block text-xs iphonePlus:text-sm md:text-base"
            >
              {poster?.form.optInLabel}
            </label>
          </div>
        )}

        {showImageOptIn && (
          <div className="flex items-center mt-2">
            <input
              type="checkbox"
              name="imageOptIn"
              id="imageOptIn"
              className="self-start mr-3 mt-1 min-w-[20px]"
              ref={register}
            />

            <label
              htmlFor="imageOptIn"
              className="block text-xs iphonePlus:text-sm md:text-base"
            >
              Yes, I am happy for this image to be displayed on the Wellington
              Phoenix Facebook
            </label>
          </div>
        )}
        {poster?.form.termsAndConditionsEnabled && (
          <div className="flex items-center mt-2">
            <button
              className="bg-gray-400 block text-label p-2"
              onClick={openTermsModal}
              type="button"
            >
              View terms and conditions
            </button>
          </div>
        )}
      </div>
      <div className="flex flex-grow items-end mt-12 iphonePlus:mt-5">
        <Button
          theme="secondary"
          isLoading={isLoading}
          disabled={isLoading}
          type="submit"
          className="self-end"
          icon={<IoIosArrowRoundForward size={30} />}
        >
          Enter
        </Button>
      </div>
    </form>
  );
};

export default CustomerDetailsForm;
