import { PosterStylesheet } from "./generated/graphql";

const defaultStylesheet = {
  backgroundImage: null,
  bodyBgColor: "#1188ab",
  bodyColor: "#ffffff",
  cardBgColor: "#f8fafc",
  cardColor: "#000000",
  dangerColor: "#dc3545",
  facebookShareImage: null,
  headerImage: null,
  infoColor: "#1188ab",
  lightColor: "#f8f9fa",
  primaryColor: "red",
  secondaryColor: "#F6F7FA",
  splashPageImage: null,
  successColor: "#28a745",
};

export const generateStylesheet = (stylesheet: PosterStylesheet): string => {
  return `
        html {
            --color-primary: ${
              stylesheet.infoColor || defaultStylesheet.primaryColor
            };
            --color-secondary: ${
              stylesheet.secondaryColor || defaultStylesheet.secondaryColor
            };
            --color-success: ${
              stylesheet.successColor || defaultStylesheet.successColor
            };
            --color-danger: ${
              stylesheet.dangerColor || defaultStylesheet.dangerColor
            };
            --color-info: ${
              stylesheet.infoColor || defaultStylesheet.infoColor
            };
            --color-bg-body: ${
              stylesheet.bodyBgColor || defaultStylesheet.bodyBgColor
            };
            --color-body: ${
              stylesheet.bodyColor || defaultStylesheet.bodyBgColor
            };
            --color-bg-card: ${
              stylesheet.cardBgColor || defaultStylesheet.cardBgColor
            };
            --color-card: ${
              stylesheet.cardColor || defaultStylesheet.cardColor
            };
        }
    `;
};
