import React from "react";

import Layout from "../shared/Layout";
import Header from "../shared/Header";
import Hero from "../shared/Hero";

const CheckoutSuccess: React.FC = () => {
  const checkoutPreviewPosterUrl = localStorage.getItem(
    "checkoutPreviewPosterUrl"
  );

  return (
    <Layout>
      <>
        <Hero
          role="SHARE_PAGE"
          defaultHeader={<Header text="Share Your Poster" />}
        />
        <div className="flex flex-grow mt-10 flex-col items-center my-4 md:my-8 mx-8 overflow-hidden">
          <h1 className="text-3xl text-center">Success!</h1>
          <h2 className="text-xl">Thank you for your purchase.</h2>
          {checkoutPreviewPosterUrl && (
            <img src={checkoutPreviewPosterUrl} className="my-5 h-4/6" />
          )}
          <h1 className="mt-2 text-center">
            You will receive a confirmation email soon. Email us at
            support@smiledealers.com if you have any issues.
          </h1>
        </div>
      </>
    </Layout>
  );
};

export default CheckoutSuccess;
