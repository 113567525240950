import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: any }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** An ISO 8601-encoded datetime */
  ISO8601DateTime: any;
  /** Represents untyped JSON */
  JSON: any;
};

export type AccessToken = {
  __typename?: 'AccessToken';
  createdAt: Scalars['ISO8601DateTime'];
  expiresIn: Scalars['Int'];
  scopes: Scalars['String'];
  token: Scalars['String'];
};

export type AdditionalPosterDataInput = {
  optionSetId: Scalars['ID'];
  optionId: Scalars['ID'];
};

export type AdditionalShare = Node & {
  __typename?: 'AdditionalShare';
  additionalPoints: Scalars['Int'];
  buttonText: Scalars['String'];
  icon?: Maybe<Image>;
  iconType: Scalars['String'];
  id: Scalars['ID'];
  instructions: Scalars['String'];
  link: Scalars['Boolean'];
  linkButtonText?: Maybe<Scalars['String']>;
  linkHref?: Maybe<Scalars['String']>;
  title: Scalars['String'];
  userInput: Scalars['Boolean'];
  userInputButtonText?: Maybe<Scalars['String']>;
  userInputPlaceholder?: Maybe<Scalars['String']>;
};

/** Autogenerated input type of AdditionalShare */
export type AdditionalShareInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  via: Scalars['ID'];
  shareId: Scalars['ID'];
  userInput?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of AdditionalShare */
export type AdditionalSharePayload = {
  __typename?: 'AdditionalSharePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

export type BackdropSettings = {
  __typename?: 'BackdropSettings';
  answerColour: Scalars['String'];
  firstNameColour: Scalars['String'];
  highlightColour: Scalars['String'];
  lastNameColour: Scalars['String'];
  variableColour: Scalars['String'];
};

export type BasicQuiz = Node & Quiz & {
  __typename?: 'BasicQuiz';
  additionalSharing: Array<AdditionalShare>;
  created?: Maybe<Scalars['ISO8601DateTime']>;
  domain?: Maybe<Scalars['String']>;
  enterBeforeQuiz: Scalars['Boolean'];
  form: QuizForm;
  id: Scalars['ID'];
  name: Scalars['String'];
  questions: QuestionConnection;
  stylesheet: QuizStylesheet;
  template: QuizTemplate;
  updated?: Maybe<Scalars['ISO8601DateTime']>;
  useImageOptions: Scalars['Boolean'];
};


export type BasicQuizQuestionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type BasicQuizEntry = Entry & HasEntryTrivia & Node & {
  __typename?: 'BasicQuizEntry';
  answers: Array<OptionSetAnswer>;
  correctAnswerQuestionTrivia: Scalars['String'];
  correctAnswerResultsTrivia: Scalars['String'];
  created?: Maybe<Scalars['ISO8601DateTime']>;
  data: Array<OptionSetEntry>;
  id: Scalars['ID'];
  question: BasicQuizQuestion;
  quiz: BasicQuiz;
  score: Scalars['Int'];
  updated?: Maybe<Scalars['ISO8601DateTime']>;
};

export type BasicQuizQuestion = Node & Question & {
  __typename?: 'BasicQuizQuestion';
  created?: Maybe<Scalars['ISO8601DateTime']>;
  data: Array<OptionSetQuestion>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  position?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  updated?: Maybe<Scalars['ISO8601DateTime']>;
  version: Scalars['Int'];
};

export type Category = {
  __typename?: 'Category';
  id: Scalars['ID'];
  image?: Maybe<Image>;
  text: Scalars['String'];
  title: Scalars['String'];
};

/** The connection type for Category. */
export type CategoryConnection = {
  __typename?: 'CategoryConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<CategoryEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Category>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type CategoryEdge = {
  __typename?: 'CategoryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Category>;
};

export type CategoryEntry = {
  __typename?: 'CategoryEntry';
  questionId: Scalars['ID'];
  value: Scalars['Int'];
};

export type CategoryEntryInput = {
  questionId: Scalars['ID'];
  value: Scalars['Int'];
};

export type CategoryQuestion = {
  __typename?: 'CategoryQuestion';
  id: Scalars['ID'];
  image: Image;
  title?: Maybe<Scalars['String']>;
};

export type CategoryValue = {
  __typename?: 'CategoryValue';
  categoryId: Scalars['ID'];
  value: Scalars['Int'];
};

export type Client = Node & {
  __typename?: 'Client';
  id: Scalars['ID'];
};

/** Autogenerated input type of CreateBasicQuizEntry */
export type CreateBasicQuizEntryInput = {
  quizId: Scalars['ID'];
  questionId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  data: Array<OptionSetEntryInput>;
};

/** Autogenerated return type of CreateBasicQuizEntry */
export type CreateBasicQuizEntryPayload = {
  __typename?: 'CreateBasicQuizEntryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  entry?: Maybe<BasicQuizEntry>;
  errors: Array<MutationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreateDisappearingImageEntry */
export type CreateDisappearingImageEntryInput = {
  quizId: Scalars['ID'];
  questionId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  data: Array<OptionSetEntryInput>;
  timeRemaining: Scalars['Float'];
};

/** Autogenerated return type of CreateDisappearingImageEntry */
export type CreateDisappearingImageEntryPayload = {
  __typename?: 'CreateDisappearingImageEntryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  entry?: Maybe<DisappearingImageEntry>;
  errors: Array<MutationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreateInteractiveQuizEntry */
export type CreateInteractiveQuizEntryInput = {
  quizId: Scalars['ID'];
  questionId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  data: Scalars['Float'];
};

/** Autogenerated return type of CreateInteractiveQuizEntry */
export type CreateInteractiveQuizEntryPayload = {
  __typename?: 'CreateInteractiveQuizEntryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  entry?: Maybe<InteractiveQuizEntry>;
  errors: Array<MutationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreateListGameEntry */
export type CreateListGameEntryInput = {
  quizId: Scalars['ID'];
  questionId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  data: Array<OptionSetEntryInput>;
};

/** Autogenerated return type of CreateListGameEntry */
export type CreateListGameEntryPayload = {
  __typename?: 'CreateListGameEntryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  entry?: Maybe<ListGameEntry>;
  errors: Array<MutationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreatePersonalityQuizEntry */
export type CreatePersonalityQuizEntryInput = {
  quizId: Scalars['ID'];
  questionId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  data: Array<CategoryEntryInput>;
};

/** Autogenerated return type of CreatePersonalityQuizEntry */
export type CreatePersonalityQuizEntryPayload = {
  __typename?: 'CreatePersonalityQuizEntryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  entry?: Maybe<PersonalityQuizEntry>;
  errors: Array<MutationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreatePosterEntry */
export type CreatePosterEntryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  posterId: Scalars['ID'];
  backdropId: Scalars['ID'];
  posterData?: Maybe<PosterDataInput>;
};

/** Autogenerated return type of CreatePosterEntry */
export type CreatePosterEntryPayload = {
  __typename?: 'CreatePosterEntryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  entry?: Maybe<PosterEntry>;
  errors: Array<MutationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreatePosterOrder */
export type CreatePosterOrderInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  posterId: Scalars['ID'];
  orderEntries: Array<OrderEntryInput>;
};

/** Autogenerated return type of CreatePosterOrder */
export type CreatePosterOrderPayload = {
  __typename?: 'CreatePosterOrderPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<MutationError>;
  intent?: Maybe<PaymentIntent>;
  order?: Maybe<PosterOrder>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreateScratchAndWinEntries */
export type CreateScratchAndWinEntriesInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  quizId: Scalars['ID'];
};

/** Autogenerated return type of CreateScratchAndWinEntries */
export type CreateScratchAndWinEntriesPayload = {
  __typename?: 'CreateScratchAndWinEntriesPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  entries?: Maybe<Array<ScratchAndWinEntry>>;
  errors: Array<MutationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of CreateUser */
export type CreateUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
};

/** Autogenerated return type of CreateUser */
export type CreateUserPayload = {
  __typename?: 'CreateUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
  userToken?: Maybe<AccessToken>;
};

/** Payment currencies */
export enum Currency {
  /** New Zealand Dollars */
  Nzd = 'NZD'
}

export type DisappearingImageEntry = Entry & HasEntryTrivia & Node & {
  __typename?: 'DisappearingImageEntry';
  answers: Array<OptionSetAnswer>;
  correctAnswerQuestionTrivia: Scalars['String'];
  correctAnswerResultsTrivia: Scalars['String'];
  created?: Maybe<Scalars['ISO8601DateTime']>;
  data: Array<OptionSetEntry>;
  id: Scalars['ID'];
  question: DisappearingImageQuestion;
  quiz: DisappearingImageQuiz;
  score: Scalars['Int'];
  timeRemaining: Scalars['Float'];
  updated?: Maybe<Scalars['ISO8601DateTime']>;
};

export type DisappearingImageQuestion = Node & Question & {
  __typename?: 'DisappearingImageQuestion';
  created?: Maybe<Scalars['ISO8601DateTime']>;
  data: Array<OptionSetQuestion>;
  description?: Maybe<Scalars['String']>;
  heroImage?: Maybe<Image>;
  id: Scalars['ID'];
  points: Scalars['Int'];
  position?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  updated?: Maybe<Scalars['ISO8601DateTime']>;
  version: Scalars['Int'];
};

export type DisappearingImageQuiz = Node & Quiz & {
  __typename?: 'DisappearingImageQuiz';
  additionalSharing: Array<AdditionalShare>;
  created?: Maybe<Scalars['ISO8601DateTime']>;
  domain?: Maybe<Scalars['String']>;
  form: QuizForm;
  id: Scalars['ID'];
  name: Scalars['String'];
  questions: QuestionConnection;
  stylesheet: QuizStylesheet;
  template: QuizTemplate;
  timer: Scalars['Int'];
  updated?: Maybe<Scalars['ISO8601DateTime']>;
};


export type DisappearingImageQuizQuestionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** Autogenerated input type of DualIntegersDualAnswerMutation */
export type DualIntegersDualAnswerMutationInput = {
  questionId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  answers: Array<IntegerAnswerAttributes>;
};

/** Autogenerated return type of DualIntegersDualAnswerMutation */
export type DualIntegersDualAnswerMutationPayload = {
  __typename?: 'DualIntegersDualAnswerMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<MutationError>;
  question: EventQuestion;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of DualOptionSetsSingleAnswerMutation */
export type DualOptionSetsSingleAnswerMutationInput = {
  questionId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  answer: OptionSetAnswerAttributes;
};

/** Autogenerated return type of DualOptionSetsSingleAnswerMutation */
export type DualOptionSetsSingleAnswerMutationPayload = {
  __typename?: 'DualOptionSetsSingleAnswerMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<MutationError>;
  question: EventQuestion;
  success: Scalars['Boolean'];
};

/** An object with an ID. */
export type Entry = {
  created?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  score: Scalars['Int'];
  updated?: Maybe<Scalars['ISO8601DateTime']>;
};

/** The connection type for Entry. */
export type EntryConnection = {
  __typename?: 'EntryConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EntryEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Entry>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type EntryEdge = {
  __typename?: 'EntryEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Entry>;
};

export type Event = Node & {
  __typename?: 'Event';
  id: Scalars['ID'];
  name: Scalars['String'];
  questions: EventQuestionConnection;
};


export type EventQuestionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** EventCalculation data types */
export type EventAnswerData = IntegerAnswer | OptionSetAnswer;

export type EventEntry = Entry & Node & {
  __typename?: 'EventEntry';
  answers: Array<EventAnswerData>;
  created?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  question: EventQuestion;
  score: Scalars['Int'];
  updated?: Maybe<Scalars['ISO8601DateTime']>;
};

/** EventCalculation data types */
export type EventEvaluatorsData = IntegerPlusMinus | OptionSetExact;

export type EventQuestion = Node & Question & {
  __typename?: 'EventQuestion';
  answers: Array<EventAnswerData>;
  created?: Maybe<Scalars['ISO8601DateTime']>;
  data: Array<EventQuestionData>;
  description?: Maybe<Scalars['String']>;
  evaluators?: Maybe<Array<EventEvaluatorsData>>;
  id: Scalars['ID'];
  judged: Scalars['Boolean'];
  lastJudgedAt?: Maybe<Scalars['ISO8601DateTime']>;
  position?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  type: QuestionTypes;
  updated?: Maybe<Scalars['ISO8601DateTime']>;
  version: Scalars['Int'];
};

/** The connection type for EventQuestion. */
export type EventQuestionConnection = {
  __typename?: 'EventQuestionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<EventQuestionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<EventQuestion>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** EventQuestion data types */
export type EventQuestionData = IntegerQuestion | OptionSetQuestion;

/** An edge in a connection. */
export type EventQuestionEdge = {
  __typename?: 'EventQuestionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<EventQuestion>;
};

export type HasEntryTrivia = {
  correctAnswerQuestionTrivia: Scalars['String'];
  correctAnswerResultsTrivia: Scalars['String'];
};

export type HeaderImage = {
  __typename?: 'HeaderImage';
  height?: Maybe<Scalars['Int']>;
  mimeType: Scalars['String'];
  role: HeaderImageRole;
  size: Scalars['Int'];
  url: Scalars['String'];
  versions: ImageVersions;
  width?: Maybe<Scalars['Int']>;
};

/** Usage recommendation for Quiz and Poster header images */
export enum HeaderImageRole {
  /** General usage */
  Default = 'DEFAULT',
  /** Entry page usage */
  SplashPage = 'SPLASH_PAGE',
  /** Instructions pages usage */
  HowToPlayPage = 'HOW_TO_PLAY_PAGE',
  /** User interaction pages */
  QuestionPage = 'QUESTION_PAGE',
  /** User form pages */
  FormPage = 'FORM_PAGE',
  /** Poster positioning pages */
  PositionPage = 'POSITION_PAGE',
  /** Poster personalisation pages */
  PersonalisePage = 'PERSONALISE_PAGE',
  /** Poster finalisation pages */
  FinalisePage = 'FINALISE_PAGE',
  /** Sharing pages */
  SharePage = 'SHARE_PAGE'
}

export type HowToPlayPage = {
  __typename?: 'HowToPlayPage';
  icon?: Maybe<Image>;
  id: Scalars['ID'];
  position: Scalars['Int'];
  text?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};


export type Image = {
  __typename?: 'Image';
  height?: Maybe<Scalars['Int']>;
  mimeType: Scalars['String'];
  size: Scalars['Int'];
  url: Scalars['String'];
  width?: Maybe<Scalars['Int']>;
};

export type ImageVersions = {
  __typename?: 'ImageVersions';
  large?: Maybe<Image>;
  largeBw?: Maybe<Image>;
  medium?: Maybe<Image>;
  mediumBw?: Maybe<Image>;
  small?: Maybe<Image>;
  smallBw?: Maybe<Image>;
};

export type ImageWithVersions = {
  __typename?: 'ImageWithVersions';
  height?: Maybe<Scalars['Int']>;
  mimeType: Scalars['String'];
  size: Scalars['Int'];
  url: Scalars['String'];
  versions: ImageVersions;
  width?: Maybe<Scalars['Int']>;
};

export type IntegerAnswer = {
  __typename?: 'IntegerAnswer';
  key: Scalars['String'];
  value?: Maybe<Scalars['Int']>;
};

export type IntegerAnswerAttributes = {
  key: Scalars['String'];
  value: Scalars['Int'];
};

export type IntegerPlusMinus = {
  __typename?: 'IntegerPlusMinus';
  difference: Scalars['Int'];
  value: Scalars['Int'];
};

export type IntegerQuestion = {
  __typename?: 'IntegerQuestion';
  key: Scalars['String'];
  title?: Maybe<Scalars['String']>;
};

/** The type of interactive game to load for a question */
export enum InteractiveGames {
  /** Test your memory skills */
  MemoryGame = 'MEMORY_GAME',
  /** Infinite runner! */
  RunnerGame = 'RUNNER_GAME'
}

export type InteractiveQuiz = Node & Quiz & {
  __typename?: 'InteractiveQuiz';
  additionalSharing: Array<AdditionalShare>;
  created?: Maybe<Scalars['ISO8601DateTime']>;
  domain?: Maybe<Scalars['String']>;
  form: QuizForm;
  id: Scalars['ID'];
  name: Scalars['String'];
  questions: QuestionConnection;
  stylesheet: QuizStylesheet;
  template: QuizTemplate;
  updated?: Maybe<Scalars['ISO8601DateTime']>;
};


export type InteractiveQuizQuestionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type InteractiveQuizEntry = Entry & Node & {
  __typename?: 'InteractiveQuizEntry';
  created?: Maybe<Scalars['ISO8601DateTime']>;
  data: Scalars['Float'];
  id: Scalars['ID'];
  question: InteractiveQuizQuestion;
  quiz: InteractiveQuiz;
  score: Scalars['Int'];
  updated?: Maybe<Scalars['ISO8601DateTime']>;
};

export type InteractiveQuizQuestion = Node & Question & {
  __typename?: 'InteractiveQuizQuestion';
  created?: Maybe<Scalars['ISO8601DateTime']>;
  description?: Maybe<Scalars['String']>;
  game: InteractiveGames;
  id: Scalars['ID'];
  position?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  updated?: Maybe<Scalars['ISO8601DateTime']>;
  version: Scalars['Int'];
};


export type ListGameEntry = Entry & Node & {
  __typename?: 'ListGameEntry';
  created?: Maybe<Scalars['ISO8601DateTime']>;
  data: Array<OptionSetEntry>;
  id: Scalars['ID'];
  question: ListGameQuestion;
  quiz: ListGameQuiz;
  score: Scalars['Int'];
  updated?: Maybe<Scalars['ISO8601DateTime']>;
};

export type ListGameQuestion = Node & Question & {
  __typename?: 'ListGameQuestion';
  created?: Maybe<Scalars['ISO8601DateTime']>;
  data: Array<OptionSetQuestion>;
  description?: Maybe<Scalars['String']>;
  game: ListGames;
  id: Scalars['ID'];
  position?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  updated?: Maybe<Scalars['ISO8601DateTime']>;
  version: Scalars['Int'];
};

export type ListGameQuiz = Node & Quiz & {
  __typename?: 'ListGameQuiz';
  additionalSharing: Array<AdditionalShare>;
  created?: Maybe<Scalars['ISO8601DateTime']>;
  domain?: Maybe<Scalars['String']>;
  form: QuizForm;
  id: Scalars['ID'];
  name: Scalars['String'];
  questions: QuestionConnection;
  stylesheet: QuizStylesheet;
  template: QuizTemplate;
  updated?: Maybe<Scalars['ISO8601DateTime']>;
};


export type ListGameQuizQuestionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The type of list game to load for a question */
export enum ListGames {
  /** Pick your team lineup */
  TeamPicker = 'TEAM_PICKER',
  /** Pick your rugby team lineup */
  RugbyTeamPicker = 'RUGBY_TEAM_PICKER',
  /** Pick your soccer team lineup */
  SoccerTeamPicker = 'SOCCER_TEAM_PICKER'
}

/** MatchPick GraphQL API Mutations */
export type Mutation = {
  __typename?: 'Mutation';
  /**
   * Set that a user has made an additional share for a Quiz
   * 
   * This mutation requires a User token in the Authorization header.
   */
  additionalShare?: Maybe<AdditionalSharePayload>;
  createBasicQuizEntry?: Maybe<CreateBasicQuizEntryPayload>;
  createDisappearingImageEntry?: Maybe<CreateDisappearingImageEntryPayload>;
  createInteractiveQuizEntry?: Maybe<CreateInteractiveQuizEntryPayload>;
  createListGameEntry?: Maybe<CreateListGameEntryPayload>;
  createPersonalityQuizEntry?: Maybe<CreatePersonalityQuizEntryPayload>;
  /**
   * Create a user entry for a Poster event
   * 
   * This mutation requires a User token in the Authorization header.
   */
  createPosterEntry?: Maybe<CreatePosterEntryPayload>;
  /**
   * Create an order for poster entries
   * 
   * This mutation requires a User token in the Authorization header.
   */
  createPosterOrder?: Maybe<CreatePosterOrderPayload>;
  /**
   * Create an entry for a Quiz
   * 
   * This mutation requires a User token in the Authorization header.
   */
  createScratchAndWinEntries?: Maybe<CreateScratchAndWinEntriesPayload>;
  /**
   * Create a user record for a client
   * 
   * This mutation requires a Client token in the Authorization header.
   */
  createUser?: Maybe<CreateUserPayload>;
  judgeDualIntegersDualAnswerQuestion?: Maybe<DualIntegersDualAnswerMutationPayload>;
  judgeDualOptionSetsSingleAnswerQuestion?: Maybe<DualOptionSetsSingleAnswerMutationPayload>;
  judgeSingleOptionSetSingleAnswerQuestion?: Maybe<SingleOptionSetSingleAnswerMutationPayload>;
  /**
   * Set that a user has shared a Quiz
   * 
   * This mutation requires a User token in the Authorization header.
   */
  share?: Maybe<SharePayload>;
  /**
   * Add poster data to an existing poster entry.
   * 
   * This mutation requires a User token in the Authorization header.
   */
  updatePosterEntry?: Maybe<UpdatePosterEntryPayload>;
  /**
   * Updates the Graph users details
   * 
   * This mutation requires a User token in the Authorization header.
   */
  updateUser?: Maybe<UpdateUserPayload>;
};


/** MatchPick GraphQL API Mutations */
export type MutationAdditionalShareArgs = {
  input: AdditionalShareInput;
};


/** MatchPick GraphQL API Mutations */
export type MutationCreateBasicQuizEntryArgs = {
  input: CreateBasicQuizEntryInput;
};


/** MatchPick GraphQL API Mutations */
export type MutationCreateDisappearingImageEntryArgs = {
  input: CreateDisappearingImageEntryInput;
};


/** MatchPick GraphQL API Mutations */
export type MutationCreateInteractiveQuizEntryArgs = {
  input: CreateInteractiveQuizEntryInput;
};


/** MatchPick GraphQL API Mutations */
export type MutationCreateListGameEntryArgs = {
  input: CreateListGameEntryInput;
};


/** MatchPick GraphQL API Mutations */
export type MutationCreatePersonalityQuizEntryArgs = {
  input: CreatePersonalityQuizEntryInput;
};


/** MatchPick GraphQL API Mutations */
export type MutationCreatePosterEntryArgs = {
  input: CreatePosterEntryInput;
};


/** MatchPick GraphQL API Mutations */
export type MutationCreatePosterOrderArgs = {
  input: CreatePosterOrderInput;
};


/** MatchPick GraphQL API Mutations */
export type MutationCreateScratchAndWinEntriesArgs = {
  input: CreateScratchAndWinEntriesInput;
};


/** MatchPick GraphQL API Mutations */
export type MutationCreateUserArgs = {
  input: CreateUserInput;
};


/** MatchPick GraphQL API Mutations */
export type MutationJudgeDualIntegersDualAnswerQuestionArgs = {
  input: DualIntegersDualAnswerMutationInput;
};


/** MatchPick GraphQL API Mutations */
export type MutationJudgeDualOptionSetsSingleAnswerQuestionArgs = {
  input: DualOptionSetsSingleAnswerMutationInput;
};


/** MatchPick GraphQL API Mutations */
export type MutationJudgeSingleOptionSetSingleAnswerQuestionArgs = {
  input: SingleOptionSetSingleAnswerMutationInput;
};


/** MatchPick GraphQL API Mutations */
export type MutationShareArgs = {
  input: ShareInput;
};


/** MatchPick GraphQL API Mutations */
export type MutationUpdatePosterEntryArgs = {
  input: UpdatePosterEntryInput;
};


/** MatchPick GraphQL API Mutations */
export type MutationUpdateUserArgs = {
  input: UpdateUserInput;
};

export type MutationError = {
  __typename?: 'MutationError';
  attribute: Scalars['String'];
  messages: Array<Scalars['String']>;
};

/** An object with an ID. */
export type Node = {
  /** ID of the object. */
  id: Scalars['ID'];
};

export type Option = Node & {
  __typename?: 'Option';
  id: Scalars['ID'];
  logoUrl?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type OptionSet = Node & {
  __typename?: 'OptionSet';
  id: Scalars['ID'];
  options: Array<Option>;
  title: Scalars['String'];
};

export type OptionSetAnswer = {
  __typename?: 'OptionSetAnswer';
  optionId?: Maybe<Scalars['ID']>;
  optionSetId?: Maybe<Scalars['ID']>;
};

export type OptionSetAnswerAttributes = {
  optionSetId: Scalars['ID'];
  optionId: Scalars['ID'];
};

export type OptionSetEntry = {
  __typename?: 'OptionSetEntry';
  optionId: Scalars['ID'];
  optionSetId: Scalars['ID'];
};

export type OptionSetEntryInput = {
  optionSetId: Scalars['ID'];
  optionId: Scalars['ID'];
};

export type OptionSetExact = {
  __typename?: 'OptionSetExact';
  value: Scalars['Int'];
};

export type OptionSetQuestion = {
  __typename?: 'OptionSetQuestion';
  description?: Maybe<Scalars['String']>;
  optionSet: OptionSet;
};

export type OrderEntryInput = {
  entryId: Scalars['ID'];
  copies: Scalars['Int'];
};

/** Information about pagination in a connection. */
export type PageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
};

/** An intent to pay by credit card for an order to be completed by the front end */
export type PaymentIntent = {
  __typename?: 'PaymentIntent';
  amount: Scalars['Int'];
  clientSecret: Scalars['String'];
  currency: Currency;
};

export type PersonalityQuiz = Node & Quiz & {
  __typename?: 'PersonalityQuiz';
  additionalSharing: Array<AdditionalShare>;
  categories: CategoryConnection;
  created?: Maybe<Scalars['ISO8601DateTime']>;
  domain?: Maybe<Scalars['String']>;
  form: QuizForm;
  id: Scalars['ID'];
  name: Scalars['String'];
  questions: QuestionConnection;
  stylesheet: QuizStylesheet;
  template: QuizTemplate;
  updated?: Maybe<Scalars['ISO8601DateTime']>;
};


export type PersonalityQuizCategoriesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};


export type PersonalityQuizQuestionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type PersonalityQuizEntry = Entry & Node & {
  __typename?: 'PersonalityQuizEntry';
  created?: Maybe<Scalars['ISO8601DateTime']>;
  data: Array<CategoryEntry>;
  id: Scalars['ID'];
  question: PersonalityQuizQuestion;
  quiz: PersonalityQuiz;
  score: Scalars['Int'];
  updated?: Maybe<Scalars['ISO8601DateTime']>;
  values: Array<CategoryValue>;
};

export type PersonalityQuizQuestion = Node & Question & {
  __typename?: 'PersonalityQuizQuestion';
  created?: Maybe<Scalars['ISO8601DateTime']>;
  data: Array<CategoryQuestion>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  position?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  updated?: Maybe<Scalars['ISO8601DateTime']>;
  version: Scalars['Int'];
};

export type Poster = Node & Timestamps & {
  __typename?: 'Poster';
  additionalFields: Array<PosterField>;
  additionalPosterPrice: Scalars['Int'];
  additionalSharing: Array<AdditionalShare>;
  backdrops: PosterBackdropConnection;
  backgroundRemoval: PosterBackgroundRemoval;
  /** @deprecated Deprecated in favour of PosterOutlet enum on outlet field */
  commercial: Scalars['Boolean'];
  created?: Maybe<Scalars['ISO8601DateTime']>;
  domain: Scalars['String'];
  form: PosterForm;
  id: Scalars['ID'];
  initialPosterPrice: Scalars['Int'];
  name: Scalars['String'];
  outlet: PosterOutlet;
  path: Scalars['String'];
  shippingPrice: Scalars['Int'];
  showAgeField: Scalars['Boolean'];
  stylesheet: PosterStylesheet;
  template: PosterTemplate;
  updated?: Maybe<Scalars['ISO8601DateTime']>;
};


export type PosterBackdropsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

export type PosterBackdrop = Node & Timestamps & {
  __typename?: 'PosterBackdrop';
  created?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  image?: Maybe<ImageWithVersions>;
  settings: BackdropSettings;
  updated?: Maybe<Scalars['ISO8601DateTime']>;
};

/** The connection type for PosterBackdrop. */
export type PosterBackdropConnection = {
  __typename?: 'PosterBackdropConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PosterBackdropEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<PosterBackdrop>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type PosterBackdropEdge = {
  __typename?: 'PosterBackdropEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<PosterBackdrop>;
};

/** Poster background removal service */
export enum PosterBackgroundRemoval {
  /** remove.bg service */
  RemoveBg = 'REMOVE_BG',
  /** Python server */
  Python = 'PYTHON'
}

/** The connection type for Poster. */
export type PosterConnection = {
  __typename?: 'PosterConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<PosterEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Poster>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

export type PosterDataInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  age?: Maybe<Scalars['Int']>;
  imageUrl?: Maybe<Scalars['String']>;
  layers?: Maybe<Array<PosterLayerInput>>;
  additionalData?: Maybe<Array<AdditionalPosterDataInput>>;
};

/** An edge in a connection. */
export type PosterEdge = {
  __typename?: 'PosterEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Poster>;
};

export type PosterEntry = Entry & Node & {
  __typename?: 'PosterEntry';
  backdrop: PosterBackdrop;
  compositeImage?: Maybe<Image>;
  created?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  poster: Poster;
  posterData: PosterEntryData;
  score: Scalars['Int'];
  updated?: Maybe<Scalars['ISO8601DateTime']>;
};

export type PosterEntryData = {
  __typename?: 'PosterEntryData';
  additionalData?: Maybe<Array<OptionSetEntry>>;
  age?: Maybe<Scalars['Int']>;
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

export type PosterField = {
  __typename?: 'PosterField';
  optionSetId: Scalars['ID'];
  options: Array<Option>;
  preselectedOptionId?: Maybe<Scalars['ID']>;
  title: Scalars['String'];
};

export type PosterForm = {
  __typename?: 'PosterForm';
  emailEnabled: Scalars['Boolean'];
  emailLabel?: Maybe<Scalars['String']>;
  emailPlaceholder?: Maybe<Scalars['String']>;
  firstNameEnabled: Scalars['Boolean'];
  firstNameLabel?: Maybe<Scalars['String']>;
  firstNamePlaceholder?: Maybe<Scalars['String']>;
  lastNameEnabled: Scalars['Boolean'];
  lastNameLabel?: Maybe<Scalars['String']>;
  lastNamePlaceholder?: Maybe<Scalars['String']>;
  mobileNumberEnabled: Scalars['Boolean'];
  mobileNumberLabel?: Maybe<Scalars['String']>;
  mobileNumberPlaceholder?: Maybe<Scalars['String']>;
  optInEnabled: Scalars['Boolean'];
  optInLabel?: Maybe<Scalars['String']>;
  termsAndConditionsEnabled: Scalars['Boolean'];
  termsAndConditionsLabel?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type PosterLayerInput = {
  /** The role of the layer in the stack */
  name: Scalars['String'];
  /** URL of the layer image */
  url: Scalars['String'];
  /** Index in the stack starting from 1 (0 is the backdrop image) */
  zIndex: Scalars['Int'];
};

/** An order for posters to be printed and delivered */
export type PosterOrder = Node & Timestamps & {
  __typename?: 'PosterOrder';
  created?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  items: Array<PosterOrderItem>;
  shippingAmount: Scalars['Int'];
  totalAmount: Scalars['Int'];
  updated?: Maybe<Scalars['ISO8601DateTime']>;
};

/** A poster order item with copies to print and subtotal for those copies */
export type PosterOrderItem = Node & Timestamps & {
  __typename?: 'PosterOrderItem';
  copies: Scalars['Int'];
  created?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  subtotalAmount: Scalars['Int'];
  updated?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Where for a Poster is destined */
export enum PosterOutlet {
  /** Social matches */
  Social = 'SOCIAL',
  /** Commercial activations */
  Commercial = 'COMMERCIAL',
  /** Ecommerce storefront */
  Storefront = 'STOREFRONT'
}

export type PosterStylesheet = {
  __typename?: 'PosterStylesheet';
  backgroundImage?: Maybe<ImageWithVersions>;
  bodyBgColor?: Maybe<Scalars['String']>;
  bodyColor?: Maybe<Scalars['String']>;
  cardBgColor?: Maybe<Scalars['String']>;
  cardColor?: Maybe<Scalars['String']>;
  dangerColor?: Maybe<Scalars['String']>;
  darkColor?: Maybe<Scalars['String']>;
  facebookShareImage?: Maybe<Image>;
  /** @deprecated Use headerImages field */
  headerImage?: Maybe<Image>;
  headerImages: Array<HeaderImage>;
  infoColor?: Maybe<Scalars['String']>;
  lightColor?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  splashPageImage?: Maybe<ImageWithVersions>;
  successColor?: Maybe<Scalars['String']>;
};

export type PosterTemplate = {
  __typename?: 'PosterTemplate';
  /** @deprecated The background image has been moved to the stylesheet object */
  backgroundImage?: Maybe<Image>;
  facebookShareButtonText?: Maybe<Scalars['String']>;
  facebookShareDescription?: Maybe<Scalars['String']>;
  facebookShareEnabled: Scalars['Boolean'];
  /** @deprecated Facebook share image has been moved to the stylesheet object */
  facebookShareImage?: Maybe<Image>;
  facebookShareTitle?: Maybe<Scalars['String']>;
  fbPixelId?: Maybe<Scalars['String']>;
  finaliseText: Scalars['String'];
  finaliseTitle: Scalars['String'];
  formText: Scalars['String'];
  formTitle: Scalars['String'];
  gaId?: Maybe<Scalars['String']>;
  /**
   * @deprecated       The header image has been moved to the stylesheet object.
   * 
   *       Multiple header images are also now supported, use the headerImages collection on stylesheet instead.
   * 
   */
  headerImage?: Maybe<Image>;
  howToPlayEnabled: Scalars['Boolean'];
  howToPlayPages?: Maybe<Array<HowToPlayPage>>;
  pageDescription?: Maybe<Scalars['String']>;
  pageTitle?: Maybe<Scalars['String']>;
  personaliseText: Scalars['String'];
  personaliseTitle: Scalars['String'];
  resultsPageShareEnabled?: Maybe<Scalars['Boolean']>;
  resultsPageShareText?: Maybe<Scalars['String']>;
  resultsPageShareTitle?: Maybe<Scalars['String']>;
  resultsPageText?: Maybe<Scalars['String']>;
  splashPageEnabled: Scalars['Boolean'];
  /** @deprecated The splash page image has been moved to the stylesheet object */
  splashPageImage?: Maybe<Image>;
  splashPageText?: Maybe<Scalars['String']>;
  splashPageTitle?: Maybe<Scalars['String']>;
  termsText: Scalars['String'];
};

/** MatchPick GraphQL API Queries */
export type Query = {
  __typename?: 'Query';
  /** Fetches entries for the viewer */
  entries: EntryConnection;
  /** Fetches an object given its ID */
  node?: Maybe<Node>;
  /** Fetches a list of objects given a list of IDs. */
  nodes: Array<Maybe<Node>>;
  /** Fetches a list of Poster events */
  posters: PosterConnection;
  /** Fetches a list of Quiz items */
  quizzes: QuizConnection;
  /** Fetches the viewer of the Graph */
  viewer: ViewerUnion;
};


/** MatchPick GraphQL API Queries */
export type QueryEntriesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  quiz?: Maybe<Scalars['ID']>;
};


/** MatchPick GraphQL API Queries */
export type QueryNodeArgs = {
  id: Scalars['ID'];
};


/** MatchPick GraphQL API Queries */
export type QueryNodesArgs = {
  ids: Array<Scalars['ID']>;
};


/** MatchPick GraphQL API Queries */
export type QueryPostersArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
  path?: Maybe<Scalars['String']>;
};


/** MatchPick GraphQL API Queries */
export type QueryQuizzesArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  domain?: Maybe<Scalars['String']>;
};

/** An object with an ID. */
export type Question = {
  created?: Maybe<Scalars['ISO8601DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  position?: Maybe<Scalars['Int']>;
  title: Scalars['String'];
  updated?: Maybe<Scalars['ISO8601DateTime']>;
  version: Scalars['Int'];
};

/** The connection type for Question. */
export type QuestionConnection = {
  __typename?: 'QuestionConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<QuestionEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Question>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type QuestionEdge = {
  __typename?: 'QuestionEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Question>;
};

/** Event question types */
export enum QuestionTypes {
  DualIntegersDualAnswer = 'DUAL_INTEGERS_DUAL_ANSWER',
  DualOptionSetsSingleAnswer = 'DUAL_OPTION_SETS_SINGLE_ANSWER',
  SingleOptionSetSingleAnswer = 'SINGLE_OPTION_SET_SINGLE_ANSWER'
}

/** An object with an ID. */
export type Quiz = {
  additionalSharing: Array<AdditionalShare>;
  created?: Maybe<Scalars['ISO8601DateTime']>;
  domain?: Maybe<Scalars['String']>;
  form: QuizForm;
  id: Scalars['ID'];
  name: Scalars['String'];
  questions: QuestionConnection;
  stylesheet: QuizStylesheet;
  template: QuizTemplate;
  updated?: Maybe<Scalars['ISO8601DateTime']>;
};


/** An object with an ID. */
export type QuizQuestionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** The connection type for Quiz. */
export type QuizConnection = {
  __typename?: 'QuizConnection';
  /** A list of edges. */
  edges?: Maybe<Array<Maybe<QuizEdge>>>;
  /** A list of nodes. */
  nodes?: Maybe<Array<Maybe<Quiz>>>;
  /** Information to aid in pagination. */
  pageInfo: PageInfo;
};

/** An edge in a connection. */
export type QuizEdge = {
  __typename?: 'QuizEdge';
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
  /** The item at the end of the edge. */
  node?: Maybe<Quiz>;
};

export type QuizForm = {
  __typename?: 'QuizForm';
  emailEnabled: Scalars['Boolean'];
  emailLabel?: Maybe<Scalars['String']>;
  emailPlaceholder?: Maybe<Scalars['String']>;
  firstNameEnabled: Scalars['Boolean'];
  firstNameLabel?: Maybe<Scalars['String']>;
  firstNamePlaceholder?: Maybe<Scalars['String']>;
  lastNameEnabled: Scalars['Boolean'];
  lastNameLabel?: Maybe<Scalars['String']>;
  lastNamePlaceholder?: Maybe<Scalars['String']>;
  mobileNumberEnabled: Scalars['Boolean'];
  mobileNumberLabel?: Maybe<Scalars['String']>;
  mobileNumberPlaceholder?: Maybe<Scalars['String']>;
  optInEnabled: Scalars['Boolean'];
  optInLabel?: Maybe<Scalars['String']>;
  termsAndConditionsEnabled: Scalars['Boolean'];
  termsAndConditionsLabel?: Maybe<Scalars['String']>;
  title: Scalars['String'];
};

export type QuizStylesheet = {
  __typename?: 'QuizStylesheet';
  backgroundImage?: Maybe<ImageWithVersions>;
  bodyBgColor?: Maybe<Scalars['String']>;
  bodyColor?: Maybe<Scalars['String']>;
  cardBgColor?: Maybe<Scalars['String']>;
  cardColor?: Maybe<Scalars['String']>;
  dangerColor?: Maybe<Scalars['String']>;
  darkColor?: Maybe<Scalars['String']>;
  facebookShareImage?: Maybe<Image>;
  /** @deprecated Use headerImages field */
  headerImage?: Maybe<Image>;
  headerImages: Array<HeaderImage>;
  infoColor?: Maybe<Scalars['String']>;
  lightColor?: Maybe<Scalars['String']>;
  primaryColor?: Maybe<Scalars['String']>;
  secondaryColor?: Maybe<Scalars['String']>;
  splashPageImage?: Maybe<ImageWithVersions>;
  successColor?: Maybe<Scalars['String']>;
};

export type QuizTemplate = {
  __typename?: 'QuizTemplate';
  /** @deprecated This field has moved to the QuizStylesheet type */
  backgroundImage?: Maybe<Image>;
  customCss?: Maybe<Scalars['String']>;
  customJavascript?: Maybe<Scalars['String']>;
  facebookShareButtonText?: Maybe<Scalars['String']>;
  facebookShareDescription?: Maybe<Scalars['String']>;
  facebookShareEnabled: Scalars['Boolean'];
  /** @deprecated This field has moved to the QuizStylesheet type */
  facebookShareImage?: Maybe<Image>;
  facebookShareTitle?: Maybe<Scalars['String']>;
  fbPixelId?: Maybe<Scalars['String']>;
  gaId?: Maybe<Scalars['String']>;
  /** @deprecated This field has moved to the QuizStylesheet type, and is now headerImages */
  headerImage?: Maybe<Image>;
  howToPlayEnabled: Scalars['Boolean'];
  howToPlayPages?: Maybe<Array<HowToPlayPage>>;
  pageDescription?: Maybe<Scalars['String']>;
  pageTitle?: Maybe<Scalars['String']>;
  resultsPageShareEnabled?: Maybe<Scalars['Boolean']>;
  resultsPageShareText?: Maybe<Scalars['String']>;
  resultsPageShareTitle?: Maybe<Scalars['String']>;
  resultsPageText?: Maybe<Scalars['String']>;
  resultsPageTitle?: Maybe<Scalars['String']>;
  splashPageEnabled: Scalars['Boolean'];
  /** @deprecated This field has moved to the QuizStylesheet type */
  splashPageImage?: Maybe<Image>;
  splashPageText?: Maybe<Scalars['String']>;
  splashPageTitle?: Maybe<Scalars['String']>;
  termsText: Scalars['String'];
};

export type ScratchAndWinEntry = Entry & Node & {
  __typename?: 'ScratchAndWinEntry';
  created?: Maybe<Scalars['ISO8601DateTime']>;
  id: Scalars['ID'];
  question: ScratchAndWinQuestion;
  quiz: ScratchAndWinQuiz;
  score: Scalars['Int'];
  updated?: Maybe<Scalars['ISO8601DateTime']>;
  winner: Scalars['Boolean'];
};

export type ScratchAndWinQuestion = Node & Question & {
  __typename?: 'ScratchAndWinQuestion';
  created?: Maybe<Scalars['ISO8601DateTime']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  loseImage?: Maybe<ImageWithVersions>;
  position?: Maybe<Scalars['Int']>;
  scratchImage?: Maybe<ImageWithVersions>;
  title: Scalars['String'];
  updated?: Maybe<Scalars['ISO8601DateTime']>;
  version: Scalars['Int'];
  winImage?: Maybe<ImageWithVersions>;
};

export type ScratchAndWinQuiz = Node & Quiz & {
  __typename?: 'ScratchAndWinQuiz';
  additionalSharing: Array<AdditionalShare>;
  created?: Maybe<Scalars['ISO8601DateTime']>;
  domain?: Maybe<Scalars['String']>;
  form: QuizForm;
  id: Scalars['ID'];
  name: Scalars['String'];
  questions: QuestionConnection;
  stylesheet: QuizStylesheet;
  template: QuizTemplate;
  updated?: Maybe<Scalars['ISO8601DateTime']>;
};


export type ScratchAndWinQuizQuestionsArgs = {
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
};

/** Autogenerated input type of Share */
export type ShareInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  via: Scalars['ID'];
};

/** Autogenerated return type of Share */
export type SharePayload = {
  __typename?: 'SharePayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of SingleOptionSetSingleAnswerMutation */
export type SingleOptionSetSingleAnswerMutationInput = {
  questionId: Scalars['ID'];
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  answer: OptionSetAnswerAttributes;
};

/** Autogenerated return type of SingleOptionSetSingleAnswerMutation */
export type SingleOptionSetSingleAnswerMutationPayload = {
  __typename?: 'SingleOptionSetSingleAnswerMutationPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors: Array<MutationError>;
  question: EventQuestion;
  success: Scalars['Boolean'];
};

export type Timestamps = {
  created?: Maybe<Scalars['ISO8601DateTime']>;
  updated?: Maybe<Scalars['ISO8601DateTime']>;
};

/** Autogenerated input type of UpdatePosterEntry */
export type UpdatePosterEntryInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  posterEntryId: Scalars['ID'];
  posterData: PosterDataInput;
};

/** Autogenerated return type of UpdatePosterEntry */
export type UpdatePosterEntryPayload = {
  __typename?: 'UpdatePosterEntryPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  entry?: Maybe<PosterEntry>;
  errors: Array<MutationError>;
  success: Scalars['Boolean'];
};

/** Autogenerated input type of UpdateUser */
export type UpdateUserInput = {
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  /** The ID of the event the user is using, i.e. Event ID, Poster ID, Quiz ID */
  via: Scalars['ID'];
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  mobileNumber?: Maybe<Scalars['String']>;
  /**
   * Terms and Conditions should be presented to and accepted by the current user.
   * 
   * If for some reason this isn't required pass false here.
   */
  acceptTerms: Scalars['Boolean'];
  optIn?: Maybe<Scalars['Boolean']>;
  /** Allows you to pass any additional form fields you need to record as JSON here. */
  formData?: Maybe<Array<Scalars['JSON']>>;
};

/** Autogenerated return type of UpdateUser */
export type UpdateUserPayload = {
  __typename?: 'UpdateUserPayload';
  /** A unique identifier for the client performing the mutation. */
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<MutationError>>;
  success: Scalars['Boolean'];
  user?: Maybe<User>;
};

export type User = Node & Timestamps & {
  __typename?: 'User';
  acceptTerms: Scalars['Boolean'];
  additionalFields: Array<Scalars['JSON']>;
  created?: Maybe<Scalars['ISO8601DateTime']>;
  email: Scalars['String'];
  entered: Scalars['Boolean'];
  firstName: Scalars['String'];
  id: Scalars['ID'];
  lastName: Scalars['String'];
  mobileNumber: Scalars['String'];
  optIn: Scalars['Boolean'];
  shared: Scalars['Boolean'];
  updated?: Maybe<Scalars['ISO8601DateTime']>;
};


export type UserEnteredArgs = {
  id: Scalars['ID'];
};


export type UserSharedArgs = {
  id: Scalars['ID'];
};

/** Viewer types */
export type ViewerUnion = Client | User;

export type CreateUserMutationMutationVariables = Exact<{
  input: CreateUserInput;
}>;


export type CreateUserMutationMutation = (
  { __typename?: 'Mutation' }
  & { createUser?: Maybe<(
    { __typename?: 'CreateUserPayload' }
    & Pick<CreateUserPayload, 'success'>
    & { userToken?: Maybe<(
      { __typename?: 'AccessToken' }
      & Pick<AccessToken, 'token' | 'expiresIn'>
    )> }
  )> }
);

export type GetPostersQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPostersQuery = (
  { __typename?: 'Query' }
  & { posters: (
    { __typename?: 'PosterConnection' }
    & { nodes?: Maybe<Array<Maybe<(
      { __typename?: 'Poster' }
      & Pick<Poster, 'id' | 'path' | 'backgroundRemoval'>
      & { template: (
        { __typename?: 'PosterTemplate' }
        & Pick<PosterTemplate, 'facebookShareTitle' | 'facebookShareDescription' | 'splashPageText' | 'splashPageEnabled' | 'pageTitle' | 'gaId'>
      ), backdrops: (
        { __typename?: 'PosterBackdropConnection' }
        & { nodes?: Maybe<Array<Maybe<(
          { __typename?: 'PosterBackdrop' }
          & Pick<PosterBackdrop, 'id'>
          & { image?: Maybe<(
            { __typename?: 'ImageWithVersions' }
            & Pick<ImageWithVersions, 'url'>
            & { versions: (
              { __typename?: 'ImageVersions' }
              & { large?: Maybe<(
                { __typename?: 'Image' }
                & Pick<Image, 'url'>
              )> }
            ) }
          )> }
        )>>> }
      ), stylesheet: (
        { __typename?: 'PosterStylesheet' }
        & Pick<PosterStylesheet, 'bodyBgColor' | 'bodyColor' | 'cardBgColor' | 'cardColor' | 'dangerColor' | 'infoColor' | 'lightColor' | 'primaryColor' | 'secondaryColor' | 'successColor'>
        & { headerImages: Array<(
          { __typename?: 'HeaderImage' }
          & Pick<HeaderImage, 'url' | 'role'>
        )>, splashPageImage?: Maybe<(
          { __typename?: 'ImageWithVersions' }
          & { versions: (
            { __typename?: 'ImageVersions' }
            & { large?: Maybe<(
              { __typename?: 'Image' }
              & Pick<Image, 'url'>
            )> }
          ) }
        )> }
      ) }
    )>>> }
  ) }
);

export type UpdateUserMutationMutationVariables = Exact<{
  input: UpdateUserInput;
}>;


export type UpdateUserMutationMutation = (
  { __typename?: 'Mutation' }
  & { updateUser?: Maybe<(
    { __typename?: 'UpdateUserPayload' }
    & Pick<UpdateUserPayload, 'success'>
    & { errors?: Maybe<Array<(
      { __typename?: 'MutationError' }
      & Pick<MutationError, 'attribute' | 'messages'>
    )>>, user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id'>
    )> }
  )> }
);

export type GetPosterQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetPosterQuery = (
  { __typename?: 'Query' }
  & { node?: Maybe<(
    { __typename?: 'AdditionalShare' }
    & Pick<AdditionalShare, 'id'>
  ) | (
    { __typename?: 'BasicQuiz' }
    & Pick<BasicQuiz, 'id'>
  ) | (
    { __typename?: 'BasicQuizEntry' }
    & Pick<BasicQuizEntry, 'id'>
  ) | (
    { __typename?: 'BasicQuizQuestion' }
    & Pick<BasicQuizQuestion, 'id'>
  ) | (
    { __typename?: 'Client' }
    & Pick<Client, 'id'>
  ) | (
    { __typename?: 'DisappearingImageEntry' }
    & Pick<DisappearingImageEntry, 'id'>
  ) | (
    { __typename?: 'DisappearingImageQuestion' }
    & Pick<DisappearingImageQuestion, 'id'>
  ) | (
    { __typename?: 'DisappearingImageQuiz' }
    & Pick<DisappearingImageQuiz, 'id'>
  ) | (
    { __typename?: 'Event' }
    & Pick<Event, 'id'>
  ) | (
    { __typename?: 'EventEntry' }
    & Pick<EventEntry, 'id'>
  ) | (
    { __typename?: 'EventQuestion' }
    & Pick<EventQuestion, 'id'>
  ) | (
    { __typename?: 'InteractiveQuiz' }
    & Pick<InteractiveQuiz, 'id'>
  ) | (
    { __typename?: 'InteractiveQuizEntry' }
    & Pick<InteractiveQuizEntry, 'id'>
  ) | (
    { __typename?: 'InteractiveQuizQuestion' }
    & Pick<InteractiveQuizQuestion, 'id'>
  ) | (
    { __typename?: 'ListGameEntry' }
    & Pick<ListGameEntry, 'id'>
  ) | (
    { __typename?: 'ListGameQuestion' }
    & Pick<ListGameQuestion, 'id'>
  ) | (
    { __typename?: 'ListGameQuiz' }
    & Pick<ListGameQuiz, 'id'>
  ) | (
    { __typename?: 'Option' }
    & Pick<Option, 'id'>
  ) | (
    { __typename?: 'OptionSet' }
    & Pick<OptionSet, 'id'>
  ) | (
    { __typename?: 'PersonalityQuiz' }
    & Pick<PersonalityQuiz, 'id'>
  ) | (
    { __typename?: 'PersonalityQuizEntry' }
    & Pick<PersonalityQuizEntry, 'id'>
  ) | (
    { __typename?: 'PersonalityQuizQuestion' }
    & Pick<PersonalityQuizQuestion, 'id'>
  ) | (
    { __typename?: 'Poster' }
    & Pick<Poster, 'name' | 'domain' | 'path' | 'commercial' | 'backgroundRemoval' | 'initialPosterPrice' | 'id'>
    & { additionalFields: Array<(
      { __typename?: 'PosterField' }
      & Pick<PosterField, 'title'>
      & { options: Array<(
        { __typename?: 'Option' }
        & Pick<Option, 'title'>
      )> }
    )>, backdrops: (
      { __typename?: 'PosterBackdropConnection' }
      & { nodes?: Maybe<Array<Maybe<(
        { __typename?: 'PosterBackdrop' }
        & Pick<PosterBackdrop, 'id'>
        & { image?: Maybe<(
          { __typename?: 'ImageWithVersions' }
          & Pick<ImageWithVersions, 'url'>
          & { versions: (
            { __typename?: 'ImageVersions' }
            & { large?: Maybe<(
              { __typename?: 'Image' }
              & Pick<Image, 'url'>
            )> }
          ) }
        )> }
      )>>> }
    ), form: (
      { __typename?: 'PosterForm' }
      & Pick<PosterForm, 'emailLabel' | 'emailEnabled' | 'emailPlaceholder' | 'firstNameLabel' | 'firstNameEnabled' | 'firstNamePlaceholder' | 'lastNameLabel' | 'lastNameEnabled' | 'lastNamePlaceholder' | 'mobileNumberLabel' | 'mobileNumberEnabled' | 'mobileNumberPlaceholder' | 'optInLabel' | 'optInEnabled' | 'termsAndConditionsLabel' | 'termsAndConditionsEnabled' | 'title'>
    ), stylesheet: (
      { __typename?: 'PosterStylesheet' }
      & Pick<PosterStylesheet, 'bodyBgColor' | 'bodyColor' | 'cardBgColor' | 'cardColor' | 'dangerColor' | 'infoColor' | 'lightColor' | 'primaryColor' | 'secondaryColor' | 'successColor'>
      & { backgroundImage?: Maybe<(
        { __typename?: 'ImageWithVersions' }
        & { versions: (
          { __typename?: 'ImageVersions' }
          & { large?: Maybe<(
            { __typename?: 'Image' }
            & Pick<Image, 'url'>
          )> }
        ) }
      )>, headerImages: Array<(
        { __typename?: 'HeaderImage' }
        & Pick<HeaderImage, 'url' | 'role'>
      )>, splashPageImage?: Maybe<(
        { __typename?: 'ImageWithVersions' }
        & Pick<ImageWithVersions, 'url'>
      )> }
    ), template: (
      { __typename?: 'PosterTemplate' }
      & Pick<PosterTemplate, 'facebookShareTitle' | 'facebookShareDescription' | 'personaliseText' | 'personaliseTitle' | 'finaliseText' | 'finaliseTitle' | 'formText' | 'formTitle' | 'pageTitle' | 'pageDescription' | 'splashPageText' | 'splashPageEnabled' | 'howToPlayEnabled' | 'termsText'>
      & { howToPlayPages?: Maybe<Array<(
        { __typename?: 'HowToPlayPage' }
        & Pick<HowToPlayPage, 'id' | 'position' | 'text' | 'title'>
        & { icon?: Maybe<(
          { __typename?: 'Image' }
          & Pick<Image, 'url'>
        )> }
      )>> }
    ) }
  ) | (
    { __typename?: 'PosterBackdrop' }
    & Pick<PosterBackdrop, 'id'>
  ) | (
    { __typename?: 'PosterEntry' }
    & Pick<PosterEntry, 'id'>
  ) | (
    { __typename?: 'PosterOrder' }
    & Pick<PosterOrder, 'id'>
  ) | (
    { __typename?: 'PosterOrderItem' }
    & Pick<PosterOrderItem, 'id'>
  ) | (
    { __typename?: 'ScratchAndWinEntry' }
    & Pick<ScratchAndWinEntry, 'id'>
  ) | (
    { __typename?: 'ScratchAndWinQuestion' }
    & Pick<ScratchAndWinQuestion, 'id'>
  ) | (
    { __typename?: 'ScratchAndWinQuiz' }
    & Pick<ScratchAndWinQuiz, 'id'>
  ) | (
    { __typename?: 'User' }
    & Pick<User, 'id'>
  )> }
);

export type CreatePosterEntryMutationMutationVariables = Exact<{
  input: CreatePosterEntryInput;
}>;


export type CreatePosterEntryMutationMutation = (
  { __typename?: 'Mutation' }
  & { createPosterEntry?: Maybe<(
    { __typename?: 'CreatePosterEntryPayload' }
    & Pick<CreatePosterEntryPayload, 'success'>
    & { errors: Array<(
      { __typename?: 'MutationError' }
      & Pick<MutationError, 'attribute' | 'messages'>
    )>, entry?: Maybe<(
      { __typename?: 'PosterEntry' }
      & Pick<PosterEntry, 'id'>
    )> }
  )> }
);

export type CreatePosterOrderMutationMutationVariables = Exact<{
  input: CreatePosterOrderInput;
}>;


export type CreatePosterOrderMutationMutation = (
  { __typename?: 'Mutation' }
  & { createPosterOrder?: Maybe<(
    { __typename?: 'CreatePosterOrderPayload' }
    & Pick<CreatePosterOrderPayload, 'success'>
    & { errors: Array<(
      { __typename?: 'MutationError' }
      & Pick<MutationError, 'attribute' | 'messages'>
    )>, intent?: Maybe<(
      { __typename?: 'PaymentIntent' }
      & Pick<PaymentIntent, 'amount' | 'clientSecret' | 'currency'>
    )> }
  )> }
);


export const CreateUserMutationDocument = gql`
    mutation CreateUserMutation($input: CreateUserInput!) {
  createUser(input: $input) {
    success
    userToken {
      token
      expiresIn
    }
  }
}
    `;
export type CreateUserMutationMutationFn = ApolloReactCommon.MutationFunction<CreateUserMutationMutation, CreateUserMutationMutationVariables>;

/**
 * __useCreateUserMutationMutation__
 *
 * To run a mutation, you first call `useCreateUserMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateUserMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createUserMutationMutation, { data, loading, error }] = useCreateUserMutationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateUserMutationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreateUserMutationMutation, CreateUserMutationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreateUserMutationMutation, CreateUserMutationMutationVariables>(CreateUserMutationDocument, baseOptions);
      }
export type CreateUserMutationMutationHookResult = ReturnType<typeof useCreateUserMutationMutation>;
export type CreateUserMutationMutationResult = ApolloReactCommon.MutationResult<CreateUserMutationMutation>;
export type CreateUserMutationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreateUserMutationMutation, CreateUserMutationMutationVariables>;
export const GetPostersDocument = gql`
    query getPosters {
  posters {
    nodes {
      id
      path
      backgroundRemoval
      template {
        facebookShareTitle
        facebookShareDescription
        splashPageText
        splashPageEnabled
        pageTitle
        gaId
      }
      backdrops {
        nodes {
          id
          image {
            url
            versions {
              large {
                url
              }
            }
          }
        }
      }
      stylesheet {
        bodyBgColor
        bodyColor
        cardBgColor
        cardColor
        dangerColor
        headerImages {
          url
          role
        }
        infoColor
        lightColor
        primaryColor
        secondaryColor
        splashPageImage {
          versions {
            large {
              url
            }
          }
        }
        successColor
      }
    }
  }
}
    `;

/**
 * __useGetPostersQuery__
 *
 * To run a query within a React component, call `useGetPostersQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPostersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPostersQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPostersQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPostersQuery, GetPostersQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPostersQuery, GetPostersQueryVariables>(GetPostersDocument, baseOptions);
      }
export function useGetPostersLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPostersQuery, GetPostersQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPostersQuery, GetPostersQueryVariables>(GetPostersDocument, baseOptions);
        }
export type GetPostersQueryHookResult = ReturnType<typeof useGetPostersQuery>;
export type GetPostersLazyQueryHookResult = ReturnType<typeof useGetPostersLazyQuery>;
export type GetPostersQueryResult = ApolloReactCommon.QueryResult<GetPostersQuery, GetPostersQueryVariables>;
export const UpdateUserMutationDocument = gql`
    mutation UpdateUserMutation($input: UpdateUserInput!) {
  updateUser(input: $input) {
    success
    errors {
      attribute
      messages
    }
    user {
      id
    }
  }
}
    `;
export type UpdateUserMutationMutationFn = ApolloReactCommon.MutationFunction<UpdateUserMutationMutation, UpdateUserMutationMutationVariables>;

/**
 * __useUpdateUserMutationMutation__
 *
 * To run a mutation, you first call `useUpdateUserMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateUserMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateUserMutationMutation, { data, loading, error }] = useUpdateUserMutationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateUserMutationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<UpdateUserMutationMutation, UpdateUserMutationMutationVariables>) {
        return ApolloReactHooks.useMutation<UpdateUserMutationMutation, UpdateUserMutationMutationVariables>(UpdateUserMutationDocument, baseOptions);
      }
export type UpdateUserMutationMutationHookResult = ReturnType<typeof useUpdateUserMutationMutation>;
export type UpdateUserMutationMutationResult = ApolloReactCommon.MutationResult<UpdateUserMutationMutation>;
export type UpdateUserMutationMutationOptions = ApolloReactCommon.BaseMutationOptions<UpdateUserMutationMutation, UpdateUserMutationMutationVariables>;
export const GetPosterDocument = gql`
    query getPoster($id: ID!) {
  node(id: $id) {
    id
    ... on Poster {
      name
      domain
      path
      commercial
      backgroundRemoval
      initialPosterPrice
      additionalFields {
        title
        options {
          title
        }
      }
      backdrops {
        nodes {
          id
          image {
            url
            versions {
              large {
                url
              }
            }
          }
        }
      }
      form {
        emailLabel
        emailEnabled
        emailPlaceholder
        firstNameLabel
        firstNameEnabled
        firstNamePlaceholder
        lastNameLabel
        lastNameEnabled
        lastNamePlaceholder
        mobileNumberLabel
        mobileNumberEnabled
        mobileNumberPlaceholder
        optInLabel
        optInEnabled
        termsAndConditionsLabel
        termsAndConditionsEnabled
        title
      }
      stylesheet {
        backgroundImage {
          versions {
            large {
              url
            }
          }
        }
        bodyBgColor
        bodyColor
        cardBgColor
        cardColor
        dangerColor
        headerImages {
          url
          role
        }
        infoColor
        lightColor
        primaryColor
        secondaryColor
        splashPageImage {
          url
        }
        successColor
      }
      template {
        facebookShareTitle
        facebookShareDescription
        personaliseText
        personaliseTitle
        personaliseText
        personaliseTitle
        finaliseText
        finaliseTitle
        formText
        formTitle
        pageTitle
        pageDescription
        splashPageText
        splashPageEnabled
        howToPlayEnabled
        termsText
        howToPlayPages {
          id
          position
          text
          title
          icon {
            url
          }
        }
      }
    }
  }
}
    `;

/**
 * __useGetPosterQuery__
 *
 * To run a query within a React component, call `useGetPosterQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPosterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPosterQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPosterQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<GetPosterQuery, GetPosterQueryVariables>) {
        return ApolloReactHooks.useQuery<GetPosterQuery, GetPosterQueryVariables>(GetPosterDocument, baseOptions);
      }
export function useGetPosterLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<GetPosterQuery, GetPosterQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<GetPosterQuery, GetPosterQueryVariables>(GetPosterDocument, baseOptions);
        }
export type GetPosterQueryHookResult = ReturnType<typeof useGetPosterQuery>;
export type GetPosterLazyQueryHookResult = ReturnType<typeof useGetPosterLazyQuery>;
export type GetPosterQueryResult = ApolloReactCommon.QueryResult<GetPosterQuery, GetPosterQueryVariables>;
export const CreatePosterEntryMutationDocument = gql`
    mutation CreatePosterEntryMutation($input: CreatePosterEntryInput!) {
  createPosterEntry(input: $input) {
    success
    errors {
      attribute
      messages
    }
    entry {
      id
    }
  }
}
    `;
export type CreatePosterEntryMutationMutationFn = ApolloReactCommon.MutationFunction<CreatePosterEntryMutationMutation, CreatePosterEntryMutationMutationVariables>;

/**
 * __useCreatePosterEntryMutationMutation__
 *
 * To run a mutation, you first call `useCreatePosterEntryMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePosterEntryMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPosterEntryMutationMutation, { data, loading, error }] = useCreatePosterEntryMutationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePosterEntryMutationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePosterEntryMutationMutation, CreatePosterEntryMutationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePosterEntryMutationMutation, CreatePosterEntryMutationMutationVariables>(CreatePosterEntryMutationDocument, baseOptions);
      }
export type CreatePosterEntryMutationMutationHookResult = ReturnType<typeof useCreatePosterEntryMutationMutation>;
export type CreatePosterEntryMutationMutationResult = ApolloReactCommon.MutationResult<CreatePosterEntryMutationMutation>;
export type CreatePosterEntryMutationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePosterEntryMutationMutation, CreatePosterEntryMutationMutationVariables>;
export const CreatePosterOrderMutationDocument = gql`
    mutation CreatePosterOrderMutation($input: CreatePosterOrderInput!) {
  createPosterOrder(input: $input) {
    success
    errors {
      attribute
      messages
    }
    intent {
      amount
      clientSecret
      currency
    }
  }
}
    `;
export type CreatePosterOrderMutationMutationFn = ApolloReactCommon.MutationFunction<CreatePosterOrderMutationMutation, CreatePosterOrderMutationMutationVariables>;

/**
 * __useCreatePosterOrderMutationMutation__
 *
 * To run a mutation, you first call `useCreatePosterOrderMutationMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePosterOrderMutationMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPosterOrderMutationMutation, { data, loading, error }] = useCreatePosterOrderMutationMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePosterOrderMutationMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<CreatePosterOrderMutationMutation, CreatePosterOrderMutationMutationVariables>) {
        return ApolloReactHooks.useMutation<CreatePosterOrderMutationMutation, CreatePosterOrderMutationMutationVariables>(CreatePosterOrderMutationDocument, baseOptions);
      }
export type CreatePosterOrderMutationMutationHookResult = ReturnType<typeof useCreatePosterOrderMutationMutation>;
export type CreatePosterOrderMutationMutationResult = ApolloReactCommon.MutationResult<CreatePosterOrderMutationMutation>;
export type CreatePosterOrderMutationMutationOptions = ApolloReactCommon.BaseMutationOptions<CreatePosterOrderMutationMutation, CreatePosterOrderMutationMutationVariables>;