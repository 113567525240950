import * as React from "react";
import { Nullable } from "../types";

interface Props {
  label: string;
  name: string;
  register: any;
  rows: number;
  maxLength?: number;
  placeholder?: string;
  error?: Nullable<any>;
  style?: React.CSSProperties;
}

const TextArea: React.SFC<Props> = ({
  label,
  name,
  rows,
  maxLength,
  register,
  placeholder,
  error,
  style,
}: Props) => {
  const inputRef = React.useRef<Nullable<HTMLTextAreaElement>>(null);
  const [lengthLeft, setLengthLeft] = React.useState(maxLength);
  return (
    <div
      style={style}
      tabIndex={-1}
      onClick={(): void => {
        if (inputRef.current) {
          inputRef.current.focus();
        }
      }}
      className="flex  relative bg-athensGray rounded-lg px-3 pt-3 sm:pt-4 pb-2 text-black border border-transparent focus-within:border-scienceBlue focus-within:bg-opacity-50 focus-within:text-opacity-50"
    >
      <div className="flex flex-col flex-1">
        <div className="flex self-stretch mb-2  justify-between">
          <label htmlFor={name} className="block text-label font-bold">
            {label}
          </label>
          {error && (
            <p className="flex-1 flex justify-end text-danger text-label">
              {error.message || "Required"}
            </p>
          )}
        </div>
        <textarea
          name={name}
          rows={rows}
          maxLength={maxLength}
          placeholder={placeholder}
          className="mt-1 bg-transparent text-sm md:text-base"
          ref={(e): void => {
            register(e);
            inputRef.current = e;
          }}
          onChange={(e) => {
            if (maxLength) {
              setLengthLeft(maxLength - e.target.value.length);
            }
          }}
        />
        {maxLength && (
          <span className="flex block text-label font-bold ml-2 justify-end">
            {lengthLeft}
          </span>
        )}
      </div>
    </div>
  );
};

export default TextArea;
