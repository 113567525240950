import * as React from "react";
import classNames from "classnames";
import LoadingSpinner from "./LoadingSpinner";

interface Props extends React.HTMLAttributes<HTMLButtonElement> {
  onClick?: () => void;
  type?: "button" | "submit" | "reset";
  theme: "primary" | "secondary";
  className?: string;
  isLoading?: boolean;
  icon?: JSX.Element;
  disabled?: boolean;
}

const getThemeColor = (theme): string => {
  if (theme === "primary") {
    return "#191919";
  }

  return "white";
};

const Button: React.FC<Props> = ({
  children,
  onClick,
  type,
  className,
  theme,
  isLoading = false,
  icon,
  disabled = false,
  ...rest
}: Props) => (
  <button
    {...rest}
    type={type}
    onClick={onClick}
    disabled={disabled}
    className={classNames(
      "w-full font-bold px-4 xs:px-4 rounded-button shadow-primaryButton relative h-14 text-sm md:text-lg whitespace-no-wrap",
      className,
      {
        "bg-white text-black": theme === "primary",
        "bg-primary text-white": theme === "secondary",
      }
    )}
    style={{
      color: isLoading ? "transparent" : getThemeColor(theme),
    }}
  >
    <div className="flex justify-center items-center">
      {children}
      {icon && <div className="ml-1">{icon}</div>}
    </div>

    {isLoading && (
      <div className="absolute flex items-center justify-center top-0 right-0 left-0 bottom-0">
        <LoadingSpinner height="40px" width="40px" strokeWidth={10} />
      </div>
    )}
  </button>
);

export default Button;
