import React from "react";
import { Nullable } from "./types";

// Bit of a hack. I can't seem to programmatically click the camera input from within the hook in Safari
export const captureImage = (): void => {
  const input: Nullable<HTMLInputElement> = document.querySelector(
    "#camera-capture"
  );
  if (input) {
    input.click();
  }
};

export const useCamera = (onImage: (imageFile: File) => void): JSX.Element => {
  const inputRef = React.useRef<Nullable<HTMLInputElement>>(null);

  const cameraSetup = (
    <input
      ref={inputRef}
      id="camera-capture"
      className="invisible absolute"
      accept="image/*;capture=camera"
      type="file"
      onChange={(e) => {
        if (e?.target?.files) {
          const file = e.target.files[0];
          onImage(file);
        }
      }}
    />
  );

  return cameraSetup;
};
