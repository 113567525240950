import * as React from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";
import { AnimatePresence, motion } from "framer-motion";
import Button from "../shared/Button";
import Hero from "../shared/Hero";
import { useGetAppStyle, useInitialPoster } from "../poster/posterHooks";

interface Props {
  children: JSX.Element[];
  className?: string;
}

export const AppLayout: React.FC<Props> = ({ children, className }: Props) => {
  return (
    <div
      className={classNames(
        "flex flex-col flex-1 overflow-hidden relative",
        className
      )}
    >
      {children[0]}
      <div className="flex flex-1 mt-6 flex-col items-center mx-8 overflow-hidden">
        {children[1]}
      </div>
      <div className="flex flex-col justify-end pb-5">{children[2]}</div>
    </div>
  );
};

const Landing: React.FC = () => {
  const posterStyle = useGetAppStyle();
  const { poster } = useInitialPoster();
  const history = useHistory();
  const [showPoster, setShowPoster] = React.useState(false);

  const goToInstructions = (): void => {
    if (
      poster.path.toLowerCase() === "chooseyourbackground" ||
      poster.path.toLowerCase() === "superfan"
    ) {
      history.replace("/posters");
    } else if (poster.path.toLowerCase() === "master") {
      history.replace("/teams");
    } else {
      history.replace("/instructions");
    }
  };

  React.useEffect(() => {
    setShowPoster(true);
    localStorage.removeItem("checkoutPreviewPosterUrl");
  }, []);

  return (
    <AppLayout className="bg-bgBody">
      <Hero role="DEFAULT" />

      <AnimatePresence>
        {showPoster && (
          <motion.img
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            className="h-auto w-full max-h-full object-contain"
            alt="Splash poster preview"
            src={posterStyle?.splashPageImage?.versions.large?.url}
          />
        )}
      </AnimatePresence>

      <div className="mx-8">
        <div className="flex items-center justify-center py-6">
          <h2
            className={`text-lg sm:text-h2 text-center text-white leading-tight font-bold ${
              poster?.path === "oppo" ? "font-title-oppo" : "font-title"
            }`}
          >
            {poster?.template.splashPageText}
          </h2>
        </div>
        <Button theme="primary" onClick={goToInstructions}>
          Get Started
        </Button>
      </div>
    </AppLayout>
  );
};

export default Landing;
