import * as React from "react";
import { useGetAppStyle } from "../poster/posterHooks";

interface Props {
  role?:
    | "DEFAULT"
    | "HOW_TO_PLAY_PAGE"
    | "SPLASH_PAGE"
    | "QUESTION_PAGE"
    | "CHECKOUT_PAGE"
    | "PERSONALISE_PAGE"
    | "SHARE_PAGE"
    | "FINALISE_PAGE"
    | "FORM_PAGE";
  defaultHeader?: JSX.Element;
}

const defaultHeroHeader = (
  <div className="flex flex-col items-center justify-center py-11">
    <h2 className="text-h3 sm:text-h2 text-white">Welcome to</h2>
    <h1 className="text-h2 sm:text-h1 text-white font-bold">Poster.ly</h1>
  </div>
);

const Hero: React.FC<Props> = ({
  role,
  defaultHeader = defaultHeroHeader,
}: Props) => {
  const posterStyle = useGetAppStyle();

  const headerImageForRole = posterStyle?.headerImages.find(
    (header) => header.role === role
  );

  if (!headerImageForRole) {
    return defaultHeader;
  }

  return (
    <img alt="Header" className="h-20 w-auto" src={headerImageForRole.url} />
  );
};

export default Hero;
