import * as React from "react";
import classNames from "classnames";

import Button from "../shared/Button";
import Hero from "../shared/Hero";
import { AppLayout } from "../splash/Splash";
import stadium from "./2.png";
import returning from "./3.png";
import mtMaunganui from "./1.png";

import bluesImage from "./blues.png";
import chiefsImage from "./chiefs.png";
import hurricanesImage from "./hurricanes.png";
import crusadersImage from "./crusaders.png";
import highlandersImage from "./highlanders.png";

import { basepath } from "../poster/posterHooks";

const PosterSelection: React.FC = () => {
  const [selectedTeam, setSelectedTeam] = React.useState("");
  const posterRoutes = {
    chooseyourbackground: {
      mtMaunganui: "MtMaunganui2024",
      stadium: "stadium2024",
      returning: "returningathlete2024",
    },
    superfan: {
      blues: "bluesSuperFan",
      chiefs: "chiefsSuperFan",
      hurricanes: "hurricanesSuperFan",
      crusaders: "crusadersSuperFan",
      highlanders: "highlandersSuperFan",
    },
  };

  const goToTeamPoster = (): void => {
    //console.log(`Going to: ${selectedTeam}`);
    const redirect = posterRoutes[basepath][selectedTeam];
    const host = window.location.origin;
    window.location.href = `${host}/${redirect}`;
  };

  const selectTeam = (team: string): void => {
    setSelectedTeam(team);
  };

  React.useEffect(() => {
    localStorage.removeItem("checkoutPreviewPosterUrl");
  }, []);

  const posterSelection = {
    chooseyourbackground: [
      {
        display: "Mt Maunganui",
        name: "mtMaunganui",
        image: mtMaunganui,
      },
      {
        display: "Stadium",
        name: "stadium",
        image: stadium,
      },
      {
        display: "Returning Athlete",
        name: "returning",
        image: returning,
      },
    ],
    superfan: [
      {
        display: "Blues",
        name: "blues",
        image: bluesImage,
      },
      {
        display: "Chiefs",
        name: "chiefs",
        image: chiefsImage,
      },
      {
        display: "Hurricanes",
        name: "hurricanes",
        image: hurricanesImage,
      },
      {
        display: "Crusaders",
        name: "crusaders",
        image: crusadersImage,
      },
      {
        display: "Highlanders",
        name: "highlanders",
        image: highlandersImage,
      },
    ],
  };

  return (
    <AppLayout className="bg-bgBody">
      <Hero role="HOW_TO_PLAY_PAGE" />
      <div className="pb-3 my-3 overflow-y-auto">
        <div className="grid grid-cols-2 gap-4 justify-items-center">
          {posterSelection[basepath].map((poster) => {
            return (
              <div
                key={poster.name}
                className={classNames(
                  "flex w-fit h-fit border-4 rounded-lg cursor-pointer",
                  {
                    "border-white": selectedTeam === poster.name,
                    "border-transparent": selectedTeam !== poster.name,
                  }
                )}
              >
                <img
                  alt={poster.display}
                  src={poster.image}
                  className="w-36 h-36"
                  onClick={(): void => {
                    selectTeam(poster.name);
                  }}
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className="mx-8">
        <Button
          theme="primary"
          disabled={selectedTeam === ""}
          className={
            selectedTeam === "" ? "cursor-not-allowed bg-opacity-25" : ""
          }
          onClick={goToTeamPoster}
        >
          Next
        </Button>
      </div>
    </AppLayout>
  );
};

export default PosterSelection;
