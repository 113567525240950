import * as React from "react";
import classNames from "classnames";
import Slider from "react-slick";
import { useHistory } from "react-router-dom";

import DefaultInstructionCard from "./DefaultInstructionCard";
import InstructionCard from "./InstructionCard";
import {
  useGetCurrentPoster,
  useGetCurrentPosterOnboarding,
  useSkipDetailsEnabled,
} from "../poster/posterHooks";

import Button from "../shared/Button";
import { Nullable } from "../types";
import { useCaptureFan, useCreationDispatch } from "../creationFlowHooks";
import { captureImage } from "../useCamera";
import { setCustomerDetails } from "../creationFlowActions";

const Uploader: React.SFC = () => {
  const { poster } = useGetCurrentPoster();
  const howToPages = useGetCurrentPosterOnboarding();
  const sliderRef = React.useRef<Nullable<Slider>>(null);
  const { processed, setupCamera } = useCaptureFan();
  const dispatch = useCreationDispatch();
  const skipDetails = useSkipDetailsEnabled();
  const [slideState, setSlideState] = React.useState({
    activeSlide: 0,
    oldSlide: null,
  });

  const history = useHistory();

  React.useEffect(() => {
    if (processed) {
      if (skipDetails) {
        const customerDetails = {
          firstName: "Oppo",
          lastName: "Reno",
        };
        dispatch(setCustomerDetails(customerDetails));
        history.replace("/loading");
      } else {
        history.replace("/details");
      }
    }
  }, [processed, history]);

  const settings = {
    dots: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false,
    centerPadding: "30",
    infinite: false,
    beforeChange: (current, next): void =>
      setSlideState({ oldSlide: current, activeSlide: next }),
  };

  const onLastSlide =
    howToPages === undefined || slideState.activeSlide === howToPages?.length;
  return (
    <div className="h-full instructions pt-6 bg-whisper">
      {poster && (
        <>
          <div style={{ height: "80%" }}>
            <Slider {...settings} ref={sliderRef}>
              <DefaultInstructionCard />
              {howToPages?.map((page) => {
                return (
                  <InstructionCard
                    key={page.title}
                    title={page.title}
                    body={page.text}
                    icon={page.icon}
                  />
                );
              })}
            </Slider>
          </div>

          <div
            className="flex flex-col justify-end px-6 "
            style={{ height: "20%" }}
          >
            <div className="flex flex-1 justify-center items-center">
              <div
                className={classNames("rounded-full h-2 w-2 mr-3", {
                  "bg-primary": slideState.activeSlide === 0,
                  "bg-secondary": slideState.activeSlide !== 0,
                })}
              />
              {howToPages?.map((page, index) => {
                return (
                  <div
                    key={index.toString()}
                    className={classNames("rounded-full h-2 w-2 mr-3", {
                      "bg-primary": index + 1 === slideState.activeSlide,
                      "bg-secondary": index + 1 !== slideState.activeSlide,
                    })}
                  />
                );
              })}
            </div>
            {setupCamera}

            <Button
              theme="secondary"
              onClick={(): void => {
                if (onLastSlide) {
                  captureImage();
                } else {
                  sliderRef.current?.slickNext();
                }
              }}
              className="mb-4"
            >
              {onLastSlide ? "Take or upload photo" : "Next"}
            </Button>
          </div>
        </>
      )}
    </div>
  );
};

export default Uploader;
