import * as React from "react";

interface Props {
  children: JSX.Element | JSX.Element[];
}
const MobileContainer = ({ children }: Props) => {
  return (
    <main className="w-full antialiased font-body mobile-screen overflow-auto bg-bgBody overflow-x-hidden">
      <div className="flex items-center justify-center h-full">
        <div className="bg-white flex items-center justify-center w-full max-w-iphone h-full max-h-lg overflow-hidden">
          {children}
        </div>
      </div>
    </main>
  );
};
export default MobileContainer;
