import * as React from "react";

interface Props {
  children: JSX.Element;
}

const Card: React.FC<Props> = ({ children }: Props) => {
  return (
    <div className="h-full flex rounded-card p-4 bg-bgCard text-card">
      {children}
    </div>
  );
};

export default Card;
