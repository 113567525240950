import React from "react";
import { BrowserRouter as Router, Redirect } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Helmet } from "react-helmet";

import Instructions from "./instructions/Instructions";
import Splash from "./splash/Splash";
import Loading from "./Loading";
import Details from "./details/Details";
import PosterPreview from "./poster/PosterPreview";
import { RouteTransition } from "./RouteTransition";
import { AnimatedRoutes } from "./AnimatedRoutes";
import Share from "./sharing/Share";
import CustomerDetails from "./customerDetails/CustomerDetails";
import {
  basepath,
  useSpashEnabled,
  useInitialPoster,
} from "./poster/posterHooks";

import { isDev } from "./enviromentHelper";

import Analytics from "react-router-ga";
import { useFullPosterBootstrap } from "./auth/authHooks";
import { Nullable } from "./types";
import PosterExport from "./poster/PosterExport";
import CheckoutSuccess from "./checkout/checkoutSuccess";
import PosterSelection from "./posterSelection/posterSelection";
import DetailsExtra from "./details/DetailsExtra";
import TeamSelection from "./teams/teamSelection";
import SkillsBlackFerns from "./teams/skillsBlackFerns";
import SkillsAllBlacks from "./teams/skillsAllBlacks";

const AnalyticsWrapper: React.FC<{
  gaId?: Nullable<string>;
}> = ({ gaId, children }) => {
  if (gaId) {
    return (
      <Analytics id={gaId} debug={isDev}>
        {children}
      </Analytics>
    );
  }

  return <>{children}</>;
};

const topLevelPages = [`/${basepath}/export`, `/${basepath}/checkout/success`];

const CreationFlow: React.FC = () => {
  const splashPageEnabled = useSpashEnabled();
  const { poster } = useInitialPoster();
  useFullPosterBootstrap();

  return (
    <main
      className={`w-full antialiased mobile-screen overflow-auto bg-bgBody overflow-x-hidden ${
        poster?.path === "oppo" ? "font-body-oppo" : "font-body"
      }`}
    >
      <div className="flex items-center justify-center h-full">
        <div className="flex w-full max-w-iphone h-full max-h-lg overflow-hidden">
          <Helmet>
            <meta property="og:url" content={window.location.href} />
            <meta
              property="og:title"
              content={`${poster?.template.facebookShareTitle}`}
            />
            <meta
              property="og:description"
              content={`${poster?.template.facebookShareDescription}`}
            />
            <meta
              property="og:image"
              content={`${poster?.stylesheet.facebookShareImage?.url}`}
            />
            <meta property="og:type" content="website" />
            <title>{poster?.template.pageTitle}</title>
          </Helmet>
          <ToastContainer />
          <Router basename={`/${basepath}`}>
            {!isDev && !topLevelPages.includes(window.location.pathname) && (
              <Redirect to="/" />
            )}

            <AnalyticsWrapper gaId={poster.template.gaId}>
              <AnimatedRoutes exitBeforeEnter initial={false}>
                <RouteTransition exact path="/">
                  {splashPageEnabled ? <Splash /> : <Instructions />}
                </RouteTransition>
                <RouteTransition exact path="/preview">
                  <PosterPreview />
                </RouteTransition>
                <RouteTransition exact path="/loading">
                  <Loading />
                </RouteTransition>
                <RouteTransition exact path="/instructions">
                  <Instructions />
                </RouteTransition>
                <RouteTransition exact path="/details">
                  <Details />
                </RouteTransition>
                <RouteTransition exact path="/details-extra">
                  <DetailsExtra />
                </RouteTransition>
                <RouteTransition exact path="/share">
                  <Share />
                </RouteTransition>
                <RouteTransition exact path="/customer-details">
                  <CustomerDetails />
                </RouteTransition>
                <RouteTransition exact path="/export">
                  <PosterExport />
                </RouteTransition>
                <RouteTransition exact path="/checkout/success">
                  <CheckoutSuccess />
                </RouteTransition>
                <RouteTransition exact path="/posters">
                  <PosterSelection />
                </RouteTransition>
                <RouteTransition exact path="/teams">
                  <TeamSelection />
                </RouteTransition>
                <RouteTransition exact path="/skills-ab">
                  <SkillsAllBlacks />
                </RouteTransition>
                <RouteTransition exact path="/skills-bf">
                  <SkillsBlackFerns />
                </RouteTransition>
              </AnimatedRoutes>
            </AnalyticsWrapper>
          </Router>
        </div>
      </div>
    </main>
  );
};

export default CreationFlow;
