import * as React from "react";
import { useQuery } from "@apollo/react-hooks";
import imageCompression from "browser-image-compression";
import { Maybe } from "graphql/jsutils/Maybe";
import axios from "axios";
import { useApolloClient } from "@apollo/react-hooks";
import * as Sentry from "@sentry/react";
import camelCase from "lodash/camelCase";
import { toast } from "react-toastify";

import { UPDATE_USER } from "../auth/authQueries";
import { PosterApi } from "../api";

import {
  Poster,
  PosterStylesheet,
  PosterBackdrop,
  PosterBackgroundRemoval,
} from "../generated/graphql";

import { Nullable, FanParams } from "../types";
import { postersMap } from "../auth/authHooks";
import { useCreationState, useCreationDispatch } from "../creationFlowHooks";
import { setFanParams } from "../creationFlowActions";
import { isDev } from "../enviromentHelper";

import { loadImage, imageElementToFile } from "../utils";
import { getToken } from "../tokenManager";
import { CurrentPosterHook, CurrentPosterInput } from "./types";
import { GET_CURRENT_POSTER, CREATE_POSTER_ENTRY } from "./posterQueries";
import { omit } from "lodash";
import { posterSuccessActions } from "./posterHelpers";

//Allow for team poster selection pages
const baseOverride = process.env.REACT_APP_BASEPATH_OVERRIDE || "";
const path = window.location.pathname.split("/")[1];
const parseBasePathOverride = (overrides) => {
  if (overrides === "") {
    return "";
  }
  const hostname = window.location.hostname.startsWith("www")
    ? window.location.hostname.substring(4)
    : window.location.hostname;
  if (overrides.includes(hostname)) {
    const individualOverrides = overrides.split("|");
    for (const override of individualOverrides) {
      const parts = override.split("::");
      if (hostname === parts[0]) {
        return parts[1];
      }
    }
  }
  return "";
};
const processedOverride = parseBasePathOverride(baseOverride);
export const basepath =
  path === "" && processedOverride !== "" ? processedOverride : path;

export const useInitialPoster = (): {
  poster: Poster;
} => {
  return { poster: postersMap[basepath] };
};

export const useGetCurrentPoster = (): CurrentPosterHook => {
  const { loading, data, error } = useQuery<any, CurrentPosterInput>(
    GET_CURRENT_POSTER,
    {
      variables: { id: postersMap[basepath].id },
    }
  );

  let poster;

  if (data) {
    poster = data.node;
  }

  return { loading, poster, error };
};

export const useDefaultOptin = (poster: Maybe<Poster>): boolean => {
  if (poster) {
    const pathLower = poster.path.toLowerCase();
    if (
      pathLower === "tiptopbakery" ||
      pathLower === "oppo" ||
      pathLower === "mtmaunganui2024" ||
      pathLower === "stadium2024" ||
      pathLower === "returningathlete2024"
    ) {
      return true;
    }
  }
  return false;
};

export const useSkipDetailsEnabled = (): boolean => {
  const { poster } = useInitialPoster();

  if (poster) {
    return poster.path === "oppo";
  }
  return false;
};

export const useSpashEnabled = (): boolean => {
  const { poster } = useInitialPoster();

  if (poster) {
    return poster.template.splashPageEnabled;
  }
  return false;
};

export const useLocalDownloadEnabled = (): boolean => {
  const { poster } = useInitialPoster();
  const downloadablePosters = [
    "weetbixstatattackblackferns",
    "weetbixstatattack2023",
    "woolworthsblackferns",
    "woolworths",
    "blueslive",
    "bluessuperfan",
    "chiefssuperfan",
    "hurricanessuperfan",
    "crusaderssuperfan",
    "highlanderssuperfan",
    "tiptopbakery",
    "statattack-allblacks-dazzlingdynamo-sidestep",
    "statattack-allblacks-superspeedster-intercept",
    "statattack-allblacks-superspeedster-chipandchase",
    "statattack-allblacks-persistentpowerhouse-steal",
    "statattack-allblacks-persistentpowerhouse-offload",
    "statattack-allblacks-daringdefender-fend",
    "statattack-allblacks-daringdefender-BallAndAllTackle",
    "statattack-allblacks-dazzlingdynamo-chargedown",
    "statattack-blackferns-dazzlingdynamo-sidestep",
    "statattack-blackferns-superspeedster-intercept",
    "statattack-blackferns-superspeedster-chipandchase",
    "statattack-blackferns-persistentpowerhouse-steal",
    "statattack-blackferns-persistentpowerhouse-offload",
    "statattack-blackferns-daringdefender-fend",
    "statattack-blackferns-daringdefender-BallAndAllTackle",
    "statattack-blackferns-dazzlingdynamo-chargedown",
  ];
  if (poster) {
    //Update with poster flag once added.
    return downloadablePosters.includes(poster.path.toLowerCase());
  }
  return false;
};

export const useBackgroundRemoval = (): PosterBackgroundRemoval => {
  const { poster } = useInitialPoster();
  if (poster && poster.backgroundRemoval) {
    return poster.backgroundRemoval;
  }
  return PosterBackgroundRemoval.RemoveBg;
};

export const useHowToPlayEnabled = (): boolean => {
  const { poster } = useGetCurrentPoster();

  if (poster) {
    return poster.template.howToPlayEnabled;
  }
  return false;
};

export const useGetCurrentPosterAdditionalFields = () => {
  const { poster } = useGetCurrentPoster();

  if (poster) {
    return poster.additionalFields.map((field) => ({
      name: field.title,
      options: field.options.map((option) => option.title),
    }));
  }
};

export const useGetCurrentPosterOnboarding = () => {
  const { poster } = useGetCurrentPoster();
  if (
    poster &&
    poster.template.howToPlayEnabled &&
    poster.template.howToPlayPages
  ) {
    return poster.template.howToPlayPages.sort((a, b) => {
      return a.position - b.position;
    });
  }
};

export const useGetAppStyle = (): Maybe<PosterStylesheet> => {
  const { poster } = useInitialPoster();
  if (poster) {
    return poster.stylesheet;
  }
};

export const useUploadImage = () => {
  return React.useCallback(async (image: any) => {
    const formData = new FormData();
    if (typeof image === "string" && image.includes("blob:")) {
      const imageBlob = await fetch(image).then((r) => r.blob());
      formData.append("file", imageBlob);
    } else {
      formData.append("file", image);
    }
    formData.append("upload_preset", "zslfq85a");
    formData.append("timestamp", (Date.now() / 1000).toString());

    const response = await axios.post(
      "https://api.cloudinary.com/v1_1/smile-dealers-new/image/upload",
      formData,
      {
        onUploadProgress: (progressEvent) => console.log(progressEvent.loaded),
      }
    );

    return response.data.secure_url;
  }, []);
};

export const usePrepareCheckout = () => {
  const { previewPoster } = useCreationState();
  const { poster } = useGetCurrentPoster();
  const uploadImage = useUploadImage();

  React.useEffect(() => {
    const prepareForCheckout = async () => {
      if (
        !previewPoster ||
        localStorage.getItem("checkoutPreviewPosterUrl") ||
        !poster?.commercial
      ) {
        return;
      }

      const options = {
        maxSizeMB: 0.05,
      };

      const posterFile = await imageElementToFile(
        previewPoster.src,
        "poster.png",
        "image/jpg"
      );

      if (posterFile.data) {
        const compressedPosterPreview = await imageCompression(
          posterFile.data,
          options
        );
        const uploadedImageUrl = await uploadImage(compressedPosterPreview);
        localStorage.setItem("checkoutPreviewPosterUrl", uploadedImageUrl);
      }
    };
    prepareForCheckout();
  }, [poster]);

  return async (customerEmail: string) => {
    if (!poster) return;
    const checkoutSessionResponse = await PosterApi.createCheckoutSession({
      posterId: poster.id,
      posterPreviewUrl: localStorage.getItem("checkoutPreviewPosterUrl"),
      posterPrice: poster.initialPosterPrice,
      customerEmail,
      posterBaseUrl: basepath,
    });
    if (checkoutSessionResponse && checkoutSessionResponse.data.checkoutUrl) {
      window.location.replace(checkoutSessionResponse.data.checkoutUrl);
    }
  };
};

export const useCreatePosterEntry = () => {
  const createPosterJob = useCreatePosterGenerationJob();
  const client = useApolloClient();
  const { poster } = useGetCurrentPoster();
  const { customerDetails, fullResFanUrl } = useCreationState();

  const createEntry = async () => {
    try {
      const response = await client.mutate({
        mutation: CREATE_POSTER_ENTRY,
        variables: {
          input: {
            posterId: poster?.id,
            backdropId: poster?.backdrops.nodes?.[0]?.id,
            posterData: {
              firstName: customerDetails?.firstName,
              lastName: customerDetails?.lastName,
              additionalData: [],
              layers: [
                {
                  name: "fan",
                  zIndex: 10,
                  url: fullResFanUrl,
                },
              ],
            },
            clientMutationId: "123",
          },
        },
      });

      if (response.data.createPosterEntry.entry.id && poster?.path) {
        await createPosterJob(
          response.data.createPosterEntry.entry.id,
          poster.path
        );
      }
    } catch (e) {
      Sentry.captureException(e);
    }
  };

  return createEntry;
};

export const useSubmitPoster = (): {
  loading: boolean;
  submitted: boolean;
  submitPoster: (
    email: string,
    posterDetails: {
      firstName: string;
      lastName: string;
    },
    optIn: boolean,
    userFormData: {} | undefined
  ) => void;
} => {
  const client = useApolloClient();
  const checkout = usePrepareCheckout();

  const [submitted, setSubmitted] = React.useState(false);
  const [loading, setLoading] = React.useState(false);

  const { poster } = useGetCurrentPoster();
  const submitPoster = async (
    email: string,
    posterDetails,
    optIn,
    userFormData
  ): Promise<void> => {
    setLoading(true);
    if (email) {
      email = email.trim();
    }
    try {
      const setEmailResponse = await client.mutate({
        mutation: UPDATE_USER,
        variables: {
          input: {
            via: poster?.id,
            email: email,
            acceptTerms: true,
            optIn: optIn,
            firstName: posterDetails.firstName,
            lastName: posterDetails.lastName,
            formData: userFormData, //Used to send dynamic information to set on the user
          },
        },
      });

      if (!setEmailResponse.data.updateUser.success) {
        throw new Error("Failed to set user email");
      }

      if (poster?.commercial) {
        await checkout(email);
      } else {
        if (posterSuccessActions[basepath] === undefined) {
          toast.success(
            "Your Poster has been sent! Check your email to view and share it"
          );
          setTimeout(() => {
            window.location.href = `${window.location.origin}/${basepath}`;
          }, 2500);
        } else {
          const actions = posterSuccessActions[basepath];
          toast.success(actions.message);

          setTimeout(() => {
            window.location.href = `${window.location.origin}/${actions.redirect}`;
          }, 2500);
        }
      }
    } catch (e) {
      setSubmitted(false);
      Sentry.captureException(e, {
        extra: {
          email,
        },
      });
      toast.error("Failed to submit poster, please try again");
    } finally {
      setLoading(false);
    }
  };

  return { loading, submitted, submitPoster };
};

const devForm = {
  firstName: "Jie lang",
  lastName: "longerstaterfdgdg",
  [camelCase("Your Tryathlon location ")]: "AUCKLAND EAST",
  howOldAreYou: "12",
  "howManyWeetbix™HaveYouEaten": "16",
  [camelCase("What was your Tryathlon time? ")]: "0:45:23",
};

const defaultForm = {
  firstName: "",
  lastName: "",
  position: "",
  age: "",
  playersNumber: "",
  team: "",
  favouriteSkill: "",
  favouritePlayerNumber: "",
};

const loadScript = (scriptSrc: string): Promise<void> => {
  return new Promise((resolve) => {
    const existingScript = document.querySelector(`script[src="${scriptSrc}"]`);

    if (existingScript) {
      resolve();
    }

    const script = document.createElement("script");
    script.src = scriptSrc;
    script.onload = () => {
      resolve();
    };
    document.body.appendChild(script);
  });
};

const loadLink = (linkSrc: string): Promise<void> => {
  return new Promise((resolve) => {
    const link = document.createElement("link");
    link.rel = "stylesheet";
    link.href = linkSrc;
    link.onload = () => {
      resolve();
    };
    document.body.appendChild(link);
  });
};

export const loadZoomScripts = () => {
  return Promise.all([
    loadScript("/hammer-konva.js"),
    loadScript("/touch-emulator.js"),
    loadLink("https://use.typekit.net/awn2mir.css"),
  ]);
};

export const usePosterGenerationParams = () => {
  const {
    customerDetails,
    uploadedFanUrl,
    fanParams,
    exportLayers,
  } = useCreationState();
  const { poster } = useGetCurrentPoster();

  if (poster && poster.backdrops.nodes) {
    const backdrops = (poster.backdrops.nodes as PosterBackdrop[])
      .filter((backdrops) => backdrops?.image)
      .map((backdrop) => backdrop?.image?.url);

    const { firstName, lastName } =
      customerDetails || (isDev ? devForm : defaultForm);

    const additionalFields = poster.additionalFields;

    if (poster?.path.endsWith("SuperFan")) {
      //Hack, magic field not present in the cms
      additionalFields.push({
        title: "additionalReason",
        optionSetId: "",
        options: [],
      });
    }

    return {
      customer: {
        firstName,
        lastName,
        ...(additionalFields.reduce(
          (accum, field) => ({
            [camelCase(field.title)]:
              customerDetails?.[camelCase(field.title)] || "",
            ...accum,
          }),
          {}
        ) || {}),
      },
      backdrops,

      fan: {
        imageUrl: uploadedFanUrl,
        ...fanParams,
      },
      exportLayers,
    };
  }
};

export const useCreatePosterGenerationJob = () => {
  const params = usePosterGenerationParams();
  const { fanParams } = useCreationState();

  const createPoster = async (posterEntryId: string, posterName: string) => {
    await PosterApi.createPosterGenerationJob({
      poster_params: {
        firstName: params?.customer.firstName,
        lastName: params?.customer.lastName,
        posterTexts: omit(params?.customer),
        exportLayers: params?.exportLayers,
        fan: {
          ...params?.fan,
          ...fanParams,
        },
      },
      auth_token: getToken(),
      poster_entry_id: posterEntryId,
      poster_name: posterName,
    });
  };
  return createPoster;
};

export const useSetFanParams = () => {
  const dispatch = useCreationDispatch();

  return (params: FanParams) => {
    dispatch(setFanParams(params));
  };
};

/**
 * Takes the current poster and the size of the container it's going to be rendered to and
 * calculates the height and width. We use these dimensions to overlay a modal exactly over
 * the poster.
 */
export const useMeasureRenderedPoster = (size?: {
  height: number;
  width: number;
}) => {
  const { poster } = useGetCurrentPoster();

  const [modalDimensions, setModalDimensions] = React.useState<
    Nullable<{ height: number; width: number }>
  >(null);

  const [showZoomModal, setShowZoomModal] = React.useState(false);

  React.useEffect(() => {
    const loadBackdrop = async () => {
      const backdropUrl =
        poster?.backdrops?.nodes?.[0]?.image?.versions.large?.url;

      if (backdropUrl && size && !isDev && !showZoomModal) {
        const image = await loadImage(backdropUrl);

        const scale = Math.min(
          size.width / image.width,
          size.height / image.height
        );

        setModalDimensions({
          height: image.height * scale,
          width: image.width * scale,
        });

        setShowZoomModal(true);
        setTimeout(() => {
          setShowZoomModal(false);
        }, 3000);
      }
    };
    loadBackdrop();
  }, [size, poster]);

  return { showZoomModal, modalDimensions };
};
