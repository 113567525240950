import * as React from "react";

import { Route } from "react-router-dom";
import { MountTransition } from "./MountTransition";

type Props = {
  exact?: boolean;
  path: string;
  children: JSX.Element;
};

export const RouteTransition: React.FC<Props> = ({
  children,
  exact = false,
  path,
  ...rest
}: Props) => (
  <Route exact={exact} path={path} {...rest}>
    <MountTransition>{children}</MountTransition>
  </Route>
);
