import React from "react";
import useComponentSize from "@rehooks/component-size";
import { useHistory } from "react-router-dom";
import omit from "lodash/omit";
import * as icons from "../assets/images/icons";
import manOutlineUrl from "../assets/images/rugby-kid-processed.png";

import {
  useCreationDispatch,
  useCaptureFan,
  useCreationState,
} from "../creationFlowHooks";
import Button from "../shared/Button";
import { setPreviewPoster, SetExportLayers } from "../creationFlowActions";
import Header from "../shared/Header";
import Modal from "../shared/Modal";

import {
  useGetCurrentPoster,
  useSetFanParams,
  basepath,
  useMeasureRenderedPoster,
} from "./posterHooks";
import Hero from "../shared/Hero";
import { Nullable, UnboxPromise } from "../types";
import PosterMaker from "./posterMaker";
import LoadingSpinner from "../shared/LoadingSpinner";
import AdjustmentToolbar from "./adjustmentPanel/AdjustmentToolbar";
import { captureImage } from "../useCamera";
import InfoHeader from "../shared/InfoHeader";

import PinchToZoomModal from "./PinchToZoomModal";
import { getPosterParams } from "./posterHelpers";
import { Poster_Description } from "../shared/Constants";

const PosterPreview: React.FC = () => {
  const ref = React.useRef(null);
  const size = useComponentSize(ref);
  const [processing, setProcessing] = React.useState<boolean>(false);
  const [generating, setGenerating] = React.useState<boolean>(false);

  const [loading, setLoading] = React.useState(true);
  const [showModal, setShowModal] = React.useState(false);

  const { poster } = useGetCurrentPoster();

  const { width, height } = size;
  const generator = React.useRef<
    Nullable<UnboxPromise<ReturnType<typeof PosterMaker>>>
  >(null);
  const history = useHistory();

  const dispatch = useCreationDispatch();
  const setFanParams = useSetFanParams();
  const { processedImage } = useCreationState();

  const { customerDetails } = useCreationState();

  const { showZoomModal, modalDimensions } = useMeasureRenderedPoster(size);

  React.useEffect(() => {
    const loadPoster = async () => {
      if (poster?.backdrops?.nodes?.length) {
        let moveableImageScale: number | undefined;
        //Square posters
        if (
          poster.path === "roaming" ||
          poster.path === "roaminggreen" ||
          poster.path === "oppo"
        ) {
          moveableImageScale = 1;
        }
        //Default is what ever is larger
        let scaleBy: "width" | "height" | undefined;
        if (poster.path === "oppo") {
          scaleBy = "width";
        }
        const posterConfig = {
          firstName: customerDetails?.firstName ?? "",
          lastName: customerDetails?.lastName ?? "",
          posterTexts: omit(customerDetails, ["firstName", "lastName"]),
          backdrops: poster.backdrops.nodes.map((backdrop) => ({
            rawZindex: 0,
            imageUrl: backdrop?.image?.versions.large?.url!,
          })),
          movableImage: {
            imageUrl: processedImage || manOutlineUrl,
            scale: moveableImageScale,
            scaleBy: scaleBy,
          },
        };

        generator.current = await PosterMaker({
          canvasId: "poster-container",
          containerDimensions: {
            height,
            width,
          },
          ...getPosterParams(basepath, posterConfig),
        });

        setLoading(false);
      }
    };
    if (height && width && poster && !generator.current) {
      loadPoster();
    }
  }, [height, width, poster]);

  React.useEffect(() => {
    const finalizePoster = async () => {
      if (processing && generator.current && !generating) {
        setGenerating(true);
        const finalizedImage = await generator.current.export();
        console.log(finalizePoster);
        const { fanParams, exportLayers } = generator.current.exportParams();
        setFanParams(fanParams);
        dispatch(SetExportLayers(exportLayers));
        dispatch(setPreviewPoster(finalizedImage));
        history.replace("/share");
      }
    };
    finalizePoster();
  }, [processing, dispatch, history, generating, setFanParams]);

  const onRetakeSuccess = React.useCallback(() => {
    history.replace("/loading");
  }, [history]);

  const { setupCamera } = useCaptureFan(onRetakeSuccess);

  return (
    <div className="flex flex-col flex-1 bg-whisper overflow-hidden relative">
      <Modal open={showModal}>
        <div className="flex flex-col items-center bg-white rounded-lg self-stretch py-6">
          <div className="flex items-center justify-center bg-primary bg-opacity-75 rounded-lg p-4">
            <img
              alt="Pinch Icon"
              src={icons.pinchIconUrl}
              className="w-18 h-[128px]"
            />
          </div>
          <InfoHeader
            header="Pinch and zoom player image into the perfect image."
            className="my-3"
          />
          <Button
            theme="secondary"
            onClick={() => {
              setShowModal(false);
            }}
          >
            Got it
          </Button>
        </div>
      </Modal>
      <Hero
        role="FINALISE_PAGE"
        defaultHeader={<Header text="Finalise Your Poster" />}
      />

      <div className="flex flex-grow flex-col items-center mx-5 overflow-hidden relative">
        <InfoHeader
          header={`Pinch and zoom for the perfect ${Poster_Description}`}
          className="my-4"
        />

        <div className="relative flex flex-grow w-full justify-center">
          <div
            ref={ref}
            id="poster-container"
            className="flex flex-grow justify-center"
          />
          <PinchToZoomModal
            {...modalDimensions}
            show={showZoomModal && !!generator.current}
          />
        </div>

        {loading && (
          <div className="absolute inset-0 flex items-center justify-center pt-[20%]">
            <LoadingSpinner color="primary" height="100px" width="100px" />
          </div>
        )}
      </div>

      {setupCamera}
      <AdjustmentToolbar
        poster={generator.current}
        onRetake={captureImage}
        onFinish={() => setProcessing(true)}
      />
    </div>
  );
};

export default PosterPreview;
