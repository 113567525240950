import { gql } from "apollo-boost";

export const GET_CURRENT_POSTER = gql`
  query getPoster($id: ID!) {
    node(id: $id) {
      id
      ... on Poster {
        name
        domain
        path
        commercial
        backgroundRemoval
        initialPosterPrice
        additionalFields {
          title
          options {
            title
          }
        }
        backdrops {
          nodes {
            id
            image {
              url
              versions {
                large {
                  url
                }
              }
            }
          }
        }
        form {
          emailLabel
          emailEnabled
          emailPlaceholder
          firstNameLabel
          firstNameEnabled
          firstNamePlaceholder
          lastNameLabel
          lastNameEnabled
          lastNamePlaceholder
          mobileNumberLabel
          mobileNumberEnabled
          mobileNumberPlaceholder
          optInLabel
          optInEnabled
          termsAndConditionsLabel
          termsAndConditionsEnabled
          title
        }
        stylesheet {
          backgroundImage {
            versions {
              large {
                url
              }
            }
          }
          bodyBgColor
          bodyColor
          cardBgColor
          cardColor
          dangerColor

          headerImages {
            url
            role
          }
          infoColor
          lightColor
          primaryColor
          secondaryColor
          splashPageImage {
            url
          }
          successColor
        }
        template {
          facebookShareTitle
          facebookShareDescription
          personaliseText
          personaliseTitle
          personaliseText
          personaliseTitle
          finaliseText
          finaliseTitle
          formText
          formTitle
          pageTitle
          pageDescription
          splashPageText
          splashPageEnabled
          howToPlayEnabled
          termsText
          howToPlayPages {
            id
            position
            text
            title
            icon {
              url
            }
          }
        }
      }
    }
  }
`;

export const CREATE_POSTER_ENTRY = gql`
  mutation CreatePosterEntryMutation($input: CreatePosterEntryInput!) {
    createPosterEntry(input: $input) {
      success
      errors {
        attribute
        messages
      }
      entry {
        id
      }
    }
  }
`;

export const CREATE_POSTER_ORDER = gql`
  mutation CreatePosterOrderMutation($input: CreatePosterOrderInput!) {
    createPosterOrder(input: $input) {
      success
      errors {
        attribute
        messages
      }
      intent {
        amount
        clientSecret
        currency
      }
    }
  }
`;
