import * as React from "react";

interface Props {
  text?: string;
}

const Header: React.FC<Props> = ({ text }: Props) => (
  <div className="bg-primary flex items-center justify-center py-5 sm:py-9 border-black border">
    <h1 className="text:xl text-white font-bold">{text}</h1>
  </div>
);

export default Header;
