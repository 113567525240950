import * as React from "react";
import { motion } from "framer-motion";

type Props = {
  children?: JSX.Element;
};

export const MountTransition: React.FC<Props> = ({ children }: Props) => (
  <motion.div
    className="flex flex-col w-full"
    exit={{ opacity: 0 }}
    initial={{ opacity: 0 }}
    animate={{ opacity: 1, x: 0, y: 0 }}
  >
    {children}
  </motion.div>
);
