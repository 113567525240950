import React from "react";
import { AnimatePresence, motion } from "framer-motion";

export const Modal: React.FC<{ open?: boolean }> = ({
  children,
  open = true,
}) => {
  return (
    <AnimatePresence>
      {open && (
        <motion.div
          initial={{ opacity: 1 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 1 }}
          className="absolute inset-0 flex items-center justify-center px-4 bg-black bg-opacity-25 z-50"
        >
          <motion.div
            initial={{ opacity: 1 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0, y: -10 }}
            className="relative bg-white border min-h-[10rem] w-full rounded-sm p-4"
          >
            {children}
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default Modal;
