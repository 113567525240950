import * as React from "react";
import MobileContainer from "./MobileContainer";

interface Props {
  error: Error;
}

const ErrorScreen: React.FC<Props> = ({ error }: Props) => {
  return (
    <MobileContainer>
      <div>
        <h1>{error.message}</h1>
      </div>
    </MobileContainer>
  );
};
export default ErrorScreen;
