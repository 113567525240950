import React from "react";
import * as Sentry from "@sentry/react";
import { addSeconds } from "date-fns";

import { Nullable } from "../types";
import { PosterApi } from "../api";
import { getClient } from "../ApolloClient";
import { basepath } from "../poster/posterHooks";
import { GET_CURRENT_POSTER } from "../poster/posterQueries";
import { Poster } from "../generated/graphql";

import { loadImage } from "../utils";

import { setAccessToken, getToken } from "../tokenManager";
import { CREATE_USER, GET_POSTERS } from "./authQueries";
export interface Token {
  token: string;
  expiry: number;
}

export interface AuthHookFields {
  error: Nullable<Error>;
  loading: boolean;
}

export let postersMap: Poster[];

const errorsToSurface = ["NoPosterError"];

const noPosterError = new Error("No Poster Found");
noPosterError.name = "NoPosterError";

export const useFullPosterBootstrap = () => {
  const fetchCurrentPoster = async () => {
    const client = getClient();

    const userToken = await client.mutate({
      mutation: CREATE_USER,
      variables: {
        input: {
          clientMutationId: getToken(),
        },
      },
    });

    const { token, expiresIn } = userToken.data.createUser.userToken;

    setAccessToken({
      token,
      expiry: addSeconds(Date.now(), expiresIn),
    });

    const currentPosterId = postersMap[basepath];

    if (!currentPosterId) {
      throw noPosterError;
    }

    await client.query({
      query: GET_CURRENT_POSTER,
      variables: { id: postersMap[basepath].id },
    });
  };

  React.useEffect(() => {
    fetchCurrentPoster();
  }, []);
};

let preloadedImages: HTMLImageElement[] = [];
console.log(preloadedImages);

const preloadPosterImages = async (poster) => {
  const preloadImagePromises: any = [];

  const layers = poster?.backdrops?.nodes;

  const bottomLayerUrl = layers?.image?.versions.large?.url;
  if (bottomLayerUrl) {
    preloadImagePromises.push(loadImage(bottomLayerUrl));
  }
  const topLayerUrl = layers?.image?.versions.large?.url;
  if (topLayerUrl) {
    preloadImagePromises.push(loadImage(topLayerUrl));
  }
  poster?.stylesheet.headerImages.map((header) => {
    preloadImagePromises.push(loadImage(header.url));
  });

  const preloaded = await Promise.all<HTMLImageElement>(preloadImagePromises);
  preloadedImages = preloaded;
};

export const useBootstrap = (): AuthHookFields => {
  const [loading, setIsLoading] = React.useState(true);
  const [error, setError] = React.useState<Nullable<Error>>(null);

  React.useEffect(() => {
    const fetchToken = async (): Promise<void> => {
      try {
        const { token, cache } = await PosterApi.bootstrap();

        localStorage.setItem("authToken", JSON.stringify(token));

        setAccessToken(token);

        if (!basepath) {
          throw noPosterError;
        }

        const client = getClient(cache);

        const posters = await client.query({
          query: GET_POSTERS,
          variables: {
            path: basepath,
          },
        });

        postersMap = posters.data.posters.nodes.reduce((accum, poster) => {
          return {
            ...accum,
            [poster.path]: poster,
          };
        }, {});

        const currentPoster = postersMap[basepath];

        if (!currentPoster) {
          throw noPosterError;
        }

        preloadPosterImages(currentPoster);

        console.log("Finished preloading");
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        if (errorsToSurface.includes(error.name)) {
          setError(error);
        } else {
          setError(new Error("Something went wrong, please try again soon"));
        }
        Sentry.captureException(error);
        setIsLoading(false);
      }
    };

    fetchToken();
  }, []);

  return {
    loading,
    error,
  };
};
