import { Nullable } from "./types";

export const downloadURI = (uri: string, filename: string) => {
  const link = document.createElement("a");
  link.download = filename;
  link.href = uri;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const loadImage = (src: string): Promise<HTMLImageElement> => {
  return new Promise((resolve) => {
    const image = new Image();
    image.crossOrigin = "Anonymous";

    image.onload = (): void => {
      resolve(image);
    };
    image.onerror = (): void => {
      console.log("Error");
      resolve();
    };
    image.src = src;
  });
};

export const urlToObject = async (imageUrl: string) => {
  const response = await fetch(imageUrl);
  const blob = await response.blob();
  const file = new File([blob], "image.jpg", { type: blob.type });
  return file;
};

export function dataURLtoFile(dataUrl: string, filename: string) {
  const arr = dataUrl.split(",");
  const mime = arr[0]?.match(/:(.*?);/)?.[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }

  return new File([u8arr], filename, { type: mime });
}

export const trimImage = async (imageB64: string) => {
  const image = await loadImage(imageB64);

  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  canvas.height = image.height;
  canvas.width = image.width;
  canvas.style.height = `${image.height}px`;
  canvas.style.width = `${image.width}px`;

  if (!ctx) return;

  ctx.drawImage(image, 0, 0, image.width, image.height);
  const pixels = ctx.getImageData(0, 0, image.width, image.height);
  const pixelLength = pixels.data.length;

  const bound: {
    top: Nullable<number>;
    left: Nullable<number>;
    right: Nullable<number>;
    bottom: Nullable<number>;
  } = {
    top: null,
    left: null,
    right: null,
    bottom: null,
  };

  for (let i = 0; i < pixelLength; i += 4) {
    // Transparent pixel
    if (pixels.data[i + 3] !== 0) {
      const x = (i / 4) % image.width;
      const y = ~~(i / 4 / image.width);

      if (bound.top === null) {
        bound.top = y;
      } else if (y < bound.top) {
        bound.top = y;
      }

      if (bound.left === null) {
        bound.left = x;
      } else if (x < bound.left) {
        bound.left = x;
      }

      if (bound.right === null) {
        bound.right = x;
      } else if (bound.right < x) {
        bound.right = x;
      }

      if (bound.bottom === null) {
        bound.bottom = y;
      } else if (bound.bottom < y) {
        bound.bottom = y;
      }
    }
  }

  if (!bound.bottom || !bound.top || !bound.left || !bound.right) {
    return imageB64;
  }

  const trimHeight = bound.bottom - bound.top;
  const trimWidth = bound.right - bound.left;

  const trimmed = ctx.getImageData(
    bound.left,
    bound.top,
    trimWidth,
    trimHeight
  );

  canvas.height = trimHeight;
  canvas.width = trimWidth;
  canvas.style.height = `${trimHeight}px`;
  canvas.style.width = `${trimWidth}px`;

  ctx.putImageData(trimmed, 0, 0);

  return canvas.toDataURL();
};

export const convertCanvasToBlob = (
  canvasElement: HTMLCanvasElement
): Promise<File> => {
  return new Promise((resolve) => {
    canvasElement.toBlob(
      (blob) => {
        const imageFile = new File([blob!], "fan-cutout.jpg", {
          type: "image/jpeg",
          lastModified: Date.now(),
        });
        resolve(imageFile);
      },
      "image/jpeg",
      1
    );
  });
};

export const imageElementToFile = async (
  src: string,
  fileName: string,
  mimeType: "image/png" | "image/jpg"
) => {
  try {
    const response = await fetch(src);
    const arrayBuffer = await response.arrayBuffer();
    return {
      data: new File([arrayBuffer], fileName, { type: mimeType }),
      error: null,
    };
  } catch (_) {
    return {
      data: null,
      error: "Error converting image to file",
    };
  }
};
