import { PosterFieldConfig } from "./posterMaker";
import textureFontImageUrl from "../assets/images/font-texture.png";

const weetbix2024Fonts = [
  "Flexible H400 W200",
  "Cutmark Variable",
  "Atomic Marker",
];

const aimsGamesFonts = ["Sequel-Neue"];

export const postersFonts = {
  athleteprofile: ["acumin-pro-extra-condensed"],
  weetbixstatattack: ["Laudanum", "United Sans SmEx Hv"],
  "test-poster": ["Laudanum", "United Sans SmEx Hv"],
  northshorerugby: ["galactic_vanguardian", "riftbold", "lakeshoreregular"],
  northshorestaging: ["galactic_vanguardian", "riftbold", "lakeshoreregular"],
  birkenheadunited: ["GlossAndBloom", "DrukWide"],
  "2degreesBlues": ["circularstdblack"],
  blues: ["circularstdblack"],
  bluesLive: ["circularstdblack"],
  highlanders: ["circularstdblack"],
  chiefs: ["circularstdblack"],
  Hurricanes: ["circularstdblack"],
  crusaders: ["circularstdblack"],
  crusadersLive: ["circularstdblack"],
  HurricanesPOUA: ["circularstdblack"],
  manawa: ["circularstdblack"],
  matatu: ["circularstdblack"],
  MtMaunganui: aimsGamesFonts,
  MtMaunganui2024: aimsGamesFonts,
  stadium2024: aimsGamesFonts,
  returningathlete2024: aimsGamesFonts,
  stadium: aimsGamesFonts,
  returningathlete: aimsGamesFonts,
  roaming: aimsGamesFonts,
  roaminggreen: aimsGamesFonts,
  ToyotaGR010Hybrid: ["FontsFree-Net-Proxima-Nova-Xbold-It"],
  GRYARISRally1HYBRID: ["FontsFree-Net-Proxima-Nova-Xbold-It"],
  GRSupra: ["FontsFree-Net-Proxima-Nova-Xbold-It"],
  GRCorolla: ["FontsFree-Net-Proxima-Nova-Xbold-It"],
  ft60: ["FontsFree-Net-Proxima-Nova-Xbold-It"],
  Maccas360Challenge: ["Speedee App", "Swipe Race Demo"],
  weetbixstatattack2023: [
    "United Sans Heavy Cd",
    "United Sans Black SmExtd",
    "United Sans Heavy",
  ],
  weetbixstatattackblackferns: [
    "United Sans Heavy Cd",
    "United Sans Black SmExtd",
    "United Sans Heavy",
  ],
  woolworthsblackferns: [
    "United Sans Heavy Cd",
    "United Sans Black SmExtd",
    "United Sans Heavy",
  ],
  woolworths: [
    "United Sans Heavy Cd",
    "United Sans Black SmExtd",
    "United Sans Heavy",
  ],
  bluesSuperFan: ["Circular Pro Black", "Circular Pro Bold"],
  chiefsSuperFan: ["Circular Pro Black", "Circular Pro Bold"],
  hurricanesSuperFan: ["Circular Pro Black", "Circular Pro Bold"],
  crusadersSuperFan: ["Circular Pro Black", "Circular Pro Bold"],
  highlandersSuperFan: ["Circular Pro Black", "Circular Pro Bold"],
  "weetbixstatattack-dazzlingdynamo-offence": weetbix2024Fonts,
  "statattack-allblacks-dazzlingdynamo-sidestep": weetbix2024Fonts,
  "statattack-allblacks-superspeedster-intercept": weetbix2024Fonts,
  "statattack-allblacks-superspeedster-chipandchase": weetbix2024Fonts,
  "statattack-allblacks-persistentpowerhouse-steal": weetbix2024Fonts,
  "statattack-allblacks-persistentpowerhouse-offload": weetbix2024Fonts,
  "statattack-allblacks-daringdefender-fend": weetbix2024Fonts,
  "statattack-allblacks-daringdefender-BallAndAllTackle": weetbix2024Fonts,
  "statattack-allblacks-dazzlingdynamo-chargedown": weetbix2024Fonts,
  "statattack-blackferns-dazzlingdynamo-sidestep": weetbix2024Fonts,
  "statattack-blackferns-superspeedster-intercept": weetbix2024Fonts,
  "statattack-blackferns-superspeedster-chipandchase": weetbix2024Fonts,
  "statattack-blackferns-persistentpowerhouse-steal": weetbix2024Fonts,
  "statattack-blackferns-persistentpowerhouse-offload": weetbix2024Fonts,
  "statattack-blackferns-daringdefender-fend": weetbix2024Fonts,
  "statattack-blackferns-daringdefender-BallAndAllTackle": weetbix2024Fonts,
  "statattack-blackferns-dazzlingdynamo-chargedown": weetbix2024Fonts,
  TipTopBakery: ["Brandon Grotesque"],
  oppo: ["OPPO Sans B5"],
};
const aimsBaseActions = {
  message:
    "Your AIMS Athlete Player Card has been sent! Check your email to view and share it",
  redirect: "chooseyourbackground",
};
const toyotaBaseActions = {
  message:
    "Your Toyota Gazoo Racing Profile has been sent! Check your email to view and share it",
  redirect: "GR",
};
const twoDegreesSuperFanActions = {
  message: "Your Poster has been sent! Check your email to view and share it",
  redirect: "superfan",
};
const weetBix2024Actions = {
  message: "Your Poster has been sent! Check your email to view and share it",
  redirect: "master",
};

export const posterSuccessActions = {
  MtMaunganui: aimsBaseActions,
  OutdoorSports: aimsBaseActions,
  IndoorSports: aimsBaseActions,
  stadium: aimsBaseActions,
  returningathlete: aimsBaseActions,
  MtMaunganui2024: aimsBaseActions,
  stadium2024: aimsBaseActions,
  returningathlete2024: aimsBaseActions,
  roaming: {
    message: aimsBaseActions.message,
    redirect: "roaming",
  },
  roaminggreen: {
    message: aimsBaseActions.message,
    redirect: "roaminggreen",
  },
  ToyotaGR010Hybrid: toyotaBaseActions,
  GRYARISRally1HYBRID: toyotaBaseActions,
  GRSupra: toyotaBaseActions,
  GRCorolla: toyotaBaseActions,
  ft60: toyotaBaseActions,
  bluesSuperFan: twoDegreesSuperFanActions,
  chiefsSuperFan: twoDegreesSuperFanActions,
  hurricanesSuperFan: twoDegreesSuperFanActions,
  crusadersSuperFan: twoDegreesSuperFanActions,
  highlandersSuperFan: twoDegreesSuperFanActions,
  "statattack-allblacks-dazzlingdynamo-sidestep": weetBix2024Actions,
  "statattack-allblacks-superspeedster-intercept": weetBix2024Actions,
  "statattack-allblacks-superspeedster-chipandchase": weetBix2024Actions,
  "statattack-allblacks-persistentpowerhouse-steal": weetBix2024Actions,
  "statattack-allblacks-persistentpowerhouse-offload": weetBix2024Actions,
  "statattack-allblacks-daringdefender-fend": weetBix2024Actions,
  "statattack-allblacks-daringdefender-BallAndAllTackle": weetBix2024Actions,
  "statattack-allblacks-dazzlingdynamo-chargedown": weetBix2024Actions,
  "statattack-blackferns-dazzlingdynamo-sidestep": weetBix2024Actions,
  "statattack-blackferns-superspeedster-intercept": weetBix2024Actions,
  "statattack-blackferns-superspeedster-chipandchase": weetBix2024Actions,
  "statattack-blackferns-persistentpowerhouse-steal": weetBix2024Actions,
  "statattack-blackferns-persistentpowerhouse-offload": weetBix2024Actions,
  "statattack-blackferns-daringdefender-fend": weetBix2024Actions,
  "statattack-blackferns-daringdefender-BallAndAllTackle": weetBix2024Actions,
  "statattack-blackferns-dazzlingdynamo-chargedown": weetBix2024Actions,
  oppo: {
    message: "Your image has been sent! Check your email to view and share it",
    redirect: "oppo",
  },
};

interface PosterConfig {
  firstName: string;
  lastName: string;
  backdrops: { rawZindex: number; imageUrl: string }[];
  posterTexts: any;
  movableImage: {
    imageUrl: string;
    x?: number;
    y?: number;
    height?: number;
    width?: number;
  };
}

export const getPosterParams = (
  posterName: string,
  config: PosterConfig
): PosterFieldConfig => {
  const northShoreTemplate = {
    backdrops: config.backdrops.map((backdrop, index) => ({
      ...backdrop,
      rawZindex: index === 0 ? 2 : index === 1 ? 3 : 0,
    })),
    posterName: {
      bottomY: 0.83,
      paddingY: 0.01,
      maxWidth: 0.8,
      centerX: true,
      firstName: {
        text: config.firstName.toUpperCase(),
        fontFamily: "galactic_vanguardian",
        fontSize: 0.1,
        fill: "white",
        stroke: "white",
        x: 0.1,
        skewX: -0.215,
        rawZindex: 4,
        maxFontSize: 0.14,
      },
      lastName: {
        text: config.lastName.toUpperCase(),
        fontFamily: "galactic_vanguardian",
        fontSize: 0.1,
        fill: "#7DFEA9",
        stroke: "#7DFEA9",

        strokeWidth: 2,
        x: 0.1,
        skewX: -0.215,
        rawZindex: 4,
        maxFontSize: 0.14,
      },
    },
    posterTexts: {
      age: {
        id: "age",
        fill: "#1D4120",
        fontFamily: "riftbold",
        fontSize: 0.021,
        rawZindex: 4,
        x: 0.07,
        y: 0.328,
        skewX: -0.215,
        text: `${config.posterTexts.age} Years Old`,
      },
      playerNumber: {
        id: "playerNumber",
        fill: "white",
        fontFamily: "lakeshoreregular",
        fontSize: 0.13,
        rawZindex: 4,
        skewX: -0.215,
        x: 0.75,
        y: 0.81,
        text: `#${config.posterTexts.playerNumber}`,
      },
      position: {
        id: "position",
        fill: "#1D4120",
        fontFamily: "riftbold",
        fontSize: 0.021,
        rawZindex: 4,
        x: 0.07,
        y: 0.458,
        skewX: -0.215,
        text: config.posterTexts.position,
      },
      team: {
        id: "team",
        fill: "#1D4120",
        fontFamily: "riftbold",
        fontSize: 0.021,
        rawZindex: 4,
        skewX: -0.215,
        x: 0.07,
        y: 0.395,
        text: config.posterTexts.team,
      },
      topSkill: {
        id: "topSkill",
        fill: "#1D4120",
        fontFamily: "riftbold",
        fontSize: 0.021,
        rawZindex: 4,
        skewX: -0.215,
        x: 0.07,
        y: 0.525,
        text: config.posterTexts.topSkill,
      },
    },
    movableImage: {
      imageUrl:
        "https://res.cloudinary.com/dglcgx45v/image/upload/v1616452695/h3nt2bn26we8sbf7e2ju.png",
      rawZindex: 1,
      ...(config.movableImage && { ...config.movableImage }),
    },
  };
  //Temp backdrop layer ordering for staging.
  //The future we will get the created from the API and order on this which
  //will mean no need for any difference between environments.
  const northShoreStagingTemplate = northShoreTemplate;
  northShoreStagingTemplate.backdrops = config.backdrops.map(
    (backdrop, index) => ({
      ...backdrop,
      rawZindex: index === 0 ? 3 : index === 1 ? 2 : 0,
    })
  );
  const weetbixStatattackTemplate = {
    backdrops: config.backdrops.map((backdrop, index) => ({
      ...backdrop,
      rawZindex: index === 0 ? 3 : 0,
    })),
    posterName: {
      bottomY: 0.765,
      paddingY: 0,
      maxWidth: 0.9,
      centerX: true,
      container: {
        y: 0.615,
        height: 0.107,
      },
      firstAndLastName: {
        text: `${config.firstName.toUpperCase()} ${config.lastName.toUpperCase()}`,
        fontFamily: "Laudanum",
        fontSize: 0.1,
        fill: "white",
        rawZindex: 4,
        maxFontSize: 0.08,
        x: 0.2,
      },
    },
    posterTexts: {
      age: {
        id: "age",
        text: config.posterTexts.age,
        fill: "white",
        fontFamily: "United Sans SmEx Hv",
        fontStyle: "bold",
        fontSize: 0.03,
        rawZindex: 4,
        x: 0.77,
        y: 0.8,
        ...(config.posterTexts.age && { ...config.posterTexts.age }),
      },
      weetbixEaten: {
        id: "weetbixEaten",
        text: config.posterTexts["howManyWeetBix™CanYouEat"],
        fill: "white",
        fontFamily: "United Sans SmEx Hv",
        fontStyle: "bold",
        fontSize: 0.03,
        rawZindex: 4,
        x: 0.77,
        y: 0.839,
        ...(config.posterTexts["howManyWeetBix™CanYouEat"] && {
          ...config.posterTexts["howManyWeetBix™CanYouEat"],
        }),
      },
    },

    movableImage: {
      rawZindex: 1,
      ...(config.movableImage && { ...config.movableImage }),
    },
  };

  //blues staging  rawZindex: index === 0 ? 3 : index === 1 ? 0 : 2,
  const base2degreesTemplate = {
    backdrops: {},
    posterName: {
      bottomY: 0.68,
      paddingY: 0.01,
      maxWidth: 0.8,
      firstName: {
        text: config.firstName.toUpperCase(),
        fontFamily: "circularstdblack",
        fontSize: 0.079,
        fill: "white",
        stroke: "white",
        x: 0.11,
        rawZindex: 4,
        maxFontSize: 0.079,
      },
      lastName: {
        text: config.lastName.toUpperCase(),
        fontFamily: "circularstdblack",
        fontSize: 0.079,
        fill: "white",
        stroke: "white",
        strokeWidth: 2,
        x: 0.11,
        rawZindex: 4,
        maxFontSize: 0.079,
      },
    },
    posterTexts: {
      position: {
        id: "position",
        fill: "white",
        fontFamily: "circularstdblack",
        fontSize: 0.02,
        rawZindex: 4,
        x: 0.123,
        y: 0.755,
        text: config.posterTexts.position,
      },
      favouritePlayer: {
        id: "favouritePlayer",
        fill: "white",
        fontFamily: "circularstdblack",
        fontSize: 0.02,
        rawZindex: 4,
        x: 0.123,
        y: 0.825,
        text: config.posterTexts.favouritePlayer,
      },
      bestSkill: {
        id: "bestSkill",
        fill: "white",
        fontFamily: "circularstdblack",
        fontSize: 0.02,
        rawZindex: 4,
        x: 0.123,
        y: 0.9,
        text: config.posterTexts.bestSkill,
      },
    },
    movableImage: {
      imageUrl:
        "https://res.cloudinary.com/dglcgx45v/image/upload/v1616452695/h3nt2bn26we8sbf7e2ju.png",
      rawZindex: 1,
      ...(config.movableImage && { ...config.movableImage }),
    },
  };

  const bluesTemplate = Object.assign({}, base2degreesTemplate);
  bluesTemplate.backdrops = config.backdrops.map((backdrop, index) => ({
    ...backdrop,
    rawZindex: index === 0 ? 3 : index === 1 ? 2 : 0,
  }));

  const bluesLiveTemplate = Object.assign({}, base2degreesTemplate);
  bluesLiveTemplate.backdrops = config.backdrops.map((backdrop, index) => ({
    ...backdrop,
    rawZindex: index === 0 ? 0 : index === 1 ? 2 : 3,
  }));

  const highlandersTemplate = Object.assign({}, base2degreesTemplate);
  highlandersTemplate.backdrops = config.backdrops.map((backdrop, index) => ({
    ...backdrop,
    rawZindex: index === 0 ? 3 : index === 1 ? 0 : 2,
  }));
  const chiefsTemplate = Object.assign({}, base2degreesTemplate);
  chiefsTemplate.backdrops = config.backdrops.map((backdrop, index) => ({
    ...backdrop,
    rawZindex: index === 0 ? 0 : index === 1 ? 3 : 2,
  }));
  const hurricanesTemplate = Object.assign({}, base2degreesTemplate);
  hurricanesTemplate.backdrops = config.backdrops.map((backdrop, index) => ({
    ...backdrop,
    rawZindex: index === 0 ? 3 : index === 1 ? 2 : 0,
  }));
  const crusadersTemplate = Object.assign({}, base2degreesTemplate);
  crusadersTemplate.backdrops = config.backdrops.map((backdrop, index) => ({
    ...backdrop,
    rawZindex: index === 0 ? 3 : index === 1 ? 2 : 0,
  }));

  const crusadersLiveTemplate = Object.assign({}, base2degreesTemplate);
  crusadersLiveTemplate.backdrops = config.backdrops.map((backdrop, index) => ({
    ...backdrop,
    rawZindex: index === 0 ? 0 : index === 1 ? 2 : 3,
  }));

  const hurricanesPOUATemplate = Object.assign({}, base2degreesTemplate);
  hurricanesPOUATemplate.backdrops = config.backdrops.map(
    (backdrop, index) => ({
      ...backdrop,
      rawZindex: index === 0 ? 2 : index === 1 ? 0 : 3,
    })
  );
  const manawaTemplate = Object.assign({}, base2degreesTemplate);
  manawaTemplate.backdrops = config.backdrops.map((backdrop, index) => ({
    ...backdrop,
    rawZindex: index === 0 ? 2 : index === 1 ? 0 : 3,
  }));
  const matatuTemplate = Object.assign({}, base2degreesTemplate);
  matatuTemplate.backdrops = config.backdrops.map((backdrop, index) => ({
    ...backdrop,
    rawZindex: index === 0 ? 3 : index === 1 ? 2 : 0,
  }));

  //let aimsPaddingY = 0.06;
  let aimsNamesBottomY = 0.85;
  //Tweak padding based on name length
  if (config.firstName?.length >= 14 && config.lastName?.length >= 14) {
    aimsNamesBottomY = 0.824;
  } else if (config.firstName?.length >= 14 || config.lastName?.length >= 14) {
    if (config.firstName?.length > 15 || config.lastName?.length > 15) {
      aimsNamesBottomY = 0.834;
    } else {
      aimsNamesBottomY = 0.836;
    }
  }
  const aimsGamesTemplate2023 = {
    backdrops: {},
    posterName: {
      bottomY: aimsNamesBottomY,
      paddingY: 0.02,
      maxWidth: 0.8,
      firstName: {
        text: config.firstName.toUpperCase(),
        fontFamily: "Sequel-Neue",
        fontSize: 0.09,
        fill: "white",
        stroke: "white",
        strokeWidth: 1,
        x: 0.08,
        rawZindex: 4,
        maxFontSize: 0.09,
      },
      lastName: {
        text: config.lastName.toUpperCase(),
        fontFamily: "Sequel-Neue",
        fontSize: 0.09,
        fill: "white",
        stroke: "white",
        strokeWidth: 1,
        x: 0.08,
        rawZindex: 4,
        maxFontSize: 0.09,
      },
    },
    posterTexts: {
      yourAge: {
        id: "yourAge",
        fill: "#328038",
        fontFamily: "Sequel-Neue",
        fontSize: 0.02,
        rawZindex: 4,
        x: 0.091,
        y: 0.489,
        text: config.posterTexts.yourAge,
      },
      yourSchool: {
        id: "yourSchool",
        fill: "white",
        fontFamily: "Sequel-Neue",
        fontSize: 0.02,
        rawZindex: 4,
        x: 0.091,
        y: 0.543,
        text: config.posterTexts.yourSchool,
      },
      yourSport: {
        id: "yourSport",
        fill: "#328038",
        fontFamily: "Sequel-Neue",
        fontSize: 0.02,
        rawZindex: 4,
        x: 0.091,
        y: 0.595,
        text: config.posterTexts.yourSport,
      },
    },
    movableImage: {
      imageUrl:
        "https://res.cloudinary.com/dglcgx45v/image/upload/v1616452695/h3nt2bn26we8sbf7e2ju.png",
      rawZindex: 1,
      ...(config.movableImage && { ...config.movableImage }),
    },
  };

  const mtMaunganuiTemplate = Object.assign({}, aimsGamesTemplate2023);
  mtMaunganuiTemplate.backdrops = config.backdrops.map((backdrop, index) => ({
    ...backdrop,
    rawZindex: index === 0 ? 3 : index === 1 ? 0 : 2,
  }));

  const stadium = Object.assign({}, aimsGamesTemplate2023);
  stadium.backdrops = config.backdrops.map((backdrop, index) => ({
    ...backdrop,
    rawZindex: index === 0 ? 3 : index === 1 ? 0 : 2,
  }));

  const returningathlete = Object.assign({}, aimsGamesTemplate2023);
  returningathlete.backdrops = config.backdrops.map((backdrop, index) => ({
    ...backdrop,
    rawZindex: index === 0 ? 0 : index === 1 ? 2 : 0,
  }));

  //Adjust the padding when the bottom name is scaled as
  //it affects where the second test (firstName) is displayed.
  let paddingY = 0.06;
  const bottomY = 0.93;
  if (config.posterTexts.yourSport?.length >= 20) {
    if (config.posterTexts.yourSchool?.length < 44) {
      paddingY = 0.07;
    } else {
      paddingY = 0.08;
    }
  } else {
    if (config.posterTexts.yourSchool?.length >= 40) {
      paddingY = 0.07;
    }
  }
  const aimsGamesRoamingTemplate = {
    backdrops: [{ ...config.backdrops[0], rawZindex: 2 }],
    posterName: {
      bottomY: bottomY,
      paddingY: paddingY,
      maxWidth: 0.5,
      firstName: {
        text: config.posterTexts.yourSchool,
        fontFamily: "Sequel-Neue",
        fontSize: 0.05,
        fill: "#328038",
        stroke: "#328038",
        strokeWidth: 0.5,
        x: 0.115,
        rawZindex: 4,
        maxFontSize: 0.05,
        maxWidth: 0.785,
      },
      lastName: {
        text: config.posterTexts.yourSport,
        fontFamily: "Sequel-Neue",
        fontSize: 0.05,
        fill: "#328038",
        stroke: "#328038",
        strokeWidth: 0.5,
        x: 0.115,
        rawZindex: 4,
        maxFontSize: 0.05,
        maxWidth: 0.5,
      },
    },
    posterTexts: {
      yourSchool: {
        id: "yourSchool",
        fill: "#328038",
        fontFamily: "Sequel-Neue",
        fontSize: 0.04,
        rawZindex: 4,
        x: 0.2,
        y: 0.745,
        text: "",
      },
      yourSport: {
        id: "yourSport",
        fill: "#328038",
        fontFamily: "Sequel-Neue",
        fontSize: 0.05,
        rawZindex: 4,
        x: 0.12,
        y: 0.85,
        text: "",
      },
    },
    movableImage: {
      imageUrl:
        "https://res.cloudinary.com/dglcgx45v/image/upload/v1616452695/h3nt2bn26we8sbf7e2ju.png",
      rawZindex: 1,
      ...(config.movableImage && { ...config.movableImage }),
    },
  };

  const aimsGamesRoamingGreenTemplate = {
    backdrops: [{ ...config.backdrops[0], rawZindex: 2 }],
    posterName: {
      bottomY: bottomY,
      paddingY: paddingY,
      maxWidth: 0.5,
      firstName: {
        text: config.posterTexts.yourSchool,
        fontFamily: "Sequel-Neue",
        fontSize: 0.05,
        fill: "#0C5B38",
        stroke: "#0C5B38",
        strokeWidth: 0.5,
        x: 0.115,
        rawZindex: 4,
        maxFontSize: 0.05,
        maxWidth: 0.785,
      },
      lastName: {
        text: config.posterTexts.yourSport,
        fontFamily: "Sequel-Neue",
        fontSize: 0.05,
        fill: "#B1CD3E",
        stroke: "#B1CD3E",
        strokeWidth: 0.5,
        x: 0.115,
        rawZindex: 4,
        maxFontSize: 0.05,
        maxWidth: 0.5,
      },
    },
    posterTexts: {
      yourSchool: {
        id: "yourSchool",
        fill: "#328038",
        fontFamily: "Sequel-Neue",
        fontSize: 0.04,
        rawZindex: 4,
        x: 0.2,
        y: 0.745,
        text: "",
      },
      yourSport: {
        id: "yourSport",
        fill: "#328038",
        fontFamily: "Sequel-Neue",
        fontSize: 0.05,
        rawZindex: 4,
        x: 0.12,
        y: 0.85,
        text: "",
      },
    },
    movableImage: {
      imageUrl:
        "https://res.cloudinary.com/dglcgx45v/image/upload/v1616452695/h3nt2bn26we8sbf7e2ju.png",
      rawZindex: 1,
      ...(config.movableImage && { ...config.movableImage }),
    },
  };

  const mtMaunganui2024Template = Object.assign({}, aimsGamesTemplate2023);
  mtMaunganui2024Template.backdrops = config.backdrops.map(
    (backdrop, index) => ({
      ...backdrop,
      rawZindex: index === 0 ? 0 : index === 1 ? 2 : 2,
    })
  );

  const stadium2024 = Object.assign({}, aimsGamesTemplate2023);
  stadium2024.backdrops = config.backdrops.map((backdrop, index) => ({
    ...backdrop,
    rawZindex: index === 0 ? 0 : index === 1 ? 2 : 2,
  }));

  const returningathlete2024 = Object.assign({}, aimsGamesTemplate2023);
  returningathlete2024.backdrops = config.backdrops.map((backdrop, index) => ({
    ...backdrop,
    rawZindex: index === 0 ? 2 : index === 1 ? 0 : 0,
  }));

  const toyotaTemplate = {
    backdrops: {},
    posterName: {
      bottomY: 0.75,
      paddingY: 0.02,
      maxWidth: 0.8,
      firstName: {
        text: config.firstName.toUpperCase(),
        fontFamily: "FontsFree-Net-Proxima-Nova-Xbold-It",
        fontSize: 0.075,
        fill: "white",
        stroke: "white",
        strokeWidth: 1,
        x: 0.09,
        rawZindex: 4,
        maxFontSize: 0.075,
      },
      lastName: {
        text: config.lastName.toUpperCase(),
        fontFamily: "FontsFree-Net-Proxima-Nova-Xbold-It",
        fontSize: 0.075,
        fill: "white",
        stroke: "white",
        strokeWidth: 1,
        x: 0.09,
        rawZindex: 4,
        maxFontSize: 0.075,
      },
    },
    posterTexts: {},
    movableImage: {
      imageUrl:
        "https://res.cloudinary.com/dglcgx45v/image/upload/v1616452695/h3nt2bn26we8sbf7e2ju.png",
      rawZindex: 1,
      ...(config.movableImage && { ...config.movableImage }),
    },
  };

  const toyotaGr010Template = Object.assign({}, toyotaTemplate);
  toyotaGr010Template.backdrops = config.backdrops.map((backdrop, index) => ({
    ...backdrop,
    rawZindex: index === 0 ? 0 : index === 1 ? 2 : 3,
  }));

  const toyotaGrarisTemplate = Object.assign({}, toyotaTemplate);
  toyotaGrarisTemplate.backdrops = config.backdrops.map((backdrop, index) => ({
    ...backdrop,
    rawZindex: index === 0 ? 0 : index === 1 ? 3 : 2,
  }));

  const toyotaGrSupraTemplate = Object.assign({}, toyotaTemplate);
  toyotaGrSupraTemplate.backdrops = config.backdrops.map((backdrop, index) => ({
    ...backdrop,
    rawZindex: index === 0 ? 3 : index === 1 ? 0 : 2,
  }));

  const toyotaGrCorollaTemplate = Object.assign({}, toyotaTemplate);
  toyotaGrCorollaTemplate.backdrops = config.backdrops.map(
    (backdrop, index) => ({
      ...backdrop,
      rawZindex: index === 0 ? 2 : index === 1 ? 0 : 3,
    })
  );

  const toyotaft60Template = Object.assign({}, toyotaTemplate);
  toyotaft60Template.backdrops = config.backdrops.map((backdrop, index) => ({
    ...backdrop,
    rawZindex: index === 0 ? 2 : index === 1 ? 3 : 0,
  }));

  const mcdonalds = {
    backdrops: config.backdrops.map((backdrop, index) => ({
      ...backdrop,
      rawZindex: index === 0 ? 3 : index === 1 ? 2 : 0,
    })),
    posterName: {
      bottomY: 0.97,
      paddingY: 0.02,
      maxWidth: 0.9,
      firstName: {
        text: toStartCase(config.firstName),
        fontFamily: "Speedee App",
        fontSize: 0.05,
        fill: "black",
        stroke: "black",
        strokeWidth: 0,
        x: 0.04,
        rawZindex: 4,
        maxFontSize: 0.05,
      },
      lastName: {
        text: toStartCase(config.lastName),
        fontFamily: "Speedee App",
        fontSize: 0.05,
        fill: "black",
        stroke: "black",
        strokeWidth: 0,
        x: 0.04,
        rawZindex: 4,
        maxFontSize: 0.05,
      },
    },
    posterTexts: {
      age: {
        id: "age",
        text: config.posterTexts.age,
        fill: "white",
        fontFamily: "Swipe Race Demo",
        fontStyle: "normal",
        fontSize: 0.025,
        rawZindex: 4,
        x: 0.04,
        y: 0.495,
      },
      position: {
        id: "position",
        text: config.posterTexts.position,
        fill: "white",
        fontFamily: "Swipe Race Demo",
        fontStyle: "normal",
        fontSize: 0.025,
        rawZindex: 4,
        x: 0.04,
        y: 0.566,
      },
      bestSkill: {
        id: "bestskill",
        text: config.posterTexts.bestSkill,
        fill: "white",
        fontFamily: "Swipe Race Demo",
        fontStyle: "normal",
        fontSize: 0.025,
        rawZindex: 4,
        x: 0.04,
        y: 0.642,
      },
      playerBestScore: {
        id: "playerBestScore",
        text: config.posterTexts.playerBestScore,
        fill: "white",
        fontFamily: "Swipe Race Demo",
        fontStyle: "normal",
        fontSize: 0.05,
        rawZindex: 4,
        x: 0.03,
        y: 0.758,
      },
    },

    movableImage: {
      rawZindex: 1,
      ...(config.movableImage && { ...config.movableImage }),
    },
  };
  const eatenX =
    config.posterTexts.howManyWeetBixCanYouEatInOneSitting < 10 ? 0.28 : 0.26;
  const weetbixstatattack2023base = {
    backdrops: {},
    posterName: {
      bottomY: 0.954,
      paddingY: 0.012,
      maxWidth: 0.55,
      firstName: {
        text: config.firstName.toUpperCase(),
        fontFamily: "United Sans Heavy",
        fontStyle: "bold",
        fontSize: 0.042,
        fill: "black",
        stroke: "black",
        strokeWidth: 0,
        x: 0.13,
        rawZindex: 4,
        maxFontSize: 0.042,
        rotation: -12,
        skewX: -0.215,
      },
      lastName: {
        text: config.lastName.toUpperCase(),
        fontFamily: "United Sans Black SmExtd",
        fontSize: 0.065,
        fill: "black",
        stroke: "black",
        strokeWidth: 0,
        x: 0.13,
        rawZindex: 4,
        maxFontSize: 0.065,
        rotation: -12,
        skewX: -0.215,
      },
    },
    posterTexts: {
      whatYearDidYouStartWorkingAtFoodstuffs: {
        id: "debut",
        text: config.posterTexts.whatYearDidYouStartWorkingAtFoodstuffs,
        fill: "white",
        fontFamily: "United Sans Heavy Cd",
        fontStyle: "bold",
        fontSize: 0.055,
        rawZindex: 4,
        x: 0.235,
        y: 0.329,
        rotation: -12,
        skewX: -0.215,
      },
      whatYearDidYouStartWorkingAtWoolworths: {
        id: "debut",
        text: config.posterTexts.whatYearDidYouStartWorkingAtWoolworths,
        fill: "white",
        fontFamily: "United Sans Heavy Cd",
        fontStyle: "bold",
        fontSize: 0.055,
        rawZindex: 4,
        x: 0.235,
        y: 0.329,
        rotation: -12,
        skewX: -0.215,
      },
      howManyWeetBixCanYouEatInOneSitting: {
        id: "eaten",
        text: config.posterTexts.howManyWeetBixCanYouEatInOneSitting,
        fill: "white",
        fontFamily: "United Sans Heavy Cd",
        fontStyle: "bold",
        fontSize: 0.055,
        rawZindex: 4,
        x: eatenX,
        y: 0.398,
        rotation: -12,
        skewX: -0.215,
      },
    },

    movableImage: {
      rawZindex: 1,
      ...(config.movableImage && { ...config.movableImage }),
    },
  };

  const weetbixstatattack2023 = Object.assign({}, weetbixstatattack2023base);
  weetbixstatattack2023.backdrops = config.backdrops.map((backdrop, index) => ({
    ...backdrop,
    rawZindex: index === 0 ? 0 : 3,
  }));

  const weetbixstatattackblackferns = Object.assign(
    {},
    weetbixstatattack2023base
  );
  weetbixstatattackblackferns.backdrops = config.backdrops.map(
    (backdrop, index) => ({
      ...backdrop,
      rawZindex: index === 0 ? 3 : 0,
    })
  );

  const woolworthsblackferns = Object.assign({}, weetbixstatattack2023base);
  woolworthsblackferns.backdrops = config.backdrops.map((backdrop, index) => ({
    ...backdrop,
    rawZindex: index === 0 ? 0 : 3,
  }));
  const woolworths = Object.assign({}, weetbixstatattack2023base);
  woolworths.backdrops = config.backdrops.map((backdrop, index) => ({
    ...backdrop,
    rawZindex: index === 0 ? 0 : 3,
  }));
  const additionReason = {};
  let nameTextColour = "white";
  if (posterName.endsWith("SuperFan")) {
    const posterNameTextColour = {
      bluesSuperFan: "white",
      chiefsSuperFan: "#ffc528",
      hurricanesSuperFan: "#ffda32",
      crusadersSuperFan: "#ff0000",
      highlandersSuperFan: "#ffc211",
    };
    nameTextColour = posterNameTextColour[posterName];
    if (config.posterTexts.additionalReason) {
      const splitReason = splitStringIntoChunks(
        config.posterTexts.additionalReason
      );
      splitReason[0] = `"${toSentanceCase(splitReason[0])}`;
      splitReason[splitReason.length - 1] = `${
        splitReason[splitReason.length - 1]
      }"`;
      const reasonTextColour = {
        "hurricanesSuperFan-1": "black",
        "highlandersSuperFan-1": "#11203D",
      };
      splitReason.map((reasonLine, index) => {
        const textColour = reasonTextColour[`${posterName}-${index}`]
          ? reasonTextColour[`${posterName}-${index}`]
          : "white";
        additionReason[`reason${index}`] = {
          id: `reason${index}`,
          fill: textColour,
          fontFamily: "Circular Pro Black",
          fontStyle: "bold",
          fontSize: 0.043,
          rawZindex: 4,
          x: 0.26,
          y: 0.7145 + index * 0.06,
          text: reasonLine,
          rotation: -6.5,
        };
      });
    }
  }
  const base2degreesSuperFanTemplate = {
    backdrops: {},
    posterName: {
      bottomY: 0,
      paddingY: 0,
      maxWidth: 0.9,
      centerX: false,
      container: {
        y: 0.932,
        height: 0.107,
        x: 0.056,
      },
      firstAndLastName: {
        text: `${toStartCase(config.firstName)} ${toStartCase(
          config.lastName
        )}`,
        fontFamily: "Circular Pro Bold",
        fontStyle: "bold",
        fontSize: 0.05,
        rawZindex: 4,
        fill: nameTextColour,
        maxFontSize: 0.05,
        x: 0.8,
        rotation: -90,
      },
    },
    posterTexts: additionReason,
    movableImage: {
      imageUrl:
        "https://res.cloudinary.com/dglcgx45v/image/upload/v1616452695/h3nt2bn26we8sbf7e2ju.png",
      rawZindex: 1,
      ...(config.movableImage && { ...config.movableImage }),
    },
  };
  const bluesSuperFan = Object.assign({}, base2degreesSuperFanTemplate);
  bluesSuperFan.backdrops = config.backdrops.map((backdrop, index) => ({
    ...backdrop,
    rawZindex: index === 0 ? 0 : 2,
  }));
  const chiefsSuperFan = Object.assign({}, base2degreesSuperFanTemplate);
  chiefsSuperFan.backdrops = config.backdrops.map((backdrop, index) => ({
    ...backdrop,
    rawZindex: index === 0 ? 0 : 2,
  }));
  const hurricanesSuperFan = Object.assign({}, base2degreesSuperFanTemplate);
  hurricanesSuperFan.backdrops = config.backdrops.map((backdrop, index) => ({
    ...backdrop,
    rawZindex: index === 0 ? 2 : 0,
  }));
  const crusadersSuperFan = Object.assign({}, base2degreesSuperFanTemplate);
  crusadersSuperFan.backdrops = config.backdrops.map((backdrop, index) => ({
    ...backdrop,
    rawZindex: index === 0 ? 2 : 0,
  }));
  const highlandersSuperFan = Object.assign({}, base2degreesSuperFanTemplate);
  highlandersSuperFan.backdrops = config.backdrops.map((backdrop, index) => ({
    ...backdrop,
    rawZindex: index === 0 ? 2 : 0,
  }));

  let firstNameOffsetY = -20;
  if (config.lastName && config.lastName.length > 14) {
    if (config.lastName.length > 18) {
      firstNameOffsetY = -8;
    } else {
      firstNameOffsetY = -15;
    }
  }
  const weetbixstatattack2024Base = {
    backdrops: {},
    posterName: {
      bottomY: 0.182,
      paddingY: 0,
      maxWidth: 0.68,
      firstName: {
        text: config.firstName,
        fontFamily: "Atomic Marker",
        fontStyle: "normal",
        fontSize: 0.07,
        fill: "red",
        stroke: "red",
        strokeWidth: 0,
        x: 0.032,
        rawZindex: 5,
        maxFontSize: 0.07,
        rotation: 0,
        skewX: 0,
        offsetY: firstNameOffsetY,
      },
      lastName: {
        text: config.lastName.toUpperCase(),
        fontFamily: "Flexible H400 W200",
        fontStyle: "normal",
        fontSize: 0.186,
        fill: "white",
        stroke: "white",
        strokeWidth: 0,
        x: 0.032,
        rawZindex: 4,
        maxFontSize: 0.186,
        rotation: 0,
        skewX: 0,
        offsetY: 25,
      },
    },
    posterTexts: {
      yourAge: {
        id: "yourAge",
        text: config.posterTexts.yourAge,
        fill: "white",
        fontFamily: "Cutmark Variable",
        fontStyle: "bold",
        fontSize: 0.08,
        rawZindex: 4,
        x: 0.735,
        y: 0.282,
        rotation: 0,
        skewX: 0,
        align: "center",
        width: 0.2321,
      },
      height: {
        id: "height",
        text: config.posterTexts.height,
        fill: "white",
        fontFamily: "Cutmark Variable",
        fontStyle: "bold",
        fontSize: 0.08,
        rawZindex: 4,
        x: 0.735,
        y: 0.4,
        rotation: 0,
        skewX: 0,
        align: "center",
        width: 0.2321,
      },
      weetbix: {
        id: "eaten",
        text: config.posterTexts.weetBixEaten,
        fill: "white",
        fontFamily: "Cutmark Variable",
        fontStyle: "bold",
        fontSize: 0.09,
        rawZindex: 4,
        x: 0.735,
        y: 0.89,
        rotation: 0,
        skewX: 0,
        align: "center",
        width: 0.2321,
      },
      weetbixStyle: {
        id: "weetbixStyle",
        text: config.posterTexts.weetBixStyle?.toUpperCase(),
        fill: "white",
        fontFamily: "Cutmark Variable",
        fontStyle: "bold",
        fontSize:
          config.posterTexts.weetBixStyle?.toUpperCase().length > 4
            ? 0.03
            : 0.06,
        rawZindex: 4,
        x: 0.735,
        y: 0.774,
        rotation: 0,
        skewX: 0,
        align: "center",
        width: 0.2321,
      },
    },
    movableImage: {
      rawZindex: 1,
      ...(config.movableImage && { ...config.movableImage }),
    },
  };

  const weetbixstatattackDazzlingdynamoOffence = Object.assign(
    {},
    weetbixstatattack2024Base
  );
  weetbixstatattackDazzlingdynamoOffence.backdrops = config.backdrops.map(
    (backdrop, index) => ({
      ...backdrop,
      rawZindex: index === 0 ? 2 : 0,
    })
  );

  const weetbixAllBlacksSideStep = Object.assign({}, weetbixstatattack2024Base);
  weetbixAllBlacksSideStep.backdrops = config.backdrops.map(
    (backdrop, index) => ({
      ...backdrop,
      rawZindex: index === 0 ? 2 : 0,
    })
  );

  const weetbixAllBlacksIntercept = Object.assign(
    {},
    weetbixstatattack2024Base
  );
  weetbixAllBlacksIntercept.backdrops = config.backdrops.map(
    (backdrop, index) => ({
      ...backdrop,
      rawZindex: index === 0 ? 2 : 0,
    })
  );

  const weetbixAllBlacksChipAndChase = Object.assign(
    {},
    weetbixstatattack2024Base
  );
  weetbixAllBlacksChipAndChase.backdrops = config.backdrops.map(
    (backdrop, index) => ({
      ...backdrop,
      rawZindex: index === 0 ? 2 : 0,
    })
  );

  const weetbixAllBlacksSteal = Object.assign({}, weetbixstatattack2024Base);
  weetbixAllBlacksSteal.backdrops = config.backdrops.map((backdrop, index) => ({
    ...backdrop,
    rawZindex: index === 0 ? 2 : 0,
  }));

  const weetbixAllBlacksOffload = Object.assign({}, weetbixstatattack2024Base);
  weetbixAllBlacksOffload.backdrops = config.backdrops.map(
    (backdrop, index) => ({
      ...backdrop,
      rawZindex: index === 0 ? 2 : 0,
    })
  );

  const weetbixAllBlacksFend = Object.assign({}, weetbixstatattack2024Base);
  weetbixAllBlacksFend.backdrops = config.backdrops.map((backdrop, index) => ({
    ...backdrop,
    rawZindex: index === 0 ? 2 : 0,
  }));
  const weetbixAllBlacksBallAndTackle = Object.assign(
    {},
    weetbixstatattack2024Base
  );
  weetbixAllBlacksBallAndTackle.backdrops = config.backdrops.map(
    (backdrop, index) => ({
      ...backdrop,
      rawZindex: index === 0 ? 2 : 0,
    })
  );
  const weetbixAllBlacksChargedown = Object.assign(
    {},
    weetbixstatattack2024Base
  );
  weetbixAllBlacksChargedown.backdrops = config.backdrops.map(
    (backdrop, index) => ({
      ...backdrop,
      rawZindex: index === 0 ? 2 : 0,
    })
  );

  const weetbixBlackFernsSideStep = Object.assign(
    {},
    weetbixstatattack2024Base
  );
  weetbixBlackFernsSideStep.backdrops = config.backdrops.map(
    (backdrop, index) => ({
      ...backdrop,
      rawZindex: index === 0 ? 2 : 0,
    })
  );

  const weetbixBlackFernsIntercept = Object.assign(
    {},
    weetbixstatattack2024Base
  );
  weetbixBlackFernsIntercept.backdrops = config.backdrops.map(
    (backdrop, index) => ({
      ...backdrop,
      rawZindex: index === 0 ? 2 : 0,
    })
  );

  const weetbixBlackFernsChipAndChase = Object.assign(
    {},
    weetbixstatattack2024Base
  );
  weetbixBlackFernsChipAndChase.backdrops = config.backdrops.map(
    (backdrop, index) => ({
      ...backdrop,
      rawZindex: index === 0 ? 2 : 0,
    })
  );

  const weetbixBlackFernsSteal = Object.assign({}, weetbixstatattack2024Base);
  weetbixBlackFernsSteal.backdrops = config.backdrops.map(
    (backdrop, index) => ({
      ...backdrop,
      rawZindex: index === 0 ? 2 : 0,
    })
  );

  const weetbixBlackFernsOffload = Object.assign({}, weetbixstatattack2024Base);
  weetbixBlackFernsOffload.backdrops = config.backdrops.map(
    (backdrop, index) => ({
      ...backdrop,
      rawZindex: index === 0 ? 2 : 0,
    })
  );

  const weetbixBlackFernsFend = Object.assign({}, weetbixstatattack2024Base);
  weetbixBlackFernsFend.backdrops = config.backdrops.map((backdrop, index) => ({
    ...backdrop,
    rawZindex: index === 0 ? 2 : 0,
  }));
  const weetbixBlackFernsBallAndTackle = Object.assign(
    {},
    weetbixstatattack2024Base
  );
  weetbixBlackFernsBallAndTackle.backdrops = config.backdrops.map(
    (backdrop, index) => ({
      ...backdrop,
      rawZindex: index === 0 ? 0 : 2,
    })
  );
  const weetbixBlackFernsChargeDown = Object.assign(
    {},
    weetbixstatattack2024Base
  );
  weetbixBlackFernsChargeDown.backdrops = config.backdrops.map(
    (backdrop, index) => ({
      ...backdrop,
      rawZindex: index === 0 ? 2 : 0,
    })
  );

  const tiptopBakery = {
    backdrops: config.backdrops.map((backdrop, index) => ({
      ...backdrop,
      rawZindex: index === 0 ? 0 : index === 1 ? 2 : 3,
    })),
    posterName: {
      bottomY: 0.8,
      paddingY: 0.01,
      maxWidth: 0.75,
      firstName: {
        text: config.firstName.toUpperCase(),
        fontFamily: "Brandon Grotesque",
        fontStyle: "normal",
        fontSize: 0.07,
        fill: "white",
        stroke: "white",
        strokeWidth: 0,
        x: 0.096,
        rawZindex: 4,
        maxFontSize: 0.07,
        rotation: 0,
        skewX: 0,
      },
      lastName: {
        text: config.lastName.toUpperCase(),
        fontFamily: "Brandon Grotesque",
        fontStyle: "normal",
        fontSize: 0.07,
        fill: "white",
        stroke: "white",
        strokeWidth: 0,
        x: 0.096,
        rawZindex: 4,
        maxFontSize: 0.07,
        rotation: 0,
        skewX: 0,
      },
    },
    posterTexts: {
      position: {
        id: "position",
        text: config.posterTexts.position,
        fill: "white",
        fontFamily: "Brandon Grotesque",
        fontStyle: "bold",
        fontSize: 0.02,
        rawZindex: 4,
        x: 0.218,
        y: 0.819,
        rotation: 0,
        skewX: 0,
      },
      topSkill: {
        id: "topSkill",
        text: config.posterTexts.topSkill,
        fill: "white",
        fontFamily: "Brandon Grotesque",
        fontStyle: "bold",
        fontSize: 0.02,
        rawZindex: 4,
        x: 0.218,
        y: 0.848,
        rotation: 0,
        skewX: 0,
      },
      favouriteTeam: {
        id: "favouriteTeam",
        text: config.posterTexts.favouriteTeam,
        fill: "white",
        fontFamily: "Brandon Grotesque",
        fontStyle: "bold",
        fontSize: 0.02,
        rawZindex: 4,
        x: 0.295,
        y: 0.876,
        rotation: 0,
        skewX: 0,
      },
    },
    movableImage: {
      rawZindex: 1,
      ...(config.movableImage && { ...config.movableImage }),
    },
  };

  const oppo = {
    backdrops: config.backdrops.map((backdrop, index) => ({
      ...backdrop,
      rawZindex: index === 0 ? 2 : 3,
    })),
    posterName: {
      bottomY: 0.8,
      paddingY: 0.01,
      maxWidth: 0.75,
      firstName: {
        text: "",
        fontFamily: "",
        fontStyle: "normal",
        fontSize: 0.07,
        fill: "white",
        stroke: "white",
        strokeWidth: 0,
        x: 0.096,
        rawZindex: 4,
        maxFontSize: 0.07,
        rotation: 0,
        skewX: 0,
      },
      lastName: {
        text: "",
        fontFamily: "",
        fontStyle: "normal",
        fontSize: 0.07,
        fill: "white",
        stroke: "white",
        strokeWidth: 0,
        x: 0.096,
        rawZindex: 4,
        maxFontSize: 0.07,
        rotation: 0,
        skewX: 0,
      },
    },
    posterTexts: {},
    movableImage: {
      rawZindex: 1,
      ...(config.movableImage && { ...config.movableImage }),
    },
  };

  const posterParams = {
    athleteprofile: {
      backdrops: config.backdrops,
      posterName: {
        bottomY: 0.765,
        paddingY: 0,
        maxWidth: 0.7,
        firstName: {
          text: config.firstName.toUpperCase(),
          fontFamily: "acumin-pro-extra-condensed",
          fontStyle: "italic",
          fontSize: 0.1,
          fill: "white",
          x: 0.1,
          skewX: -0.215,
          rotation: -11,
          rawZindex: 3,
          maxFontSize: 0.14,
        },
        lastName: {
          text: config.lastName.toUpperCase(),
          fontFamily: "acumin-pro-extra-condensed",
          fontStyle: "italic",
          fontSize: 0.1,
          stroke: "white",
          fill: "transparent",
          strokeWidth: 2,
          x: 0.2,
          skewX: -0.215,
          rotation: -11,
          rawZindex: 3,
          maxFontSize: 0.14,
        },
      },
      posterTexts: {
        age: {
          id: "age",
          text: "16",
          fill: "#dcaf48",
          fontFamily: "acumin-pro-extra-condensed",
          fontStyle: "italic",
          fontSize: 0.0585,
          skewX: -0.215,
          rotation: -11,
          rawZindex: 3,
          x: 0.2,
          y: 0.855,
          ...(config.posterTexts.age && { ...config.posterTexts.age }),
        },
        event: {
          id: "event",
          fill: "#dcaf48",
          fontFamily: "acumin-pro-extra-condensed",
          fontStyle: "italic",
          fontSize: 0.036,
          skewX: -0.215,
          rotation: -11,
          rawZindex: 3,
          x: 0.39,
          y: 0.795,
          ...(config.posterTexts.event && { ...config.posterTexts.event }),
        },
        favPart: {
          id: "favPart",
          fill: "#dcaf48",
          fontFamily: "acumin-pro-extra-condensed",
          fontStyle: "italic",
          fontSize: 0.0468,
          skewX: -0.215,
          rotation: -11,
          rawZindex: 3,
          x: 0.475,
          y: 0.83,
          ...(config.posterTexts.favPart && { ...config.posterTexts.favPart }),
        },
      },
      movableImage: {
        imageUrl:
          "https://res.cloudinary.com/dglcgx45v/image/upload/v1616452695/h3nt2bn26we8sbf7e2ju.png",
        rawZindex: 1,
        ...(config.movableImage && { ...config.movableImage }),
      },
    },
    weetbixstatattack: weetbixStatattackTemplate,
    northshorerugby: northShoreTemplate,
    northshorestaging: northShoreStagingTemplate,
    birkenheadunited: {
      backdrops: config.backdrops.map((backdrop, index) => ({
        ...backdrop,
        rawZindex: index === 0 ? 0 : 3,
      })),
      posterName: {
        bottomY: 0.68,
        paddingY: 0.02,
        maxWidth: 0.7,
        centerX: false,
        firstName: {
          text: config.firstName.toUpperCase(),
          fontFamily: "DrukWide",
          fontSize: 0.02,
          fill: "transparent",
          stroke: "white",
          x: 0.15,
          skewX: -0.01,
          rawZindex: 4,
          maxFontSize: 0.07,
        },
        lastName: {
          text: config.lastName.toUpperCase(),
          fontFamily: "DrukWide",
          fontSize: 0.03,
          strokeWidth: 2,
          x: 0.13,
          skewX: -0.01,
          rawZindex: 4,
          maxFontSize: 0.11,
          fillPatternImageUrl: textureFontImageUrl,
          fillPriority: "pattern",
          fillPatternScale: { x: 1, y: 1 },
          fillPatternRepeat: "no-repeat",
        },
      },
      posterTexts: {
        age: {
          id: "age",
          fill: "black",
          fontFamily: "DrukWide",
          fontSize: 0.013,
          rawZindex: 4,
          x: 0.29,
          y: 0.76,
          text: config.posterTexts.age,
        },
        playerNumber: {
          id: "playerNumber",
          fill: "white",
          fontFamily: "GlossAndBloom",
          fontSize: 0.1,
          rawZindex: 4,
          x: 0.15,
          y: 0.3,
          text: `#${config.posterTexts.playerNumber}`,
        },
        position: {
          id: "position",
          fill: "black",
          fontFamily: "DrukWide",
          fontSize: 0.013,
          rawZindex: 4,
          x: 0.69,
          y: 0.76,
          text: config.posterTexts.position,
        },
        team: {
          id: "team",
          fill: "black",
          fontFamily: "DrukWide",
          fontSize: 0.013,
          rawZindex: 4,
          x: 0.28,
          y: 0.8223,
          text: config.posterTexts.team,
        },
        topSkill: {
          id: "topSkill",
          fill: "black",
          fontFamily: "DrukWide",
          fontSize: 0.013,
          rawZindex: 4,
          x: 0.69,
          y: 0.8223,
          text: config.posterTexts.topSkill,
        },
      },
      movableImage: {
        imageUrl:
          "https://res.cloudinary.com/dglcgx45v/image/upload/v1616452695/h3nt2bn26we8sbf7e2ju.png",
        rawZindex: 2,
        ...(config.movableImage && { ...config.movableImage }),
      },
    },
    "test-poster": weetbixStatattackTemplate,
    "2degreesBlues": bluesTemplate,
    blues: bluesTemplate,
    bluesLive: bluesLiveTemplate,
    highlanders: highlandersTemplate,
    chiefs: chiefsTemplate,
    Hurricanes: hurricanesTemplate,
    crusaders: crusadersTemplate,
    crusadersLive: crusadersLiveTemplate,
    HurricanesPOUA: hurricanesPOUATemplate,
    manawa: manawaTemplate,
    matatu: matatuTemplate,
    MtMaunganui: mtMaunganuiTemplate,
    stadium: stadium,
    returningathlete: returningathlete,
    roaming: aimsGamesRoamingTemplate,
    roaminggreen: aimsGamesRoamingGreenTemplate,
    ToyotaGR010Hybrid: toyotaGr010Template,
    GRYARISRally1HYBRID: toyotaGrarisTemplate,
    GRSupra: toyotaGrSupraTemplate,
    GRCorolla: toyotaGrCorollaTemplate,
    ft60: toyotaft60Template,
    Maccas360Challenge: mcdonalds,
    weetbixstatattack2023: weetbixstatattack2023,
    weetbixstatattackblackferns: weetbixstatattackblackferns,
    woolworthsblackferns: woolworthsblackferns,
    woolworths: woolworths,
    bluesSuperFan: bluesSuperFan,
    chiefsSuperFan: chiefsSuperFan,
    hurricanesSuperFan: hurricanesSuperFan,
    crusadersSuperFan: crusadersSuperFan,
    highlandersSuperFan: highlandersSuperFan,
    "weetbixstatattack-dazzlingdynamo-offence": weetbixstatattackDazzlingdynamoOffence,
    "statattack-allblacks-dazzlingdynamo-sidestep": weetbixAllBlacksSideStep,
    "statattack-allblacks-superspeedster-intercept": weetbixAllBlacksIntercept,
    "statattack-allblacks-superspeedster-chipandchase": weetbixAllBlacksChipAndChase,
    "statattack-allblacks-persistentpowerhouse-steal": weetbixAllBlacksSteal,
    "statattack-allblacks-persistentpowerhouse-offload": weetbixAllBlacksOffload,
    "statattack-allblacks-daringdefender-fend": weetbixAllBlacksFend,
    "statattack-allblacks-daringdefender-BallAndAllTackle": weetbixAllBlacksBallAndTackle,
    "statattack-allblacks-dazzlingdynamo-chargedown": weetbixAllBlacksChargedown,
    "statattack-blackferns-dazzlingdynamo-sidestep": weetbixBlackFernsSideStep,
    "statattack-blackferns-superspeedster-intercept": weetbixBlackFernsIntercept,
    "statattack-blackferns-superspeedster-chipandchase": weetbixBlackFernsChipAndChase,
    "statattack-blackferns-persistentpowerhouse-steal": weetbixBlackFernsSteal,
    "statattack-blackferns-persistentpowerhouse-offload": weetbixBlackFernsOffload,
    "statattack-blackferns-daringdefender-fend": weetbixBlackFernsFend,
    "statattack-blackferns-daringdefender-BallAndAllTackle": weetbixBlackFernsBallAndTackle,
    "statattack-blackferns-dazzlingdynamo-chargedown": weetbixBlackFernsChargeDown,
    TipTopBakery: tiptopBakery,
    oppo: oppo,
    MtMaunganui2024: mtMaunganui2024Template,
    stadium2024: stadium2024,
    returningathlete2024: returningathlete2024,
  };

  if (!posterParams[posterName]) {
    throw new Error(`Poster Config for ${posterName} not found`);
  }

  return posterParams[posterName];
};

function toStartCase(input) {
  return input.replace(/\w\S*/g, (txt: string) => {
    return txt.charAt(0).toUpperCase() + txt.substring(1);
  });
}

function toSentanceCase(sentance: string) {
  return sentance.charAt(0).toUpperCase() + sentance.substring(1);
}

function splitStringIntoChunks(sentance: string): string[] {
  const regex = /\s*(?:(\S{19})|([\s\S]{1,19})(?!\S))/g;
  const result = sentance.replace(regex, function ($0, $1, $2) {
    return $1 ? $1 + "__" : $2 + "__";
  });
  return result.split("__").filter((line) => line !== "");
}
