import { addSeconds, isBefore, parseISO } from "date-fns";
import { Maybe } from "./types";

export type AccessToken = { token: string; expiry: Date };

export const getToken = () => getAccessToken()?.token;
const getAccessToken = (): Maybe<AccessToken> => {
  const accessToken = localStorage.getItem("authToken");
  if (accessToken) return JSON.parse(accessToken);
};
export const setAccessToken = (token: AccessToken) => {
  localStorage.setItem("authToken", JSON.stringify(token));
};

export const isAccessTokenExpired = () => {
  const accessToken = getAccessToken();
  if (accessToken) {
    return isBefore(
      addSeconds(Date.now(), 10),
      parseISO((accessToken.expiry as unknown) as string)
    );
  }
  return false;
};
