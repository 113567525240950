import React from "react";
import { Nullable, UnboxPromise } from "../../types";
import PosterMaker from "../posterMaker";
import AdjustmentPanel, { AdjustmentMode } from "./AdjustmentPanel";
import { FaCamera } from "react-icons/fa";
import ToolbarAction from "./ToolbarAction";
import Button from "../../shared/Button";
import { HiOutlineAdjustments } from "react-icons/hi";
import { Poster_Description } from "../../shared/Constants";

interface Props {
  poster: Nullable<UnboxPromise<ReturnType<typeof PosterMaker>>>;
  onFinish: () => void;
  onRetake: () => void;
}

const AdjustmentToolbar: React.FC<Props> = ({ poster, onFinish, onRetake }) => {
  const [adjustmentMode, setAdjustmentMode] = React.useState<
    Nullable<AdjustmentMode>
  >(null);

  let activeAdjustmentPanel: React.ReactNode = null;

  if (adjustmentMode === "Adjust" && poster) {
    activeAdjustmentPanel = (
      <AdjustmentPanel
        onClose={() => {
          setAdjustmentMode(null);
        }}
        onChangeBrightness={(value: number) => {
          poster.handlers.brightness.onChange(value);
        }}
        onChangeContrast={(value: number) => {
          poster.handlers.contrast.onChange(value);
        }}
        getBrightnessValue={poster.handlers.brightness.value}
        getContrastValue={poster.handlers.contrast.value}
        brightnessRange={poster.handlers.brightness.range}
        contrastRange={poster.handlers.contrast.range}
      />
    );
  }

  return (
    <div className="flex px-6 md:px-10 my-3 h-17">
      {poster && (
        <>
          {activeAdjustmentPanel}
          <div className="flex justify-end flex-[0.4] mr-3">
            <ToolbarAction
              icon={<FaCamera />}
              text="Retake"
              onClick={() => {
                onRetake();
              }}
            />
            <ToolbarAction
              icon={<HiOutlineAdjustments />}
              text="Adjust"
              onClick={() => {
                setAdjustmentMode("Adjust");
              }}
            />
          </div>

          <Button
            theme="secondary"
            className="flex-[0.6] !text-sm !md:text-base"
            onClick={onFinish}
          >
            Finish {Poster_Description}
          </Button>
        </>
      )}
    </div>
  );
};

export default AdjustmentToolbar;
