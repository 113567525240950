const Prefix = "additional";

export const addAdditionalPrefix = (data) => {
  const prefixedObject = {};
  for (const key in data) {
    if (Object.hasOwnProperty.call(data, key)) {
      prefixedObject[Prefix + key.charAt(0).toUpperCase() + key.slice(1)] =
        data[key];
    }
  }
  return prefixedObject;
};

export const removeAdditionalPrefix = (data): {} | undefined => {
  let result: {} | undefined = undefined;
  for (const key in data) {
    if (key.startsWith(Prefix)) {
      if (result === undefined) {
        result = {};
      }
      const newKey = key
        .replace(Prefix, "")
        .replace(/([a-z])([A-Z])/g, "$1_$2")
        .toLowerCase();
      result[newKey] = data[key];
    }
  }
  return result;
};
