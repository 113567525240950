import React from "react";

import { Nullable } from "../types";
import PosterMaker from "./posterMaker";
import useComponentSize from "@rehooks/component-size";
import { basepath, useGetCurrentPoster } from "./posterHooks";
import { getPosterParams } from "./posterHelpers";

const PosterExport: React.FC = () => {
  const posterMaker = React.useRef<Nullable<any>>(null);
  const ref = React.useRef(null);
  const [image, setImage] = React.useState<Nullable<HTMLImageElement>>(null);
  const [layers, setLayers] = React.useState<
    Nullable<{ zIndex: number; layer: HTMLImageElement }[]>
  >(null);

  const size = useComponentSize(ref);
  const queryParams = new URLSearchParams(window.location.search);
  const posterParamsString = queryParams.get("posterParams") || "";
  const posterParams = JSON.parse(posterParamsString);
  console.log(posterParams);
  const { poster } = useGetCurrentPoster();

  React.useEffect(() => {
    const { width, height } = size;

    console.log(size);

    const exportPoster = async () => {
      // If there are no backdrops, there is no poster
      if (poster?.backdrops?.nodes?.length) {
        const posterConfig = {
          firstName: posterParams?.firstName ?? "",
          lastName: posterParams?.lastName ?? "",
          posterTexts: posterParams?.posterTexts,
          backdrops: poster.backdrops.nodes.map((backdrop) => ({
            rawZindex: 0,
            imageUrl: backdrop?.image?.url!,
          })),
          movableImage: posterParams.fan,
        };

        posterMaker.current = await PosterMaker({
          canvasId: "poster-container",
          containerDimensions: {
            height,
            width,
          },
          ...getPosterParams(basepath, posterConfig),
        });

        const image = await posterMaker.current.export(7.5);
        const layers = await posterMaker.current.exportLayers(7.5);
        setImage(image);
        setLayers(layers);
      }
    };
    if (height && width) {
      exportPoster();
    }
  }, [size, poster]);
  return (
    <div className="w-full h-full">
      {image ? (
        <img id="exported-poster" src={image.src} />
      ) : (
        <div
          id="poster-container"
          ref={ref}
          className="flex h-full w-full mt-10 invisible"
        />
      )}
      {layers?.map((layer) => (
        <img
          key={layer.zIndex}
          id={`layer-${layer.zIndex}`}
          src={layer.layer.src}
        />
      ))}
    </div>
  );
};
export default PosterExport;
