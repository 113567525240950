import React from "react";

interface Props {
  children: JSX.Element | JSX.Element[];
}
const Layout: React.FC<Props> = ({ children }: Props) => {
  return (
    <div className="flex flex-col flex-1 bg-whisper overflow-hidden relative">
      {children}
    </div>
  );
};

export default Layout;
