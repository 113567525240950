import React from "react";
import { motion, AnimatePresence } from "framer-motion";

import * as icons from "../assets/images/icons";

interface Props {
  height?: number;
  width?: number;
  show: boolean;
}
const PinchToZoomModal: React.FC<Props> = ({ height, width, show }) => {
  return (
    <AnimatePresence>
      {show && height && width && (
        <motion.div
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          exit={{
            opacity: 0.8,
          }}
          style={{
            height,
            width,
          }}
          className="absolute bg-black bg-opacity-60 rounded-lg flex items-center justify-center z-50"
        >
          <img src={icons.pinchIconUrl} className="h-30 animate-pinch-zoom" />
        </motion.div>
      )}
    </AnimatePresence>
  );
};
export default PinchToZoomModal;
