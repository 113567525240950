import Konva from "konva";
import groupBy from "lodash/groupBy";

import { TextConfig } from "konva/types/shapes/Text";
import { Node } from "konva/types/Node";
import { loadImage } from "../utils";
import { loadZoomScripts } from "./posterHooks";

const mobileDevice = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
  navigator.userAgent
);

export interface PosterFieldConfig {
  backdrops: Backdrop[];
  posterTexts: Record<string, PosterText>;
  posterName: PosterName | PosterNameSplit;
  movableImage: MovableImage;
}

interface PosterMakerParams extends PosterFieldConfig {
  canvasId: string;
  containerDimensions: Dimensions;
}

interface MovableImage {
  imageUrl: string;
  x?: number;
  y?: number;
  height?: number;
  width?: number;
  rawZindex: number;
  brightness?: number;
  contrast?: number;
  rotation?: number;
  scale?: number;
  scaleBy?: "width" | "height" | undefined;
}

interface Backdrop {
  imageUrl: string;
  rawZindex: number;
}

interface PosterText extends TextConfig {
  fontSize: number;
  y: number;
  x: number;
  rawZindex: number;
  text: string;
}

interface PosterNameSplit {
  bottomY: number;
  paddingY: number;
  maxWidth: number;
  centerX: boolean;
  // Center the text within a container. Useful when you need to fit within an element of the poster.
  firstName: {
    fontSize: number;
    x: number;
    maxFontSize: number;
    maxWidth?: number;
  } & TextConfig;
  lastName: {
    fontSize: number;
    x: number;
    maxFontSize: number;
    maxWidth?: number;
  } & TextConfig;
}

interface PosterName {
  maxWidth: number;
  centerX: boolean;
  // Center the text within a container. Useful when you need to fit within an element of the poster.
  container: {
    y: number;
    height: number;
    x: number; //Used if centerX is false
  };
  firstAndLastName: {
    fontSize: number;
    x: number;
    maxFontSize: number;
  } & TextConfig;
}

interface Dimensions {
  height: number;
  width: number;
}

type Element = Konva.Image | Konva.Text | Konva.Group;

function getDistance(touches) {
  const x1 = touches[0].clientX;
  const y1 = touches[0].clientY;
  const x2 = touches[1].clientX;
  const y2 = touches[1].clientY;
  return Math.sqrt((x1 - x2) ** 2 + (y1 - y2) ** 2);
}

let stage: Konva.Stage;

const layer = new Konva.Layer();

const PosterMaker = async ({
  canvasId,
  containerDimensions,
  backdrops,
  posterTexts,
  posterName,
  movableImage,
}: PosterMakerParams) => {
  const elements: Element[] = [];

  await loadZoomScripts();

  //@ts-ignore
  TouchEmulator();
  //@ts-ignore
  Konva.hitOnDragEnabled = true;
  //@ts-ignore
  Konva.captureTouchEventsEnabled = true;

  const backdropImages = await createBackdropImages(
    backdrops,
    containerDimensions
  );

  /**
   * The size of the poster is the size of the first backdrop image,
   * Which is scaled to fit the container dimensions
   */
  const posterDimensions = {
    height: backdropImages[0].height(),
    width: backdropImages[0].width(),
  };

  stage = new Konva.Stage({
    container: canvasId,
    ...posterDimensions,
  });

  stage.destroyChildren();
  layer.destroyChildren();

  const canvas = layer.getCanvas();
  canvas._canvas.className = "rounded-lg";

  elements.push(...backdropImages);

  Object.values(posterTexts).forEach((text) => {
    let newWidth: number | undefined = undefined;
    if (text.width !== undefined && text.width != null) {
      newWidth = text.width * posterDimensions.width;
    }
    const textElement = new Konva.Text({
      ...text,
      fontSize: text.fontSize * posterDimensions.height,
      y: text.y * posterDimensions.height,
      x: text.x * posterDimensions.width,
      width: newWidth,
    });

    elements.push(textElement);
  });

  // Movable image such as a fan
  const movableImageElement = await createMovableImage(
    movableImage,
    posterDimensions,
    layer
  );

  elements.push(movableImageElement.movableImage);

  const nameElements = await createPosterName(posterName, posterDimensions);

  elements.push(...nameElements);

  /**
   * Group elements by zIndex
   * Create a new Konva Group for each zIndex value
   * add to the Konva Layer
   */
  console.log(
    Object.entries(
      groupBy(
        elements,
        (element: Element) => element.attrs.rawZindex
      ) as Record<string, Element[]>
    )
  );

  Object.entries(
    groupBy(elements, (element: Element) => element.attrs.rawZindex) as Record<
      string,
      Element[]
    >
  ).forEach(([zIndex, elements]) => {
    const group = new Konva.Group({
      x: 0,
      y: 0,
      height: posterDimensions.height,
      width: posterDimensions.width,
      rawZindex: parseInt(zIndex, 10),
      zIndex,
    });
    elements.forEach((element) => {
      group.add(element);
    });

    layer.add(group);
  });

  layer.add(movableImageElement.transformer);
  stage.add(layer);
  layer.batchDraw();

  const getExportableLayers = () =>
    layer.children
      .toArray()
      .filter(
        (layer) =>
          !backdrops
            .map((backdrop) => backdrop.rawZindex)
            .includes(layer.attrs.rawZindex)
      );

  return {
    handlers: movableImageElement.handlers,
    export: (pixelRatio = 3) => {
      removeExcludedChildren(layer);

      return new Promise<HTMLImageElement>((resolve) => {
        stage.toImage({
          pixelRatio,
          mimeType: "image/jpeg",
          quality: 1,
          callback: (img) => resolve(img),
        });
      });
    },
    exportLayers: async (pixelRatio = 1) => {
      removeExcludedChildren(layer);
      const exportLayers = await Promise.all(
        getExportableLayers().map((exportGroup) => {
          const exportDiv = document.createElement("div");
          const exportStage = new Konva.Stage({
            container: exportDiv,
            ...posterDimensions,
          });
          const exportLayer = new Konva.Layer();
          exportLayer.add(exportGroup);

          exportStage.add(exportLayer);
          exportLayer.batchDraw();

          return new Promise<{ zIndex: number; layer: HTMLImageElement }>(
            (resolve) => {
              exportStage.toImage({
                pixelRatio,
                mimeType: "image/png",
                quality: 1,
                callback: (img) => {
                  resolve({
                    zIndex: exportGroup.attrs.rawZindex,
                    layer: img,
                  });
                  exportStage.destroy();
                },
              });
            }
          );
        })
      );
      return exportLayers;
    },

    exportParams: () => {
      return {
        fanParams: movableImageElement.getParams(),
        exportLayers: getExportableLayers().map(
          (exportGroup) => exportGroup.attrs.rawZindex
        ),
      };
    },
  };
};

/**
 * Any nodes marked as excluded will be removed before the stage
 * is exported
 */
const removeExcludedChildren = (layer: Konva.Layer) => {
  const allChildren: Node[] = [];
  const recursiveAdd = (el: Node) => {
    allChildren.push(el);
    const children = el.children;
    if (children) {
      for (let i = 0; i < children.length; i++) {
        recursiveAdd(children[i]);
      }
    }
  };

  recursiveAdd(layer);
  allChildren
    .filter((child) => child.attrs.excluded === true)
    .forEach((excluded) => {
      excluded.remove();
    });
};

interface MovableImageHandlers {
  brightness: {
    onChange: (brightness: number) => void;
    value: () => number;
    range: {
      max: number;
      min: number;
      step: number;
    };
  };
  contrast: {
    onChange: (contrast: number) => void;
    value: () => number;
    range: {
      max: number;
      min: number;
      step: number;
    };
  };
}

const calculateScale = (
  height,
  width,
  loadedMovableImage,
  movableImage
): number => {
  // The amount we want to scale the movable image down by after calculating the fit scale of the original image
  const movableImageScaleFactor = movableImage?.scale ?? 0.7;
  let scale =
    movableImage.height && movableImage.width
      ? (movableImage.height * height) / loadedMovableImage.height
      : Math.min(
          width / loadedMovableImage.width,
          height / loadedMovableImage.height
        ) * movableImageScaleFactor;
  if (movableImage?.scaleBy) {
    if (movableImage.scaleBy === "width") {
      scale = (width / loadedMovableImage.width) * movableImageScaleFactor;
    } else if (movableImage.scaleBy === "height") {
      scale = (height / loadedMovableImage.height) * movableImageScaleFactor;
    }
  }
  return scale;
};

const createMovableImage = async (
  movableImage: MovableImage,
  { height, width }: Dimensions,
  layer: Konva.Layer
) => {
  return new Promise<{
    movableImage: Konva.Group;
    // Get the state of the movable image at any one time (It could be adjusted)
    getParams: () => {
      x: number;
      y: number;
      height: number;
      width: number;
      brightness: number;
      contrast: number;
      rotation: number;
    };
    handlers: MovableImageHandlers;
    transformer: Konva.Transformer;
  }>(async (resolve) => {
    const loadedMovableImage = await loadImage(movableImage.imageUrl);

    const scale = calculateScale(
      height,
      width,
      loadedMovableImage,
      movableImage
    );
    const scaledHeight = loadedMovableImage.height * scale;
    const scaledWidth = loadedMovableImage.width * scale;

    const imageElement = new Konva.Image({
      draggable: true,
      image: loadedMovableImage,
      width: loadedMovableImage.width,
      height: loadedMovableImage.height,
      scale: {
        x: scale,
        y: scale,
      },
      filters: [Konva.Filters.Brighten, Konva.Filters.Contrast],
      rotation: movableImage.rotation || 0,
    });

    imageElement.cache({
      pixelRatio: 1,
    });

    // If the coordinates are passed through for a fan, use then. Otherwise center
    if (movableImage.x && movableImage.y) {
      imageElement.y(movableImage.y * height);
      imageElement.x(movableImage.x * width);
    } else {
      imageElement.y(height / 2 - scaledHeight / 2);
      imageElement.x(width / 2 - scaledWidth / 2);
    }

    const tr = new Konva.Transformer({
      anchorFill: "#F0F2F6",
      anchorStroke: "#191919",
      anchorCornerRadius: 20,
      excluded: true,
      anchorSize: 25,
      borderStroke: "#00D4DB",
      keepRatio: true,
      rotateEnabled: true,
      borderEnabled: true,
      enabledAnchors: ["top-left", "top-right", "bottom-right", "bottom-left"],
      resizeEnabled: !mobileDevice,
      centeredScaling: false,
    });

    tr.setNode(imageElement);

    imageElement.brightness(movableImage.brightness || 0);
    imageElement.contrast(movableImage.contrast || 0);
    tr.forceUpdate();

    let lastDist = 0;
    stage.on("touchstart", (e) => {
      const touches = e.evt.touches;
      if (touches.length < 2) {
        return;
      }
      imageElement.draggable(false);
      lastDist = getDistance(touches);
    });

    stage.on("touchmove", (e) => {
      const touches = e.evt.touches;
      if (touches.length < 2) {
        return;
      }
      if (!lastDist) {
        return;
      }

      const newDist = getDistance(touches);
      const scaleFactor = newDist / lastDist;
      const newScale = imageElement.scaleX() * scaleFactor;

      const oldHeight = imageElement.height() * imageElement.scaleY();
      const oldWidth = imageElement.width() * imageElement.scaleX();

      imageElement.scaleX(newScale);
      imageElement.scaleY(newScale);

      const newHeight = imageElement.height() * imageElement.scaleY();
      const newWidth = imageElement.width() * imageElement.scaleX();

      imageElement.x(imageElement.x() - (newWidth - oldWidth) / 2);
      imageElement.y(imageElement.y() - (newHeight - oldHeight) / 2);

      lastDist = newDist;
      layer.batchDraw();
    });

    stage.on("touchend", () => {
      imageElement.draggable(true);
      lastDist = 0;
    });

    const handlers = {
      brightness: {
        onChange: (brightness: number) => {
          imageElement.brightness(Math.min(brightness, 1));
          tr.forceUpdate();
          layer.batchDraw();
        },
        value: () => {
          return imageElement.getAttr("brightness");
        },
        range: {
          max: 0.5,
          min: -0.5,
          step: 0.05,
        },
      },
      contrast: {
        onChange: (contrast: number) => {
          imageElement.contrast(Math.max(Math.min(contrast, 100), -100));
          tr.forceUpdate();
          layer.batchDraw();
        },
        value: () => {
          return imageElement.getAttr("contrast");
        },
        range: {
          max: 50,
          min: -50,
          step: 5,
        },
      },
    };

    const getParams = () => {
      return {
        x: imageElement.x() / width,
        y: imageElement.y() / height,
        height: (imageElement.height() * imageElement.scaleY()) / height,
        width: (imageElement.width() * imageElement.scaleX()) / width,
        brightness: imageElement.brightness(),
        contrast: imageElement.contrast(),
        rotation: imageElement.rotation(),
      };
    };

    const group = new Konva.Group({ rawZindex: movableImage.rawZindex });
    group.add(imageElement);

    resolve({ movableImage: group, handlers, getParams, transformer: tr });
  });
};

const createBackdropImages = async (
  backdrops: Backdrop[],
  { height, width }: Dimensions
) => {
  return await Promise.all<Konva.Image>(
    backdrops.map((backdrop) => {
      return new Promise(async (resolve) => {
        const loadedBackdrop = await loadImage(backdrop.imageUrl);
        const scale = Math.min(
          width / loadedBackdrop.width,
          height / loadedBackdrop.height
        );

        resolve(
          new Konva.Image({
            image: loadedBackdrop,
            listening: false,
            x: 0,
            y: 0,
            rawZindex: backdrop.rawZindex,
            width: loadedBackdrop.width * scale,
            height: loadedBackdrop.height * scale,
          })
        );
      });
    })
  );
};

/**
 * Name text is special. We need to give it a bottom y value and the dynamically size the font with a max width and a base font size
 * Once we've done this, we work backwards and set the y position of the first and last name. We then center both text elements
 * once we know the final width of the text.
 */
const createPosterName = (
  posterName: PosterName | PosterNameSplit,
  posterDimensions: Dimensions
) => {
  if (posterName.hasOwnProperty("firstAndLastName")) {
    return createCombinedPosterName(posterName as PosterName, posterDimensions);
  } else {
    return createSplitPosterName(
      posterName as PosterNameSplit,
      posterDimensions
    );
  }
};

const createCombinedPosterName = (
  posterName: PosterName,
  posterDimensions: Dimensions
) => {
  const { firstAndLastName, container } = posterName;
  const { height: posterHeight, width: posterWidth } = posterDimensions;

  const nameAbsFontSize = firstAndLastName.fontSize * posterHeight;

  const nameAbsX = firstAndLastName.x * posterWidth;

  const nameAbsMaxFontSize = firstAndLastName.maxFontSize * posterHeight;

  const textAbsMaxWidth = posterName.maxWidth * posterWidth;

  const containerYAbs = container.y * posterHeight;
  const containerHeightAbs = container.height * posterHeight;

  const nameElement = new Konva.Text({
    ...firstAndLastName,
    fontSize: nameAbsFontSize,
    x: nameAbsX,
  });

  const nameScaledFontSize = Math.min(
    nameAbsMaxFontSize,
    (nameAbsMaxFontSize * textAbsMaxWidth) / nameElement.width()
  );

  const { height, width } = getRealFontDimensions(
    firstAndLastName.text!,
    nameScaledFontSize,
    firstAndLastName.fontFamily!
  );

  const nameX = posterName.centerX
    ? posterWidth / 2 - width / 2
    : container.x * posterWidth;

  nameElement.fontSize(nameScaledFontSize);
  nameElement.x(nameX);

  const y = containerYAbs + containerHeightAbs / 2 - height / 2;
  nameElement.y(y);

  return [nameElement];
};

const createSplitPosterName = async (
  posterName: PosterNameSplit,
  posterDimensions: Dimensions
) => {
  const { firstName, lastName } = posterName;
  const { height: posterHeight, width: posterWidth } = posterDimensions;

  const firstNameAbsFontSize = firstName.fontSize * posterHeight;
  const lastNameAbsFontSize = lastName.fontSize * posterHeight;

  const firstNameAbsX = firstName.x * posterWidth;
  const lastNameAbsX = lastName.x * posterWidth;

  const lastNameAbsMaxFontSize = lastName.maxFontSize * posterHeight;
  const firstNameAbsMaxFontSize = firstName.maxFontSize * posterHeight;

  const textAbsMaxWidth = posterName.maxWidth * posterWidth;
  const textFirstNameAbsMaxWidth =
    firstName.maxWidth === undefined
      ? textAbsMaxWidth
      : firstName.maxWidth * posterWidth;

  const textLastNameAbsMaxWidth =
    lastName.maxWidth === undefined
      ? textAbsMaxWidth
      : lastName.maxWidth * posterWidth;
  const paddingAbsY = posterName.paddingY * posterHeight;
  const bottomAbsY = posterName.bottomY * posterHeight;

  if (firstName.fillPatternImageUrl) {
    firstName.fillPatternImage = await loadImage(firstName.fillPatternImageUrl);
  }

  if (lastName.fillPatternImageUrl) {
    lastName.fillPatternImage = await loadImage(lastName.fillPatternImageUrl);
  }
  const firstNameElement = new Konva.Text({
    ...firstName,
    fontSize: firstNameAbsFontSize,
    x: firstNameAbsX,
  });

  const lastNameElement = new Konva.Text({
    ...lastName,
    fontSize: lastNameAbsFontSize,
    x: lastNameAbsX,
  });

  const lastNameScaledFontSize = Math.min(
    lastNameAbsMaxFontSize,
    (lastNameAbsFontSize * textLastNameAbsMaxWidth) / lastNameElement.width()
  );

  const firstNameScaledFontSize = Math.min(
    firstNameAbsMaxFontSize,
    firstNameAbsFontSize * (textFirstNameAbsMaxWidth / firstNameElement.width())
  );

  const {
    height: firstNameHeight,
    width: firstNameWidth,
  } = getRealFontDimensions(
    firstName.text!,
    firstNameScaledFontSize,
    firstName.fontFamily!
  );

  const {
    height: lastNameHeight,
    width: lastNameWidth,
  } = getRealFontDimensions(
    lastName.text!,
    lastNameScaledFontSize,
    lastName.fontFamily!
  );

  firstNameElement.fontSize(firstNameScaledFontSize);
  lastNameElement.fontSize(lastNameScaledFontSize);

  if (posterName.centerX) {
    const lastNameX =
      lastNameAbsX +
      Math.max(0, textLastNameAbsMaxWidth / 2 - lastNameWidth / 2);

    const firstNameX =
      firstNameAbsX +
      Math.max(0, textFirstNameAbsMaxWidth / 2 - firstNameWidth / 2);

    firstNameElement.x(firstNameX);
    lastNameElement.x(lastNameX);
  }

  lastNameElement.y(bottomAbsY - lastNameHeight);
  firstNameElement.y(lastNameElement.y() - firstNameHeight - paddingAbsY);

  return [firstNameElement, lastNameElement];
};

const getRealFontDimensions = (
  text: string,
  fontSize: number,
  fontFamily: string
) => {
  const canvas = document.createElement("canvas");
  const originalCtx = canvas.getContext("2d") as CanvasRenderingContext2D;

  originalCtx.font = `italic ${fontSize}px ${fontFamily}`;
  originalCtx.textBaseline = "middle";
  originalCtx.strokeText(text, 0, 0);
  originalCtx.strokeStyle = "red";
  const metrics = originalCtx.measureText(text?.toUpperCase());
  return {
    height: metrics.actualBoundingBoxAscent + metrics.actualBoundingBoxDescent,
    width: metrics.width,
  };
};

export const preloadFont = (fontFamily: string) => {
  const canvas = document.createElement("canvas");
  const ctx = canvas.getContext("2d");

  if (ctx) {
    console.log("preloading ", fontFamily);
    ctx.font = `normal 20px ${fontFamily}`;
    ctx.measureText("Some test text");
  }
};

export default PosterMaker;
