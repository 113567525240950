import ApolloClient from "apollo-client";

import { createHttpLink } from "apollo-link-http";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";
import { getToken } from "./tokenManager";

let apolloClient;

export const getClient = (cache = {}) => {
  if (apolloClient) return apolloClient;
  const GRAPHQL_URL =
    process.env.REACT_APP_GRAPHQL_URL ||
    "https://api.staging.matchpick.co/graphql";

  const httpLink = createHttpLink({
    uri: GRAPHQL_URL,
  });

  const headerLink = setContext((request, previousContext) => ({
    headers: {
      ...previousContext.headers,
      Authorization: `Bearer ${getToken()}`,
    },
  }));

  const client = new ApolloClient({
    link: headerLink.concat(httpLink),
    cache: new InMemoryCache().restore(cache),
  });

  apolloClient = client;
  return apolloClient;
};
