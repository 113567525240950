import * as React from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";

import { useCreationDispatch, useCreationState } from "../creationFlowHooks";
import { setCustomerDetails } from "../creationFlowActions";
import Layout from "../shared/Layout";
import Header from "../shared/Header";
import InfoHeader from "../shared/InfoHeader";

import Hero from "../shared/Hero";
import { Poster_Description } from "../shared/Constants";
import Button from "../shared/Button";
import { IoIosArrowRoundForward } from "react-icons/io";
import { useForm } from "react-hook-form";
import TextArea from "../shared/TextArea";
import { addAdditionalPrefix } from "./AdditionalData";

const DetailsExtra: React.FC = () => {
  const { error, customerDetails } = useCreationState();
  const dispatch = useCreationDispatch();
  const history = useHistory();
  const { register, handleSubmit, errors } = useForm({});

  const onSubmit = (data): void => {
    const prefixed: any = addAdditionalPrefix(data);
    dispatch(setCustomerDetails({ ...customerDetails, ...prefixed }));
    history.replace("/loading");
  };

  React.useEffect(() => {
    if (error) {
      history.replace("/");
      toast.error(error);
    }
  }, [error, history]);
  const maxLength = 57;
  return (
    <Layout>
      <>
        <Hero
          role="PERSONALISE_PAGE"
          defaultHeader={<Header text="Personalise Your Poster" />}
        />
        <div className="flex flex-col flex-1 mx-5 overflow-hidden">
          <div className="mb-3 mx-4">
            <InfoHeader header={"Why are you a super fan?"} />
          </div>

          <div className="flex flex-grow overflow-y-scroll overscroll-x-none rounded-lg">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex flex-col flex-grow"
            >
              <div className="px-5 pt-6 pb-3 bg-bgCard text-card">
                <div className="mb-4">
                  <TextArea
                    rows={4}
                    maxLength={maxLength}
                    error={errors.reason}
                    name="reason"
                    label={`In ${maxLength} characters or less explain why you are a super fan`}
                    register={register({ required: true })}
                  ></TextArea>
                </div>
                <div className="mb-4">
                  <TextArea
                    rows={8}
                    error={errors.reasonExtra}
                    name="reasonExtra"
                    label="Additional info (Optional) if you have more info on why you are a super fan - enter it here"
                    register={register({ required: false })}
                  ></TextArea>
                </div>
              </div>
              <div className="flex flex-grow items-end bg-whisper px-6 py-4 mt-3">
                <Button
                  theme="secondary"
                  type="submit"
                  icon={<IoIosArrowRoundForward size={30} />}
                >
                  Preview {Poster_Description}
                </Button>
              </div>
            </form>
          </div>
        </div>
      </>
    </Layout>
  );
};

export default DetailsExtra;
