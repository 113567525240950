import * as React from "react";
import classNames from "classnames";
import { useHistory } from "react-router-dom";

import Button from "../shared/Button";
import Hero from "../shared/Hero";
import { AppLayout } from "../splash/Splash";
import InfoHeader from "../shared/InfoHeader";

import allBlacksImage from "./allblacks.png";
import blackFernsImage from "./blackferns.png";

const TeamSelection: React.FC = () => {
  const history = useHistory();
  const [selectedTeam, setSelectedTeam] = React.useState("");
  const goToTeamPoster = (): void => {
    if (selectedTeam === "allBlacks") {
      history.push("/skills-ab");
    } else {
      history.push("/skills-bf");
    }
  };

  const selectTeam = (team: string): void => {
    setSelectedTeam(team);
  };

  return (
    <AppLayout className="bg-bgBody">
      <Hero role="SPLASH_PAGE" />
      <div className="pb-3 my-3 overflow-y-auto">
        <div className="flex flex-col flex-1 mx-5 overflow-hidden">
          <div className="mb-3 mx-4">
            <InfoHeader header="Who do you support?" />
          </div>
        </div>
        <div className="grid grid-cols-1 gap-4 justify-items-center pt-6">
          <div
            onClick={() => selectTeam("allBlacks")}
            className={classNames(
              "flex w-fit h-fit border-4 rounded-lg cursor-pointer",
              {
                "border-white": selectedTeam === "allBlacks",
                "border-transparent": selectedTeam !== "allBlacks",
              }
            )}
          >
            <img alt="All Blacks" src={allBlacksImage} className="w-36 h-36" />
          </div>
          <div
            onClick={() => selectTeam("blackFerns")}
            className={classNames(
              "flex w-fit h-fit border-4 rounded-lg cursor-pointer",
              {
                "border-white": selectedTeam === "blackFerns",
                "border-transparent": selectedTeam !== "blackFerns",
              }
            )}
          >
            <img
              alt="Black Ferns"
              src={blackFernsImage}
              className="w-36 h-36"
            />
          </div>
        </div>
      </div>
      <div className="mx-8">
        <Button
          theme="primary"
          disabled={selectedTeam === ""}
          className={
            selectedTeam === "" ? "cursor-not-allowed bg-opacity-25" : ""
          }
          onClick={goToTeamPoster}
        >
          Next
        </Button>
      </div>
    </AppLayout>
  );
};

export default TeamSelection;
