import * as React from "react";
import classNames from "classnames";

import Button from "../shared/Button";
import Hero from "../shared/Hero";
import { AppLayout } from "../splash/Splash";
import InfoHeader from "../shared/InfoHeader";

import interceptImage from "./intercept.png";
import sidestepImage from "./sidestep.png";
import sprintImage from "./sprint.png";
import tackleImage from "./tackle.png";

const SkillsAllBlacks: React.FC = () => {
  const [selectedTeam, setSelectedTeam] = React.useState("");
  const posterRoutes = {
    sprint: [
      "statattack-allblacks-persistentpowerhouse-steal",
      "statattack-allblacks-superspeedster-chipandchase",
    ],
    sidestep: [
      "statattack-allblacks-dazzlingdynamo-sidestep",
      "statattack-allblacks-dazzlingdynamo-chargedown",
    ],
    tackle: [
      "statattack-allblacks-daringdefender-BallAndAllTackle",
      "statattack-allblacks-daringdefender-fend",
    ],
    intercept: [
      "statattack-allblacks-persistentpowerhouse-offload",
      "statattack-allblacks-superspeedster-intercept",
    ],
  };

  const goToTeamPoster = (): void => {
    const redirectPosters = posterRoutes[selectedTeam];
    const index = Math.floor(Math.random() * 2);
    const host = window.location.origin;
    window.location.href = `${host}/${redirectPosters[index]}`;
  };

  const posterSelection = [
    {
      display: "Side Step",
      name: "sidestep",
      image: sidestepImage,
    },
    {
      display: "Tackle",
      name: "tackle",
      image: tackleImage,
    },
    {
      display: "Sprint",
      name: "sprint",
      image: sprintImage,
    },
    {
      display: "Intercept",
      name: "intercept",
      image: interceptImage,
    },
  ];

  const selectTeam = (team: string): void => {
    setSelectedTeam(team);
  };

  return (
    <AppLayout className="bg-bgBody">
      <Hero role="HOW_TO_PLAY_PAGE" />
      <div className="pb-3 my-3 overflow-y-auto">
        <div className="flex flex-col flex-1 mx-5 overflow-hidden">
          <div className="mb-3 mx-4">
            <InfoHeader header="What signature moves are you pulling?" />
          </div>
        </div>
        <div className="grid grid-cols-1 gap-1 justify-items-center pt-2">
          {posterSelection.map((poster) => {
            return (
              <div
                key={poster.name}
                onClick={() => selectTeam(poster.name)}
                className={classNames(
                  "flex w-fit h-fit border-4 rounded-lg cursor-pointer",
                  {
                    "border-black": selectedTeam === poster.name,
                    "border-transparent": selectedTeam !== poster.name,
                  }
                )}
              >
                <img
                  alt={poster.display}
                  src={poster.image}
                  className="w-30 h-30"
                />
              </div>
            );
          })}
        </div>
      </div>
      <div className="mx-8">
        <Button
          theme="primary"
          disabled={selectedTeam === ""}
          className={
            selectedTeam === "" ? "cursor-not-allowed bg-opacity-25" : ""
          }
          onClick={goToTeamPoster}
        >
          Next
        </Button>
      </div>
    </AppLayout>
  );
};

export default SkillsAllBlacks;
