import * as React from "react";
import LoadingSpinner from "./LoadingSpinner";

const LoadingScreen: React.FC = () => {
  return (
    <main className="w-full antialiased font-body mobile-screen overflow-auto bg-bgBody overflow-x-hidden">
      <div className="flex items-center justify-center h-full">
        <div className="bg-white flex justify-center w-full max-w-iphone h-full max-h-lg overflow-hidden">
          <div className="flex justify-center items-center mt-10">
            <LoadingSpinner color="athensGray" height="80px" width="80px" />
          </div>
        </div>
      </div>
    </main>
  );
};
export default React.memo(LoadingScreen);
